import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_STATUS_REASONS_CONNECTION_DEPOSIT,
  API_GET_STATUS_REASONS_CONNECTION_ITEM,
  API_GET_STATUS_REASONS_CONNECTION_WITHDRAWAL,
  API_UPDATE_STATUS_REASONS_CONNECTION
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { ErrorType } from "system/helpers/types";
import { useSuccessToast } from "system/helpers/hooks";
import { StatusConnectionTypeType, StatusReasonConnectionTemplateDataType } from "./types";

const getStatusReasonConnectionDepositKey = "getStatusReasonConnectionDepositKey";
const getStatusReasonConnectionWidrawalKey = "getStatusReasonConnectionWidrawalKey";
const getStatusReasonConnectionItemKey = "getStatusReasonConnectionItemKey";

export const useGetStatusReasonConnectionDeposit = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getStatusReasonConnectionDepositKey, filters],
    (): Promise<StatusReasonConnectionTemplateDataType> =>
      apiRequest({
        url: API_GET_STATUS_REASONS_CONNECTION_DEPOSIT,
        method: "get",
      })
  );
};
export const useGetStatusReasonConnectionWithdrawal = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getStatusReasonConnectionWidrawalKey, filters],
    (): Promise<StatusReasonConnectionTemplateDataType> =>
      apiRequest({
        url: API_GET_STATUS_REASONS_CONNECTION_WITHDRAWAL,
        method: "get",
      })
  );
};

export const useGetStatusReasonConnectionItem = (
  {
    gatewayCode,
    type 
  }: {
    gatewayCode: string,
    type: StatusConnectionTypeType
  }) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getStatusReasonConnectionItemKey, gatewayCode, type],
    (): Promise<StatusReasonConnectionTemplateDataType> =>
      apiRequest({
        url: generatePath(API_GET_STATUS_REASONS_CONNECTION_ITEM, { gatewayCode, type }),
        method: "get",
      })
  );
};


export const useCreateStatusReasonConnection = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_UPDATE_STATUS_REASONS_CONNECTION,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getStatusReasonConnectionWidrawalKey);
        queryClient.invalidateQueries(getStatusReasonConnectionItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateStatusReasonConnection = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) => {
      return apiRequest({
        url: API_UPDATE_STATUS_REASONS_CONNECTION,
        method: "post",
        data: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getStatusReasonConnectionItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

import { Column as ColumnType } from "react-table";
import { Link } from "carbon-components-react";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "modules/formBuilder/types";
import { useModuleData } from "modules/merchantsBalance/hooks";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (data: any): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo((): TableColumnType[] => {
    const result = [];
    if (data?.result?.[0]) {
      const item = data?.result?.[0];
      for (let key in item) {
        if (key === "merchantName") {
          result.push({
            Header: _t("merchant"),
            id: "merchantName",
            isMobileTitle: true,
            mobileVisible: true,
            Cell: ({ row, showDetails }: any) => {
              return (
                <Link
                  onClick={() =>
                    showDetails({
                      merchantId: row.original.merchantId,
                      merchantName: row.original.merchantName,
                    })
                  }
                >
                  {row?.original?.merchantName}
                </Link>
              );
            },
          });
        } else {
          result.push({
            Header: key,
            id: key + 1,
            accessor: key,
            mobileVisible: key !== "merchantId",
          });
        }
      }
    }

    return result;
  }, [data]);

  return columns;
};
export const useTableSummaryColumns = (data: any): TableColumnType[] => {
  const columns = useMemo((): TableColumnType[] => {
    const result = [];
    if (data?.[0]) {
      const item = data?.[0];
      for (let key in item) {
        result.push({
          Header: key,
          id: key + 1,
          accessor: key,
          mobileVisible: true,
          isMobileTitle: key === "currency",
        });
      }
    }
    return result;
  }, [data]);

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const { merchantsOptions, currenciesOptions, paymentGatewaysOptions } =
    useModuleData();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchantId({
        componentProps: {
          items: merchantsOptions,
        },
      }),
      fields.filters.paymentGateway({
        componentProps: {
          items: paymentGatewaysOptions,
        },
      }),
      fields.filters.currency({
        componentProps: {
          items: currenciesOptions,
        },
      }),
      fields.filters.date(),
      fields.general.submitBtn(),
    ];
  }, [merchantsOptions, paymentGatewaysOptions, currenciesOptions]);
  return formConfig;
};

import { Column as ColumnType } from "react-table";
import InfoTag from "components/atoms/InfoTag";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigItemType, FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useAlertsModuleData } from "modules/alerts/hooks";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { CreateEditFormConfigParams } from "modules/alerts/types";
import Input from "components/atoms/Input";
import { TableColumnType } from "modules/table/types";
import { useModuleData } from "modules/merchantsBalance/hooks";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("id"),
        accessor: "id",
        id: "id1",
        width: 50,
      },
      {
        Header: _t("alert_type"),
        accessor: "alertType",
        id: "alertType",
        mobileVisible: true,
        isMobileTitle: true,
      },
      {
        Header: _t("merchant"),
        accessor: "merchantName",
        id: "merchantName",
        mobileVisible: true,
      },
      {
        Header: _t("recipient"),
        accessor: "recipient",
        id: "recipient",
        minWidth: 230,
        mobileVisible: true,
      },
      {
        Header: _t("name"),
        accessor: "name",
        id: "name",
      },
      {
        Header: _t("comment"),
        accessor: "comment",
        id: "comment",
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};
const isMobile = window.matchMedia('(max-width: 671px)').matches;
export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { merchantsOptions } = useModuleData();

  const buttonForm = useMemo((): FormConfigItemType => {
    if (isMobile) {
      return fields.general.submitBtn( {emptySpace: {} })
    }
    return fields.general.searchBtn()
  }, [isMobile])

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchantIdSingle({
        openModal: true,
        valuePreview: isMobile,
        componentProps: {
          items: merchantsOptions,
          light: false,
          type: "default",
          labelWithAll: isMobile ? true : false,
          whiteBackground: true,
          withSelectAll: false,
          singleSelect: true,
          label: isMobile ? _t('merchant') : '',
          openModal: true,
        },
        columnParams: {
          lg: 6,
          md: 6,
          className: isMobile ? 'mobile-filter' : ''
        },
      }),
      buttonForm
    ];
  }, [merchantsOptions]);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { merchantsOptions, alertsTypesOptions } = useAlertsModuleData();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.merchantIdSingle({
        componentProps: {
          items: merchantsOptions,
          light: false,
        },
      }),
      fields.formFields.alertType({
        componentProps: {
          items: alertsTypesOptions,
          light: false,
        },
      }),
      fields.general.divider(),
      {
        component: null,
        type: "array",
        name: "recipient",
        components: [
          {
            component: Input,
            name: "",
            valuePreview: true,
            componentProps: {
              labelText: _t("email"),
              placeholder: _t("email"),
            },
            columnParams: {
              lg: 16,
              md: 16,
            },
          },
        ],
      },
      fields.general.divider(),
      fields.formFields.name(),
      fields.formFields.description(),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, [merchantsOptions]);
  return formConfig;
};

import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { useGetPaymentGateways } from "modules/merchantsBalanceV2/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import {  UseModuleDataRes } from "./types";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGateways = [] } = useGetPaymentGateways();

  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });

  return { merchantsOptions, paymentGatewaysOptions };
};
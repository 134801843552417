import { TransType } from "system/translations/types";

export const filterOptions = (_t: TransType) => [
  { label: _t("merchant_client_id"), value: "merchantClientId" },
  { label: _t("card_number"), value: "cardNum" },
  { label: _t("uuid"), value: "transactionUUID" },
  { label: _t("customer_email"), value: "customerEmail" },
  { label: _t("merchant_order_no"), value: "referenceNo" },
  { label: _t("psp_order_no"), value: "gatewayTransactionId" },
];

export const expandedItems = (_t: TransType) => [
  { title: _t("payment_method"), accessor: "paymentMethod" },
  { title: _t("client"), accessor: "client.name" },
  { title: _t("merchant_client_id"), accessor: "client.merchantClientId" },
  { title: _t("country"), accessor: "client.country" },
];


export const getInitialValuesForCreate = (
  { isSubMerchant, additionalData = {} } : { isSubMerchant: boolean, additionalData?: any }
) => {
  const initialValue = {
    allowDynamicDescriptor: false,
    allowPartialCapture: false,
    allowPartialRefund: false,
    avsCheck: false,
    disable: false,
    redirectType: "POST",
    
    ipns: {
      depositIpnUrl: "",
      pushDepositIpnUrl: "",
      v2IpnUrl: "",
      withdrawalIpnUrl: "",
      v1IpnUrl: ""
    }
  }
  if (!isSubMerchant) {
    return initialValue
  }

 return { ...initialValue,  ...additionalData }
 }

import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ArrowLeft20 } from "@carbon/icons-react";
import { AuthWrapperProps } from "modules/auth/types";
import { useTrans } from "system/translations/hooks";
import { ListItem, OrderedList } from "carbon-components-react";
import authLogo from '../../../../images/authLogo.svg'

const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  classname,
  title,
  subtitle,
  actionLink,
  actionText,
  backHandler,
  footer,
  qrCode = null,
  manual_code,
}) => {
  const { _t } = useTrans();
  return (
    <div className={classNames("auth__container", classname)}>
      <div className={"auth__content"}>
        <div className={"auth__logo"}><img src={authLogo} alt=""/></div>
        {!!title && (
          <div
            className={classNames("auth__title", !!subtitle && "has-subtitle")}
          >
            {backHandler && (
              <span className={"back-btn"} onClick={backHandler}>
                <ArrowLeft20 />
              </span>
            )}
            <span className={"auth__title-text"}>{title}</span>
          </div>
        )}
           {!!subtitle && <div className={"auth__subtitle"}>{subtitle}</div>}
        {qrCode && (
          <div className={"auth__description"}>
            <div className={"auth__description__title"}>
              {_t("set_up_via_google_auth")}
            </div>
            <OrderedList>
              <ListItem>
                Download and install the Google Authenticator app on your mobile
                device
              </ListItem>
              <ListItem>
                Scan the QR code
                <div className={"auth__description__qr"}>
                  <div>
                    <img
                      src={qrCode}
                      style={{ width: "130px", height: "130px" }}
                    />
                  </div>
                  <div>{_t("enter_key_manually")}</div>
                  <div className={"auth__description__qr__text-code"}>
                    {manual_code}
                  </div>
                </div>
              </ListItem>
              <ListItem>
                Enter the 6-digit code generated by Google Authenticator
              </ListItem>
            </OrderedList>
          </div>
        )}
        {children}
        {!!actionLink && (
          <div className={"auth__link-wrapper"}>
            <Link to={actionLink}>{actionText}</Link>
          </div>
        )}
        {!!footer && <div className={"auth__footer-wrapper"}>{footer}</div>}
      </div>
    </div>
  );
};

export default AuthWrapper;

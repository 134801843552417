import React from "react";
import classNames from "classnames";


const OperatorChangerButton: React.FC<any> = ({
  value,
  labelA = "",
  labelB = "",
  valueA = "",
  valueB = "",
  onChange,
  className,
}) => {
  return (
    <>
      {valueA && valueB
        ? (
          <div className={classNames("operator-changer__container", className && className)}>
            <div className={classNames("operator-changer-item", { 'operator-changer-checked': value === valueA })} onClick={() => onChange && onChange(valueA)}>{labelA}</div>
            <div className={classNames("operator-changer-item", { 'operator-changer-checked': value === valueB })} onClick={() => onChange && onChange(valueB)}>{labelB}</div>
          </div>
        )
        : (
          <div className="operator-changer__container">
            <div className='operator-changer-item operator-changer-checked'>{value}</div>
          </div>
        )
      }
    </>
  );
};

export default OperatorChangerButton;
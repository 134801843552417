import React from "react";
import { CodeSnippet, Column, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import JSONbig from 'json-bigint';

const Details = (props: any) => {
  const { _t } = useTrans();
  const renderJson = (value: any) => {
    
    try {
      return JSONbig.stringify(JSONbig.parse(value), null, 2);
    } catch (e) {
        if (typeof value === 'object') {
          return JSONbig.stringify(value, null, 2)
        }
        
        const replacedValueWithAsterisksToString =  value.replace(/\b\d+\*+\d+\b/g, '"$&"');
        if (value !== replacedValueWithAsterisksToString) {
          try {
            return JSONbig.stringify(JSONbig.parse(replacedValueWithAsterisksToString), null, 2);
          } catch (e) {
              return value
          }
        } else {
          const jsonFragments = value.split(/(?<=\})\s*,?\s*(?=\{)/);

          try {
              const parsedFragments = jsonFragments.map((fragment: any) => JSONbig.parse(fragment));
              
              return JSONbig.stringify(parsedFragments, null, 2);
          } catch (e) {
              return value;
          }
        }
    }
  };
  return (
    <Row style={{ background: "#F3F5F6", padding: "10px 5px" }}>
      <Column lg={{ span: 8 }}>
        {/* <label className="bx--label">{_t("selection_details")}</label> */}
        <label className="bx--label">Selection Details</label>
        <CodeSnippet
          className={"mv-2"}
          type="multi"
          feedback="Copied to clipboard"
        >
          {renderJson(props?.rowData?.selectionDetails)}
        </CodeSnippet>
      </Column>
      <Column lg={{ span: 8 }}>
        {/* <label className="bx--label">{_t("status_reason")}</label> */}
        <label className="bx--label">Status reason</label>
        <CodeSnippet
          className={"mv-2"}
          type="multi"
          feedback="Copied to clipboard"
        >
          {renderJson(props?.rowData?.statusReason)}
        </CodeSnippet>
      </Column>
    </Row>
  );
};

export default Details;

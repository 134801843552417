import React from "react";
import * as Yup from "yup";
import { Send20 } from "@carbon/icons-react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useForgotPassword } from "modules/auth/apiHooks";
import { isValidEmail } from "system/helpers/validationHelper";
import { useForgotPasswordFormConfig } from "modules/auth/constantHooks";

const ForgotPasswordForm: React.FC = () => {
  const { mutateAsync, isLoading } = useForgotPassword();
  const { _t } = useTrans();
  const formConfig = useForgotPasswordFormConfig();
  const onSubmit = async (data: any) => {
    await mutateAsync(data);
  };

  const ForgotPasswordSchema = Yup.object().shape({
    email: isValidEmail(_t).required(),
  });

  return (
    <FormBuilder
      formItemsConfig={formConfig}
      formProps={{
        onSubmit: onSubmit,
        initialValues: { email: "" },
        validationSchema: ForgotPasswordSchema,
        submitBtnKind: "primary",
        submitBtnLabel: _t("send_instructions"),
        renderIcon: Send20,
        isLoading,
      }}
    />
  );
};

export default ForgotPasswordForm;

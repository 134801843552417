import React, { useMemo } from "react";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import { useFilters } from "system/helpers/hooks";
import { useGetRequestHistory } from "modules/requestHistory/apiHooks";
import { filterOptions } from "modules/requestHistory/constants";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/requestHistory/constantHooks";
import { useModal } from "modules/modal/hooks";
import TransactionDetails from "modules/transactions/components/TransactionDetails";
import Details from "modules/requestHistory/components/Details";
import { ArrayOptionsMapRes } from "system/helpers/types";

const RequestHistory = ({ initialFilters = {}, showOnlyTable = false }) => {
  const { _t } = useTrans();
  const { showModal } = useModal();
  const { filters, setFilters } = useFilters(initialFilters);
  const requestParams = useMemo(() => ({ ...filters }), [filters]);
  const { data = {}, isLoading } = useGetRequestHistory(requestParams, {
    enabled: !!filters.request,
  });
  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const onFilterSubmit = async (data: any) => {
    setFilters(data);
  };
  const filterByOptions: ArrayOptionsMapRes = useMemo(
    () => filterOptions(_t),
    [_t]
  );
  const showDetails = (transaction: any) => {
    showModal(
      {
        componentProps: {
          id: transaction?.transactionUUID,
        },
        modalHeading: _t("transaction_details"),
        component: TransactionDetails,
      },
      "history_modal"
    );
  };

  const tableData: any = Object.values(data)?.[0]
    ? Object.values(data)?.[0]
    : [];
  const pageSize = !!tableData.length ? tableData.length : 1000; //remove that line after table refactor

  if (showOnlyTable) {
    return (
      <Table
        columns={columns}
        isLoading={isLoading}
        data={tableData}
        pageSize={pageSize}
        cellProps={{ showDetails }}
        rowSubComponent={<Details />}
      />
    );
  }

  return (
    <Card title={_t("request_history")}>
      <FormBuilder
        formItemsConfig={searchFormConfig}
        showSubmit={false}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: { field: filterByOptions[0]?.value },
        }}
      />
      <Table
        columns={columns}
        isLoading={isLoading}
        pageSize={pageSize}
        data={tableData}
        cellProps={{ showDetails }}
        rowSubComponent={<Details />}
      />
    </Card>
  );
};

export default RequestHistory;

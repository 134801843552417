import React from "react";
import Card from "components/atoms/Card";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import { useTableColumns } from "modules/credentials/constantHooks";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Add16, Edit16, TrashCan16 } from "@carbon/icons-react";
import {
  useGetCredential,
  useDeleteCredential,
} from "modules/credentials/apiHooks";
import { useConfirmModal } from "modules/modal/predefinedModals";

const CredentialInfo = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { onConfirm } = useConfirmModal();
  const { data = [], isLoading } = useGetCredential(routeParams?.credentialId);
  const { mutate: onDelete } = useDeleteCredential(routeParams?.credentialId);
  const columns = useTableColumns(data);
  const onUpdate = ({ id, allData }: any) => {
    const link = generatePath(APP_ROUTES.credentials.update, {
      credentialId: routeParams?.credentialId,
      id,
    });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };

  const actionMenuItems = [
    { 
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => generatePath(APP_ROUTES.credentials.update, {
        credentialId: routeParams?.credentialId,
        id,
      }),
      icon: <Edit16 /> 
    },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];

  const isMobile = window.matchMedia('(max-width: 671px)').matches;


  return (
    <Card
      title={routeParams?.credentialId}
      extra={
        <>
        {isMobile
          ? <ButtonLink
              className="button-create__link"
              isExpressive
              renderIcon={Add16}
              linkTo={generatePath(APP_ROUTES.credentials.create, {
                credentialId: routeParams?.credentialId,
              })}
            />
          : <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={generatePath(APP_ROUTES.credentials.create, {
                credentialId: routeParams?.credentialId,
              })}
              title={_t("create")}
            />
        }
        </>
      }
    >
      <Table
        columns={columns || []}
        isLoading={isLoading}
        data={data || []}
        cellProps={{
          actionMenuItems,
        }}
      />
    </Card>
  );
};

export default CredentialInfo;

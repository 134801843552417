import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { useModuleData } from "./hooks";

const statuses = [
  { label: "Active", value: true },
  { label: "Deactivated", value: false }
]

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("name"),
        accessor: "name",
        id: "name",
      },
      {
        Header: _t("merchant"),
        accessor: "merchant",
        id: "merchant",
      },
      {
        Header: _t("method"),
        accessor: "method",
        id: "method",
      },
      {
        Header: _t("provider"),
        accessor: "provider",
        id: "provider",
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const providerConstraintsStatuses = statuses;
  const { merchantsOptions, paymentMethodsOptions, paymentGatewaysOptions } = useModuleData(); 

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchants({
        componentProps: {
          items: merchantsOptions,
        },
      }),
      fields.filters.paymentMethod({
        componentProps: {
          items: paymentMethodsOptions,
          label: _t("method")
        },
      }),
      fields.filters.paymentGateway({
        componentProps: {
          items: paymentGatewaysOptions,
          label: _t("provider")
        },
      }),
      fields.filters.active({
        componentProps: {
          items: providerConstraintsStatuses,
        },
      }),
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          type: "submit",
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
      }),
      fields.general.submitBtn()
    ];
  }, [merchantsOptions, paymentMethodsOptions, paymentGatewaysOptions]);
  return formConfig;
};
import React, { useMemo } from "react";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import { useFilters } from "system/helpers/hooks";
import { useTableTransactionColumns, useTableTransactionColumnsExpandFee } from "modules/merchantsBalanceDetails/constantHooks";
import { useGetMerchantBalanceDetailsAmount } from "../apiHooks";
import Loader from "components/atoms/Loader";
import ExpandedRow from "components/molecules/ExpandedRow";

const TransactionDetails = ({ initialFilters = {}, merchantId, currency}: any) => {
  const { _t } = useTrans();
  const { filters, setFilters } = useFilters(initialFilters);
  const requestParams = useMemo(() => ({ ...filters }), [filters]);
  const { data = [], isLoading } = useGetMerchantBalanceDetailsAmount(
    merchantId,
    currency,
    requestParams
    );
  const columns = useTableTransactionColumns();
  const columnsExpanded = useTableTransactionColumnsExpandFee();
  if (isLoading) {
    return <Loader formOverlay />;
  }
    
  return (
      <Table
        columns={columns}
        isLoading={isLoading}
        data={data}
        tableName={'TableWithSubComponentTable'}
        isPagination={true}
        // rowSubComponent={
        // <Table 
        //   data={data}
        //   columns={columnsExpanded} 
        //   />}
      />
  );
};

export default TransactionDetails;

import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UseModuleDataRes } from "modules/merchantsBalance/types";
import { useGetPaymentGateways } from "../payment/apiHooks";
import { useGetMerchantBalanceCurrencies } from "modules/merchantsBalance/apiHooks";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGateways = [] } = useGetPaymentGateways();
  const { data: currencies = [] } = useGetMerchantBalanceCurrencies();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "id",
  });
  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  return { merchantsOptions, currenciesOptions, paymentGatewaysOptions };
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import { RadioButton } from "carbon-components-react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Input from "components/atoms/Input";
import { format, parseISO } from "date-fns";
import { useOutsideClick } from "system/helpers/hooks"; // theme css file
import { ChevronRight16 } from "@carbon/icons-react"
import { useModal } from "modules/modal/hooks";
import DateRangeMobileModal from "../DateRangeMobileModal";
import { useTrans } from "system/translations/hooks";

const DateRange = ({
  value,
  onChange,
  name,
  fromName,
  toName,
  onFormValueChange,
  dateFormat,
  labelText,
  openLeft = false,
  withDateType = true,
}: any) => {
  const checkDate = (date: string) => {
    const parsedDate: any = parseISO(date);
    return parsedDate != "Invalid Date" ? parsedDate : new Date();
  };
  const handlerChange = (val: any) => {
    const from = val["selection"].startDate; //&& format(val["selection"].startDate, "yyyy-MM-dd");
    const to = val["selection"].endDate; //&& format(val["selection"].endDate, "yyyy-MM-dd");
    onChange(format(from, dateFormat), `${name}.${fromName}`);
    onChange(format(to, dateFormat), `${name}.${toName}`);
  };
  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);
  const data = useMemo(() => {
    return {
      startDate: checkDate(value?.[fromName]),
      endDate: checkDate(value?.[toName]),
    };
  }, [value, fromName, toName]);
  useEffect(() => {
    setShow(false);
  }, [clickOutSide]);
  const [type, setType] = useState("created");
  const [show, setShow] = useState(false);
  useEffect(() => {
    onFormValueChange("searchFrom", type);
  }, [type]);
  const renderStaticRangeLabel = () => (
    <div>
      Show period since:
      <div>
        <RadioButton
          onClick={() => setType("created")}
          checked={type === "created"}
          name={"created"}
          labelText={"Transaction was created"}
        />
        <RadioButton
          onClick={() => setType("updated")}
          checked={type === "updated"}
          name={"updated"}
          labelText={"Last updated"}
        />
      </div>
    </div>
  );

  const isMobile = window.matchMedia('(max-width: 671px)').matches;
  const { showModal, updateModal } = useModal();
  const { _t } = useTrans();


  useEffect(() => {
    updateModal({
      modalHeading: _t("date_range"),
      isShowScale: false,
      componentProps: {
        value,
        onChange,
        name,
        fromName,
        toName,
        onFormValueChange,
        dateFormat,
      },
      component: DateRangeMobileModal,
    }, "dateRange")
  },[value]);

  const showDateRange = () => {
    showModal({
      modalHeading: _t("date_range"),
      isShowScale: false,
      componentProps: {
        value,
        onChange,
        name,
        fromName,
        toName,
        onFormValueChange,
        dateFormat,
      },
      component: DateRangeMobileModal,
    },"dateRange");
  }  


  return (
    <div className={"date-range"} ref={ref} style={isMobile ? {} : { width: "220px" }}>
      <div onClick={() => isMobile ? showDateRange() : setShow(!show)}> 
        {!isMobile 
          ? <Input
              value={`${format(data?.startDate, dateFormat)} - ${format(
                data?.endDate,
                dateFormat
              )}`}
              onChange={() => {}}
              labelText={labelText !== "Date range" ? labelText : ''}
            />
        : <div className="date-range-mobile-container">
            <div className="date-range-mobile">
              <div>{_t("date_range")}:</div>
              {`${format(data?.startDate, dateFormat)} - ${format(
                data?.endDate,
                dateFormat
              )}`}
            </div>
              <div><ChevronRight16 /></div> 
          </div>
      }
      </div>
      {show ? (
        <div className={"date-range__body"} style={openLeft ? {right: 0} : {}}>
          <DateRangePicker
            onChange={(item) => handlerChange(item)}
            months={2}
            moveRangeOnFirstSelection={false}
            renderStaticRangeLabel={renderStaticRangeLabel}
            ariaLabels={{}}
            inputRanges={[]}
            ranges={[
              {
                startDate: data?.startDate,
                endDate: data?.endDate,
                key: "selection",
              },
            ]}
            /*            staticRanges={[
              {
                hasCustomRendering: true,
                range: () => ({}),
                isSelected() {
                  return false;
                },
              },
              ...defaultStaticRanges,
            ]}*/
            direction="horizontal"
            // direction="vertical"
          />
          {withDateType && <div className={"date-range__body__period"}>
            <RadioButton
              onClick={() => setType("created")}
              checked={type === "created"}
              name={"created"}
              labelText={"Transaction was created"}
            />
            <RadioButton
              onClick={() => setType("updated")}
              checked={type === "updated"}
              name={"updated"}
              labelText={"Last updated"}
            />
          </div>}
        </div>
      ) : null}
    </div>
  );
};

export default DateRange;

import React from "react";
import Card from "components/atoms/Card";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import {
  useGetMerchantPaymentMethods,
  useGetMerchantPaymentWithdrawalMethods,
  useMerchantDeletePaymentMethod,
  useMerchantEditPaymentMethod,
} from "modules/merchantPayments/apiHooks";
import { useSearchFormConfig, useTableColumns } from "modules/merchantPayments/constantHooks";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Add16, CloseOutline16, Edit16, TrashCan16 } from "@carbon/icons-react";
import { TabsType } from "components/atoms/Tabs/types";
import Tabs from "components/atoms/Tabs";
import { DotsMenuItem } from "components/atoms/DotsMenu/types";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { useIsSubMerchants } from "modules/merchants/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFEFilter, useFilters } from "system/helpers/hooks";
import { useConfirmModal } from "modules/modal/predefinedModals";

const MerchantPayments = () => {
  const history = useHistory();
  const { onConfirm } = useConfirmModal();
  const { filters: filtersDeposit, setFilters: setFiltersDeposit } = useFilters({});
  const { filters: filtersWithdrawal, setFilters: setFiltersWithdrawal } = useFilters({});
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const isSubMerchants = useIsSubMerchants();
  const { data: dataDeposit = [], isLoading } = useGetMerchantPaymentMethods(
    routeParams?.merchantId
  );
  const { mutate: onUpdateMerchantPayments, variables } = useMerchantEditPaymentMethod();
  const { mutate: onDeleteMethod } = useMerchantDeletePaymentMethod();
  const { data: dataWithdrawal = [] } = useGetMerchantPaymentWithdrawalMethods(
    routeParams?.merchantId
  );
  const filteredDataWithdrawal = useFEFilter(dataWithdrawal, filtersWithdrawal);
  const filteredDataDeposit = useFEFilter(dataDeposit, filtersDeposit);

  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const onUpdate = ({ id: paymentId, allData }: any) => {
    const link = generatePath(APP_ROUTES.merchants.payments.update, {
      merchantId: routeParams?.merchantId,
      id: paymentId,
      type: allData.paymentType,
    });
    history.push(link, history.location);
  };
  const onDelete = ({ id, allData }: any) => {
    const data = {
      merchantId: routeParams?.merchantId,
      id,
      paymentType: allData.paymentType,
    };
    onDeleteMethod(data);
  };

  const onFilterSubmitDeposit = async (data: any) => {
    setFiltersDeposit(data);
  };

  const onFilterSubmitWithdrawal = async (data: any) => {
    setFiltersWithdrawal(data);
  };

  const onActionUpdateMethod = (data: any, paymentType: string) => {
    return ({id} : any) => {
      let updatedPayment: any = data.find((payment: any) => payment.id === id);
      const updatedData: any = {
        ...updatedPayment,
        credential: updatedPayment.credential.id,
        credentialName: updatedPayment.credential.key,
        paymentGateway: updatedPayment.provider.id,
        paymentGatewayName: updatedPayment.provider.name,
      }
      delete updatedData.provider;
        (updatedData?.active 
          ? onConfirm({
              onOk: () => {
                onUpdateMerchantPayments({ 
                  ...updatedData,
                  active: false, 
                  merchantId: routeParams?.merchantId,
                  paymentType,
                  id: id,
                });
              },
              modalHeading: _t("deactivating_of_{method}_header_text", {method: (updatedData?.method)}),
              bodyText: _t("deactivating_of_method_body_text"),
              onOkText: _t("deactivate"),
              onOkKind: "danger",
              renderIcon: CloseOutline16,
              isShowScale: false,
            })
          : (() => {
              onUpdateMerchantPayments({ 
                ...updatedData, 
                active: true, 
                merchantId: routeParams?.merchantId,
                paymentType,
                id: id,
              });
            })() 
        );
    };

  }

  const actionMenuItems: DotsMenuItem[] = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      icon: <Edit16 />,
      accessTo: "ROLE_MODIFY_MERCHANT_PM",
    },
    {
      title: _t("delete"),
      onClick: onDelete,
      icon: <TrashCan16 />,
      type: "danger",
      accessTo: "ROLE_MODIFY_MERCHANT_PM",
    },
  ];
  const createBtnPath = isSubMerchants
    ? APP_ROUTES.merchants.sub.payments.create
    : APP_ROUTES.merchants.payments.create;

  const tabs: TabsType = {
    deposit: {
      label: _t("deposit_methods"),
      content: (
        <>
          <FormBuilder
            formItemsConfig={searchFormConfig}
            showSubmit={false}
            formProps={{
              submitBtnLabel: _t("apply_filters"),
              onSubmit: onFilterSubmitDeposit,
              initialValues: {},
              btnColumnParams: {
                lg: { span: 8 },
              },
            }} />
            <Table
              columns={columns}
              isLoading={isLoading}
              data={filteredDataDeposit || []}
              isPagination
              totalRecords={filteredDataDeposit?.length}
              cellProps={{
                actionMenuItems,
                paymentType: "deposit",
                onActionUpdate: onActionUpdateMethod(dataDeposit, 'deposit'),
                variables: variables,
              }} />
            </>
      ),
    },
    withdrawal: {
      label: _t("withdrawal_methods"),
      content: (
        <>
          <FormBuilder
            formItemsConfig={searchFormConfig}
            showSubmit={false}
            formProps={{
              submitBtnLabel: _t("apply_filters"),
              onSubmit: onFilterSubmitWithdrawal,
              initialValues: {},
              btnColumnParams: {
                lg: { span: 8 },
              },
            }} />
            <Table
              columns={columns}
              isLoading={isLoading}
              data={filteredDataWithdrawal || []}
              isPagination
              totalRecords={filteredDataWithdrawal?.length}
              cellProps={{
                actionMenuItems,
                paymentType: "withdrawal",
                onActionUpdate: onActionUpdateMethod(dataWithdrawal, 'withdrawal'),
                variables: variables,
              }} />
          </>
      ),
    },
  };

  return (
    <Card
      title={_t("payment_methods")}
      extra={
        <RoleWrapper
          accessTo={
            isSubMerchants
              ? "ROLE_MODIFY_SUBMERCHANTS"
              : "ROLE_MODIFY_MERCHANT_PM"
          }
        >
          <ButtonLink
            isExpressive
            renderIcon={Add16}
            linkTo={generatePath(createBtnPath, {
              merchantId: routeParams?.merchantId,
            })}
            title={_t("create")}
          />
        </RoleWrapper>
      }
    >
      <Tabs tabs={tabs} name={"payment_methods"} />
    </Card>
  );
};

export default MerchantPayments;

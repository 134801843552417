import React from "react";
import { TextArea as CarbonTextArea } from "carbon-components-react";
import { TextAreaProps } from "components/atoms/TextArea/types";

const TextArea: React.FC<TextAreaProps> = ({
  id = "textAria",
  labelText,
  value,
  onChange,
  hideLabel = false,
  ...rest
}) => {
  return (
    <CarbonTextArea
      {...rest}
      id={id}
      onChange={(e) => onChange(e?.target?.value)}
      value={value}
      labelText={labelText}
      hideLabel={hideLabel}
    />
  );
};

export default TextArea;

import React from "react";
import { Tag } from "carbon-components-react";
import classNames from "classnames";

const FilterTag = ({ label, isFullClickable, value, onClose, type = "default" }: any) => {
  return (
    <Tag onClick={isFullClickable && onClose}
      className={classNames("filter-tag", `filter-tag--${type?.toLowerCase()}`)}
    >
      <span className={"filter-tag"}>
        <span className={"filter-tag__label"}>{label}</span>
        {value ? (
          <span className={"filter-tag__value"}>: {JSON.stringify(value)}</span>
        ) : null}

        <span onClick={() => !isFullClickable && onClose()} className={classNames("filter-tag__close", !isFullClickable && "filter-tag__close-hov")}>
          x
        </span>
      </span>
    </Tag>
  );
};

export default FilterTag;

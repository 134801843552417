import React, { useMemo } from "react";
import Tabs from "components/atoms/Tabs";
import { useTrans } from "system/translations/hooks";
import { TabsType } from "components/atoms/Tabs/types";
import MerchantDetails from "./MerchantDetails";
import CustomerDetails from "./CustomerDetails";
import TransactionDetails from "./TransactionDetails";
import RequestHistory from "modules/requestHistory";
import { usePermissionAccess } from "modules/admin/helperHooks";
import RequestOrchestrationHistory from "modules/requestOrchestrationHistory";

const TransactionInfo = ({ transaction, tab }: any) => {
  const { _t } = useTrans();
  const tabs: TabsType = {
    merchant: {
      label: _t("merchant"),
      content: <MerchantDetails id={transaction?.merchant?.id} />,
      accessTo: "ROLE_VIEW_MERCHANT_DETAILS",
    },
    transaction: {
      label: _t("transaction"),
      content: <TransactionDetails id={transaction.transactionUUID} />,
      accessTo: "ROLE_VIEW_TRANSACTION_DETAILS",
    },
    customer: {
      label: _t("customer"),
      content: <CustomerDetails id={transaction.transactionUUID} />,
      accessTo: "ROLE_VIEW_TRANSACTION_CLIENT",
    },
    history_log: {
      label: _t("history_log"),
      content: (
        <RequestHistory
          showOnlyTable
          initialFilters={{
            request: transaction.transactionUUID,
            field: "transactionUUID",
          }}
        />
      ),
      accessTo: "ROLE_VIEW_HISTORY",
    },
    orchestration_history_log: {
      label: _t("orchestration_history_log"),
      content: (
        <RequestOrchestrationHistory
          initialFilters={{
            request: transaction.transactionUUID,
          }}
        />
      ),
      accessTo: "ROLE_VIEW_ORCHESTRATOR_HISTORY",
    },
  };
  const { checkPermission, isSuperAdmin } = usePermissionAccess();

  const checkedTabs = useMemo(() => {
    let res: TabsType = {};
    for (let key in tabs) {
      const accessControl = checkPermission(tabs[key]?.accessTo);
      if (accessControl || isSuperAdmin) {
        res[key] = tabs[key];
      }
    }
    return res;
  }, [tabs]);

  return <Tabs selected={tab} tabs={checkedTabs} name={"transaction-info"} />;
};

export default TransactionInfo;

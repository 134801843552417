import React from "react";
import CreateSubMerchant from "modules/merchants/components/CreateSubMerchant";

const CreateSubMerchantPage = () => {
  return (
    <div>
      <CreateSubMerchant />
    </div>
  );
};

export default CreateSubMerchantPage;

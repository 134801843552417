import React, { useEffect, useRef, useState } from "react";
import { OverflowMenuProps } from "./types";
import { useOutsideClick } from "system/helpers/hooks";
import { ChevronDown16, ChevronRight16, ChevronUp16, Information16 } from "@carbon/icons-react";
import Search from "../Search";
import { useTrans } from "system/translations/hooks";

const OverflowMenu: React.FC<OverflowMenuProps> = ({items, title, amount, itemKey, itemsHint, onUpdate }) => {
  const { _t } = useTrans()
  const [show, setShow] = useState(false);
  const [inputValueState, setInputValueState] = useState('');
  const [visibleItems, setVisibleItems] = useState(items);
  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);
  useEffect(() => {
    setShow(false);
    setInputValueState('')
  }, [clickOutSide]);

  useEffect(() => {
    setVisibleItems(items)
  },[items])

  useEffect(() => {
    inputValueState?.trim() 
        ? setVisibleItems([...items].filter((item: any) => item[itemKey].toLowerCase().includes(inputValueState?.trim().toLowerCase())))
        : setVisibleItems(items)
  }, [inputValueState])
  
  return (
    <div style={{ position: "relative" }}>
      <div
        onClick={() => !clickOutSide && setShow(!show)}
        style={{
          zIndex: 1,
          cursor: "pointer",
        }}
      >
        <div className={"overflow-menu__header-container"} >
        {title}
        <div className={"overflow-menu__amount"}>{amount}</div>
        {show ? <ChevronUp16 />  : <ChevronDown16 /> }
        </div>
      </div>
      {show ? (
        <div
          className={"overflow-menu__content"}
          ref={ref}
        >
          <Search 
            value={inputValueState} 
            onChange={setInputValueState} 
            placeHolderText={_t('search')} 
            className={'overflow-menu-search-wrapper'}/>
            <div className="overflow-menu__information">
              <Information16 /> {itemsHint}
            </div>
            <div className="overflow-menu__item-container">
          {visibleItems.map((item: any) => {
            return (
              <div className="overflow-menu__item" onClick={() => onUpdate({
                merchantId: item.merchantId,
                paymentGateway: item.paymentGatewayCode,
                id: item.id
              })}>{item[itemKey]} <ChevronRight16/></div>
            )
          })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OverflowMenu;

import React from "react";

const AnalyticRenderLegend = (props: any) => {
  const { payload } = props;
    const unique = payload.filter(
      (obj: any, index: number) => payload.findIndex((item: any) => item.value === obj.value) === index
    );
   return (
      <ul className="custom-legend__container">
        {
          unique.map((entry: any, index: number) => {
            return (
              <div className="custom-legend__item">
                {entry.value.includes('Quantity')
                  ? (
                    <div className="quantity-icon" style={{ backgroundColor: entry.color }}>
                      <div style={{ backgroundColor: entry.color }}></div>
                    </div>
                  )
                  : (
                    <div className="custom-legend__item--icon" style={{ backgroundColor: entry.color }}>
                    </div>
                  )
                }
                <li key={`item-${index}`}>{entry.value}</li>
              </div>
            )
          })
        }
      </ul>
    );
};

export default AnalyticRenderLegend;

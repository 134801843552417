import React from "react";
import { Tab as CarbonTab, Tabs as CarbonTabs } from "carbon-components-react";
import { TabsProps, TabItem } from "components/atoms/Tabs/types";

const Tabs: React.FC<TabsProps> = ({ tabs, name = "tabs", selected = "" }) => {
  const selectedTab =
    Object.keys(tabs).indexOf(selected) >= 0
      ? Object.keys(tabs).indexOf(selected)
      : 0;
  return (
    <CarbonTabs selected={selectedTab}>
      {Object.values(tabs).map((item: TabItem, idx: number) => {
        return (
          <CarbonTab key={name + idx} label={item.label}>
            {item.content}
          </CarbonTab>
        );
      })}
    </CarbonTabs>
  );
};

export default Tabs;

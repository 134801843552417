import * as Yup from "yup";
import { TransType } from "system/translations/types";
import { StatusCallbackResponsePullCreateUpdateItemType } from "./types";

export const createEditValidation = (_t: TransType) => {
  return Yup.object().shape({
    type: Yup.string().required(_t("required")),
    gatewayCode: Yup.string().required(_t("required")),
    comment: Yup.string()
  });
};

export const createEditStatusConnectionConfigurationSettingsValidation = (_t: TransType, type: StatusCallbackResponsePullCreateUpdateItemType ) => {
  switch(type) {
    case 'statusName':
      return Yup.object().shape({
        statusName: Yup.string().trim().required(_t("can_not_be_empty")).nullable()
      });
    case 'codePriority':
      return Yup.object().shape({
        codePriority: Yup.number().required(_t("can_not_be_empty")).moreThan(0).typeError(_t("must_be_a_number")).nullable()
      });
    case 'sequenceNumber':
      return Yup.object().shape({
        sequenceNumber: Yup.number().required(_t("can_not_be_empty")).moreThan(0).typeError(_t("must_be_a_number")).nullable()
      });
    case 'statusReasonOption':
      return Yup.object().shape({
        statusReasonOption: Yup.object().required(_t("can_not_be_empty")).nullable()
      });
    case 'errorDetailKey':
      return Yup.object().shape({
        errorDetailKey: Yup.string().trim().required(_t("can_not_be_empty")).nullable()
      });
    case 'errorValue':
      return Yup.object().shape({
        errorValue: Yup.string().trim().required(_t("can_not_be_empty")).nullable()
      });
  }
};

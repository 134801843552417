import React, {  useEffect, useMemo, useState } from "react";
import {  ChevronDown16, ChevronUp16 } from "@carbon/icons-react";
import { useTrans } from "system/translations/hooks";
import RadioButton from "components/atoms/RadioButton";
import Search from "components/atoms/Search";
import Loader from "components/atoms/Loader";
import { useGetMerchantsByProvider } from "modules/MerchantsFees/apiHooks";
import { Checkbox } from "carbon-components-react";


const CheckboxTreeView = ({
  id,
  item,
  data,
  onChange,
  value,
  isGroupByMerchant
}: {
  id: number,
  item: any,
  data?: any,
  onChange: (selectedItems: any[]) => void ,
  value: any,
  isGroupByMerchant: boolean,
}) => {
  const [open, setOpen] = useState(false)
  const dataValue = value || []

  useEffect(() => {
    setOpen(false)
  }, [isGroupByMerchant])

  return (
    <>
    <div className="tree-view__multi-select__item" style={open ? {backgroundColor: "#F3F5F6"} :{}}>
      {open ? <ChevronUp16 /> : <ChevronDown16 /> }
    <Checkbox labelText={item.split(':')[0]} key={"id" + id + item} id={"id" + id + item} indeterminate={open} onClick={() => setOpen(!open)} />
    </div>
    <div style={!open ? {display: 'none'} : {}} className='tree-view__multi-select__sub-item'>
      {data[item].map((el:any) => {
        return (
          <div>
            <Checkbox 
              key={!isGroupByMerchant ? "id" + el.merchantName + item : "id" + el.paymentGatewayName + item}
              labelText={!isGroupByMerchant ? el.merchantName : el.paymentGatewayName} 
              id={!isGroupByMerchant ? "id" + el.merchantName + item : "id" + el.paymentGatewayName + item} 
              onChange={(data: any) => {
                  if (data) {
                    onChange([...dataValue,{
                      merchantId: el.merchantId,
                      paymentGatewayCode: el.paymentGatewayCode,
                    }])
                  } else {
                    onChange(dataValue.filter((item: any) => (
                      JSON.stringify(item) !== JSON.stringify({
                        merchantId: el.merchantId,
                        paymentGatewayCode: el.paymentGatewayCode,
                      })) && item))
                  }
              }}
            />
          </div>
        )
      })}
    </div>
    </>
  )
}



const TreeViewMultiselect: React.FC<{
  value: any;
  onChange: (selectedItems: any[]) => void;
}> = ({
  onChange,
  value,
}) => {
  
  const { _t } = useTrans()
  const [inputValueState, setInputValueState] = useState('');
  const [groupBy, setGroupBy] = useState(_t('merchant'))
  const isGroupByMerchant = groupBy === _t('merchant')
  const requestParams = useMemo(() => (
    { groupingType: isGroupByMerchant 
      ? 'merchant'
      : 'gateway' 
    }
  ), [groupBy]); 
  
  const {data = {}, isLoading} = useGetMerchantsByProvider(requestParams);
  const [filteredData, setFilteredData] = useState(data)


  useEffect(()=> {
    onChange([])
    setInputValueState('')
  }, [groupBy])

  useEffect(() => {
    const filterData: any = {}

    if (inputValueState && data) { 
      for (let key in data) {
        if (key.toLowerCase().includes(inputValueState?.trim().toLowerCase())) {
          filterData[key] = data[key]
        }
      }
      setFilteredData(filterData)
    } else if (!inputValueState && Object.keys(data).length > 0 && JSON.stringify(data) !== JSON.stringify(filteredData)) {
      setFilteredData(data)
    }
    
  }, [inputValueState, data])

  const setColumnOrderPSPToMerchant = (type: string) => {
    setGroupBy(type)
  }
  
  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="tree-view__container">
    <div>
      <div className="tree-view__groupBy">
        <RadioButton 
            labelText= {_t("group_by")}
            labelA= {_t("merchant")}
            labelB= {_t("payment_provider")}
            value={groupBy}
            onValueChange={setColumnOrderPSPToMerchant}
          />
        </div>
      <div>
        <Search 
          value={inputValueState} 
          onChange={setInputValueState} 
          placeHolderText={_t('search')} 
          className={'tree-view__search'}
          />
      </div>
      <div className="tree-view__multi-select">
        {Object.keys(filteredData)?.map((item: any, idx) => {

          return (
            <div className="tree-view__multi-select__item-container">
              <CheckboxTreeView id={idx} item={item} data={filteredData} onChange={onChange} value={value} isGroupByMerchant={groupBy === _t('merchant')}/>
            </div>
          )
        })}
      </div>
    </div>
     <div className="tree-view__amount">{value?.length || 0} selected</div>
    </div>
  )
}

export default TreeViewMultiselect
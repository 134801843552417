import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_TRANSACTIONS,
  API_GET_TRANSACTION,
  API_GET_TRANSACTIONS_EXPORT,
  API_UPDATE_RESEND_IPN,
  API_UPDATE_TRANSACTION,
  API_GET_CLIENT,
  API_CHANGE_CUSTOMER_STATUS,
  API_GET_KIBANA_HISTORY_BY_TRANSACTION,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useToast } from "modules/toast/hooks";
import { useTrans } from "system/translations/hooks";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  ClientDataType,
  TransactionByIdDataType,
  TransactionDataType,
} from "modules/transactions/types";

const getTransactionsKey = "getTransactionsKey";
const getTransactionKey = "getTransactionKey";
const getClientKey = "getClientKey";
export const useGetTransactions = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getTransactionsKey, JSON.stringify(filters)],
    (): Promise<TransactionDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_TRANSACTIONS, filters),
        method: "get",
      }),
    {
      staleTime: 0,
    }
  );
};
export const useGetTransaction = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getTransactionKey, id],
    (): Promise<TransactionByIdDataType> =>
      apiRequest({
        url: generatePath(API_GET_TRANSACTION, { id }),
        method: "get",
      })
  );
};
export const useGetTransactionsExport = () => {
  const [apiRequest] = useApiRequest();
  const toastHandler = useToast();
  const { _t } = useTrans();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generateUrlQuery(API_GET_TRANSACTIONS_EXPORT, data),
        method: "get",
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          caption: _t("export_csv_notification"),
          kind: "success",
        });
      },
    }
  );
};

export const useResendIpn = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (id: string) =>
      apiRequest({
        url: generatePath(API_UPDATE_RESEND_IPN, { id }),
        method: "post",
      }),
    {
      onSuccess: () => {
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useChangeCustomerStatus = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      apiRequest({
        url: API_CHANGE_CUSTOMER_STATUS,
        method: "post",
        data,
      }),
    {
      onSuccess: () => {
        apiSuccessMessage();
        queryClient.invalidateQueries(getClientKey);
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateTransactionStatus = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_UPDATE_TRANSACTION,
        method: "post",
        data,
      }),
    {
      onSuccess: () => {
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetClient = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getClientKey, id],
    (): Promise<ClientDataType> =>
      apiRequest({
        url: generatePath(API_GET_CLIENT, { id }),
        method: "get",
      })
  );
};

export const useGetKibanaTransactionUrl = () => {
  const [apiRequest] = useApiRequest();
  return useMutation(
    (transactionUUID: any) =>
      apiRequest({
        url: generatePath(API_GET_KIBANA_HISTORY_BY_TRANSACTION, { transactionUUID }),
        method: "get",
      })
  );
};


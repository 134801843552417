import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "system/helpers/hooks";
import { OverflowClearUpdateMenuItem, OverflowClearUpdateMenuProps } from "./types";

const OverflowClearUpdateMenu: React.FC<OverflowClearUpdateMenuProps> = ({ items, clickParams = {} }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);
  
  useEffect(() => {
    setShow(false);
  }, [clickOutSide]);
  
  return (
    <div className="overflow-clear-update-menu__container">
      <div onClick={() => !clickOutSide && setShow(!show)} className="overflow-clear-update-menu__button">
        ...
      </div>
      {show ? (
        <div className={"overflow-clear-update-menu__content"} ref={ref}>
          {items.map(({ icon, onClick, title, type }: OverflowClearUpdateMenuItem) => {
        return (
                <div
                  className={classNames(
                    "overflow-clear-update-menu__item",
                    type && `dots-menu__item--${type}`
                  )}
                  onClick={() => {
                    onClick && onClick();
                    setShow(false)
                  }}
                >
                  {icon && <span>{icon}</span>}
                  <span>{title}</span>
                </div>
        );
      })}
        </div>
      ) : null}
    </div>
      
  );
};

export default OverflowClearUpdateMenu;

import Select from "components/atoms/Select";
import { useTrans } from "system/translations/hooks";
import { FormConfigItemType } from "modules/formBuilder/types";
import MultiSelect from "components/atoms/MultiSelect";
import SearchWithFieldSelect from "components/atoms/SearchWithFieldSelect";
import Search from "components/atoms/Search";
import DateRange from "components/molecules/DateRange";
import Btn from "components/atoms/Btn";
import Input from "components/atoms/Input";
import TextArea from "components/atoms/TextArea";
import InputPassword from "components/atoms/InputPassword";
import config from "system/config";
import CheckboxPanel from "components/molecules/CheckboxPanel";
import Toggle from "components/atoms/Toggle";
import Checkbox from "components/atoms/Checkbox";
import React from "react";
import { Delete16, Draggable16, Search16 } from "@carbon/icons-react";
import Divider from "components/atoms/Divider";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import InputWithFilterTag from "components/atoms/InputWithFilterTag";
import JsonEditor from "components/atoms/JsonEditor";
import PaymentOrchestrationTreeViewRule from "modules/routingRules/components/RoutingRulesTreeView";
import OperatorChangerButton from "components/atoms/OperatorChangerButton";
import ReorderArrows from "components/atoms/ReorderArrows";
import Calendar from "components/molecules/Calendar";
import { HistoryLink } from "components/atoms/HistoryLink";
import RadioButton from "components/atoms/RadioButton";
import InfoBlock from "components/atoms/InfoBlock";
import TreeViewMultiselect from "components/molecules/TreeViewMultiSelect";
import MonthPicker from "components/molecules/MonthPicker";
//: { [key: string]: FormConfigItemType }

type FieldsType = {
  filters: {
    merchantId: (config?: any) => FormConfigItemType;
    merchantIdV2: (config?: any) => FormConfigItemType;
    merchantIdSingle: (config?: any) => FormConfigItemType;
    paymentMethod: (config?: any) => FormConfigItemType;
    paymentGateway: (config?: any) => FormConfigItemType;
    operation: (config?: any) => FormConfigItemType;
    status: (config?: any) => FormConfigItemType;
    type: (config?: any) => FormConfigItemType;
    fields: (config?: any) => FormConfigItemType;
    filterValue: (config?: any) => FormConfigItemType;
    filterField: (config?: any) => FormConfigItemType;
    date: (config?: any) => FormConfigItemType;
    securityGroupId: (config?: any) => FormConfigItemType;
    merchants: (config?: any) => FormConfigItemType;
    active: (config?: any) => FormConfigItemType;
    filterName: (config?: any) => FormConfigItemType;
    filterWithSelectFieldForInput: (config?: any) => FormConfigItemType;
    currency: (config?: any) => FormConfigItemType;
    userEmail: (config?: any) => FormConfigItemType;
    actionType: (config?: any) => FormConfigItemType;
    entityType: (config?: any) => FormConfigItemType;
    request: (config?: any) => FormConfigItemType;
    key: (config?: any) => FormConfigItemType;
    smallToggle: (config?: any) => FormConfigItemType; 
    groupBy: (config?: any) => FormConfigItemType; 
    ipnSent: (config?: any) => FormConfigItemType; 
    statusReason: (config?: any) => FormConfigItemType; 
  };
  general: {
    submitBtn: (config?: any) => FormConfigItemType;
    saveBtn: (config?: any) => FormConfigItemType;
    searchBtn: (config?: any) => FormConfigItemType;
    removeBtn: (config?: any) => FormConfigItemType;
    dragDropBtn: (config?: any) => FormConfigItemType;
    reorderArrows: (config?: any) => FormConfigItemType;
    divider: (config?: any) => FormConfigItemType;
    historyLink: (config?: any) => FormConfigItemType;
    infoBlock: (config?: any) => FormConfigItemType;
  };
  formFields: {
    key: (config?: any) => FormConfigItemType;
    transKeys: (config?: any) => FormConfigItemType;
    name: (config?: any) => FormConfigItemType;
    kycMaxAmount: (config?: any) => FormConfigItemType;
    merchantIdSingle: (config?: any) => FormConfigItemType;
    alertType: (config?: any) => FormConfigItemType;
    paymentGatewayCode: (config?: any) => FormConfigItemType;
    successRateCheckLength: (config?: any) => FormConfigItemType;
    successRateThreshold: (config?: any) => FormConfigItemType;
    errorsInARowThreshold: (config?: any) => FormConfigItemType;
    depositsPercentage: (config?: any) => FormConfigItemType;
    withdrawalsPercentage: (config?: any) => FormConfigItemType;
    depositsCheckingEnabled: (config?: any) => FormConfigItemType;
    withdrawalsCheckingEnabled: (config?: any) => FormConfigItemType;
    checkDepositsOlderThan: (config?: any) => FormConfigItemType;
    checkDepositsTo: (config?: any) => FormConfigItemType;
    checkWithdrawalsOlderThan: (config?: any) => FormConfigItemType;
    checkWithdrawalsTo: (config?: any) => FormConfigItemType;
    successRateCheckEnabled: (config?: any) => FormConfigItemType;
    maxSingleAmountCheckEnabled: (config?: any) => FormConfigItemType;
    maxSingleAmount: (config?: any) => FormConfigItemType;
    maxCumulativeAmount: (config?: any) => FormConfigItemType;
    cumulativeAmountCheckTimeFrame: (config?: any) => FormConfigItemType;
    cumulativeAmountCheckLength: (config?: any) => FormConfigItemType;
    maxCumulativeAmountCheckEnabled: (config?: any) => FormConfigItemType;
    errorsInARowCheckEnabled: (config?: any) => FormConfigItemType;
    maxAmount: (config?: any) => FormConfigItemType;
    countriesSupported: (config?: any) => FormConfigItemType;
    description: (config?: any) => FormConfigItemType;
    allCountries: (config?: any) => FormConfigItemType;
    defaultToggle: (config?: any) => FormConfigItemType;
    refundable: (config?: any) => FormConfigItemType;
    storedSupported: (config?: any) => FormConfigItemType;
    voucherAuto: (config?: any) => FormConfigItemType;
    authority: (config?: any) => FormConfigItemType;
    password: (config?: any) => FormConfigItemType;
    confirm_password: (config?: any) => FormConfigItemType;
    email: (config?: any) => FormConfigItemType;
    securityGroupId: (config?: any) => FormConfigItemType;
    merchants: (config?: any) => FormConfigItemType;
    transactionUUID: (config?: any) => FormConfigItemType;
    comment: (config?: any) => FormConfigItemType;
    ip: (config?: any) => FormConfigItemType;
    transactionStatus: (config?: any) => FormConfigItemType;
    paymentType: (config?: any) => FormConfigItemType;
    configurationType: (config?: any) => FormConfigItemType;
    transactionType: (config?: any) => FormConfigItemType;
    strategy: (config?: any) => FormConfigItemType;
    needToMaskSensitiveData: (config?: any) => FormConfigItemType;
    activeToggle: (config?: any) => FormConfigItemType;
    code: (config?: any) => FormConfigItemType;
    rememberMe: (config?: any) => FormConfigItemType;
    authenticationCode: (config?: any) => FormConfigItemType;
    state: (config?: any) => FormConfigItemType;
    city: (config?: any) => FormConfigItemType;
    postcode: (config?: any) => FormConfigItemType;
    address: (config?: any) => FormConfigItemType;
    street: (config?: any) => FormConfigItemType;
    domain: (config?: any) => FormConfigItemType;
    walletId: (config?: any) => FormConfigItemType;
    comType: (config?: any) => FormConfigItemType;
    ThreeDStatus: (config?: any) => FormConfigItemType;
    mcc: (config?: any) => FormConfigItemType;
    descriptor: (config?: any) => FormConfigItemType;
    ipns: (config?: any) => FormConfigItemType;
    depositIpnUrl: (config?: any) => FormConfigItemType;
    withdrawalIpnUrl: (config?: any) => FormConfigItemType;
    v1IpnUrl: (config?: any) => FormConfigItemType;
    pushDepositIpnUrl: (config?: any) => FormConfigItemType;
    useSeparateUrlForV1Api: (config?: any) => FormConfigItemType;
    usePushDepositUrl: (config?: any) => FormConfigItemType;
    useSeparateUrlV2DepositAndWithdrawal: (config?: any) => FormConfigItemType;
    getAllGateways: (config?: any) => FormConfigItemType;
    allowPartialRefund: (config?: any) => FormConfigItemType;
    allowPartialCapture: (config?: any) => FormConfigItemType;
    isCascadingAllowed: (config?: any) => FormConfigItemType;
    apiV2: (config?: any) => FormConfigItemType;
    allowDynamicDescriptor: (config?: any) => FormConfigItemType;
    avsCheck: (config?: any) => FormConfigItemType;
    disableToggle: (config?: any) => FormConfigItemType;
    selectedPaymentMethods: (config?: any) => FormConfigItemType;
    selectedCurrencies: (config?: any) => FormConfigItemType;
    selectCurrencies: (config?: any) => FormConfigItemType;
    selectedCredentials: (config?: any) => FormConfigItemType;
    operationType: (config?: any) => FormConfigItemType;
    priority: (config?: any) => FormConfigItemType;
    json: (config?: any) => FormConfigItemType;
    redirectType: (config?: any) => FormConfigItemType;
    dateManual: (config?: any) => FormConfigItemType;
    validFrom: (config?: any) => FormConfigItemType;
    monthPicker: (config?: any) => FormConfigItemType;
    depositFees: (config?: any) => FormConfigItemType;
    treeViewMultiselect: (config?: any) => FormConfigItemType;
    fixedFee: (config?: any) => FormConfigItemType;
    withdrawalFees: (config?: any) => FormConfigItemType;
    transactionFees: (config?: any) => FormConfigItemType;
    rrForDeposit: (config?: any) => FormConfigItemType;
    numberOfDays: (config?: any) => FormConfigItemType;
    percentage: (config?: any) => FormConfigItemType;
    settlementsFees: (config?: any) => FormConfigItemType;
    amount: (config?: any) => FormConfigItemType;
    paymentProviders: (config?: any) => FormConfigItemType;
    selectCurrenciesWithSearch: (config?: any) => FormConfigItemType;
    selectStatus: (config?: any) => FormConfigItemType;
    selectWithSearch: (config?: any) => FormConfigItemType;
    rulesOrchestration: (config?: any) => FormConfigItemType;
    select: (config?: any) => FormConfigItemType;
    strategyType: (config?: any) => FormConfigItemType;
    weightValue: (config?: any) => FormConfigItemType;
    operatorChangerButton: (config?: any) => FormConfigItemType;
    statusReasonCode: (config?: any) => FormConfigItemType;
    statusReasonTitle: (config?: any) => FormConfigItemType;
    statusReasonDescription: (config?: any) => FormConfigItemType;
    statusReasonCodePriority: (config?: any) => FormConfigItemType;
    statusReasonSequenceNumber: (config?: any) => FormConfigItemType;
    errorDetailKey: (config?: any) => FormConfigItemType;
    slackChannelName: (config?: any) => FormConfigItemType;
    merchantSlackChannelSelect: (config?: any) => FormConfigItemType;
  };
};

export const useDefaultFieldsConfig = (): FieldsType => {
  const { _t } = useTrans();
  const fields = {
    filters: {
      merchantId: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "merchantId",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("merchants"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      merchantIdV2: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "merchantId",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("merchants"),
          labelWithAll: false,
          light: true,
          wrapClassPrefix: "menu-full-width",
          withSelectAll: false,
          singleSelect: true,
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      merchantIdSingle: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        // openModal: true,
        name: "merchantId",
        componentProps: {
          items: componentProps?.options,
          hideLabel: true,
          isOpen: false,
          openModal: false,
          ...componentProps,
        },
        columnParams: {
          md: 3,
          lg: 3,
          ...columnParams,
        },
        ...rest,
      }),
      paymentMethod: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "paymentMethod",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("payment_methods"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      paymentGateway: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "paymentGateway",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("payment_gateways"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      operation: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "operation",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("operation_types"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      status: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "status",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("transaction_statuses"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      type: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "type",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("transaction_types"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      fields: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "fields",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("export_fields"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      ipnSent: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "ipnSent",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("ipn_sent"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      statusReason: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "statusReason",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("status_reason"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      filterValue: ({
        componentProps,
        columnParams,
        emptySpace,
        name = "",
        ...rest
      }: any = {}) => ({
        component: Search,
        name: name ? name : "filterValue",
        componentProps: {
          labelText: _t("search"),
          placeHolderText: _t("search"),
          searchIconDescription: _t("search"),
          ...componentProps,
        },
        columnParams: {
          md: { span: 5 },
          lg: { span: 5 },
          ...columnParams,
        },
        emptySpace: {
          md: 16,
          lg: 16,
          ...emptySpace,
        },
        ...rest,
      }),
      filterField: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Select,
        name: "filterField",
        componentProps: {
          items: componentProps?.options,
          hideLabel: true,
          ...componentProps,
        },
        columnParams: {
          md: 3,
          lg: 3,
          ...columnParams,
        },
        ...rest,
      }),
      date: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: DateRange,
        openModal: true,
        name: "date",
        valuePreview: true,
        componentProps: {
          labelText: _t("date_range"),
          fromName: "fromDate",
          toName: "toDate",
          dateFormat: config.dateFormat,
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      securityGroupId: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "securityGroupId",
        componentProps: {
          items: componentProps?.options,
          label: _t("roles"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      merchants: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "merchants",
        componentProps: {
          items: componentProps?.options,
          label: _t("merchants"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      active: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "active",
        componentProps: {
          items: componentProps?.options,
          label: _t("active"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      filterName: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "name",
        componentProps: {
          labelText: _t("search_by_name"),
          placeHolderText: _t("search_by_name"),
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      filterWithSelectFieldForInput: ({ componentProps, columnParams, emptySpace,...rest }: any = {}) => ({
        component: SearchWithFieldSelect,
        name: "searchWithFieldSelect",
        componentProps: {
          labelText: _t("search"),
          placeHolderText: _t("search"),
          ...componentProps,
        },
        columnParams: {
          md: 9,
          lg: 9,
        },
        emptySpace: {
          sm: { span: 10 },
          lg: { span: 10 },
          md: { span: 10 },
          ...emptySpace
        },
        ...rest,
      }),
      currency: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "currency",
        componentProps: {
          items: componentProps?.options,
          label: _t("currencies"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      entityType: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "entityType",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("entity_type"),
          light: false,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      actionType: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "actionType",
        valuePreview: true,
        componentProps: {
          items: componentProps?.options,
          label: _t("action_type"),
          light: false,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      userEmail: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "userEmail",
        componentProps: {
          labelText: _t("email"),
          placeHolderText: _t("email"),
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      request: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "request",
        componentProps: {
          labelText: _t("search"),
          placeHolderText: _t("search"),
          SearchIconDescription: _t("search"),
          showSearch: true,
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      smallToggle: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "pspToMerchant",
        componentProps: {
          labelText: _t("psp_to_merchant"),
          id: "pspToMerchant",
          size: 'sm',
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      groupBy: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: RadioButton,
        name: "pspToMerchant",
        componentProps: {
          labelText: _t("group_by"),
          labelA: _t("merchant"),
          labelB: _t("payment_provider"),
          id: "pspToMerchant",
          size: 'sm',
          ...componentProps,
        },
        columnParams: {
          className: "radio-button__container",
          ...columnParams,
        },
        ...rest,
      }),
      key: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "key",
        componentProps: {
          labelText: _t("search"),
          placeHolderText: _t("search"),
          SearchIconDescription: _t("search"),
          showSearch: true,
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
    },
    formFields: {
      transactionUUID: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "transactionUUID",
        componentProps: {
          labelText: _t("transactionUUID"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      paymentGatewayCode: ({
        componentProps,
        columnParams,
        name = '',
        ...rest
      }: any = {}) => ({
        component: SelectWithSearch,
        name: name ? name : "paymentGatewayCode",
        componentProps: {
          items: componentProps?.options,
          labelText: _t("payment_gateway_code"),
          ...componentProps,
        },
        columnParams: {
          md: 16,
          lg: 16,
          ...columnParams,
        },
        ...rest,
      }),
      selectCurrenciesWithSearch: ({
        componentProps,
        columnParams,
        name = '',
        ...rest
      }: any = {}) => ({
        component: SelectWithSearch,
        name: name ? name : "selectCurrenciesWithSearch",
        componentProps: {
          items: componentProps?.options,
          labelText: _t('currency'),
          placeholderText: _t('select_currency'),
          ...componentProps,
        },
        columnParams: {
          md: 16,
          lg: 16,
          ...columnParams,
        },
        ...rest,
      }),
      selectStatus: ({
        componentProps,
        columnParams,
        name = '',
        ...rest
      }: any = {}) => ({
        component: SelectWithSearch,
        name: name ? name : "selectStatus",
        componentProps: {
          items: componentProps?.options,
          labelText: _t('status'),
          placeholderText: _t('status'),
          ...componentProps,
        },
        columnParams: {
          md: 16,
          lg: 16,
          ...columnParams,
        },
        ...rest,
      }),
      successRateCheckLength: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "successRateCheckLength",
        componentProps: {
          labelText: _t("success_rate_check_length"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      successRateThreshold: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "successRateThreshold",
        componentProps: {
          labelText: _t("success_rate_threshold"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      errorsInARowThreshold: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "errorsInARowThreshold",
        componentProps: {
          labelText: _t("errors_in_row_threshold"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      checkDepositsOlderThan: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "checkDepositsOlderThan",
        componentProps: {
          labelText: _t("check_deposits_older_than"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      checkWithdrawalsOlderThan: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "checkWithdrawalsOlderThan",
        componentProps: {
          labelText: _t("check_withdrawals_older_than"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      checkDepositsTo: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "checkDepositsTo",
        componentProps: {
          labelText: _t("check_deposits_to"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      checkWithdrawalsTo: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "checkWithdrawalsTo",
        componentProps: {
          labelText: _t("check_withdrawals_to"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      depositsPercentage: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "depositsPercentage",
        componentProps: {
          labelText: _t("deposits_percentage"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      withdrawalsPercentage: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "withdrawalsPercentage",
        componentProps: {
          labelText: _t("withdrawals_percentage"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      comment: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: TextArea,
        name: "comment",
        componentProps: {
          labelText: _t("comment"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      description: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: TextArea,
        name: "description",
        componentProps: {
          labelText: _t("description"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      transactionStatus: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "status",
        componentProps: {
          items: config.transactionStatuses || componentProps?.options,
          labelText: _t("status"),
          light: true,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      key: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "key",
        componentProps: {
          labelText: _t("key"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      transKeys: ({
        componentProps,
        columnParams,
        name,
        ...rest
      }: any = {}) => ({
        component: TextArea,
        name: name,
        componentProps: {
          labelText: (param: any) =>
            _t("language{fieldName}", { fieldName: param.fieldName }),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      name: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "name",
        componentProps: {
          labelText: _t("name"),
          placeholder: _t("name"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      merchantIdSingle: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: SelectWithSearch,
        name: "merchantId",
        componentProps: {
          items: componentProps?.options,
          labelText: _t("merchant"),
          ...componentProps,
        },
        columnParams: {
          md: 16,
          lg: 16,
          ...columnParams,
        },
        ...rest,
      }),
      selectWithSearch: ({
        name = "",
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: SelectWithSearch,
        name: name ? name : "select",
        componentProps: {
          items: componentProps?.options,
          ...componentProps,
        },
        columnParams: {
          md: 16,
          lg: 16,
          ...columnParams,
        },
        ...rest,
      }),
      rulesOrchestration: ({
        name = "",
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: PaymentOrchestrationTreeViewRule,
        name: name ? name : "rulesOrchestration",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          md: 16,
          lg: 16,
          ...columnParams,
        },
        ...rest,
      }),
      strategyType: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: RadioButton,
        name: "strategyType",
        componentProps: {
          labelText: _t("strategy_type"),
          labelA: _t("direct"),
          valueA: "direct",
          labelB: _t("weight"),
          valueB: "weight",
          additionalRadioButtonsProps: [{label: _t("priority"), value: "priority"}],
          id: "strategyType",
          size: 'sm',
          ...componentProps,
        },
        columnParams: {
          className: "radio-button__container",
          lg: 9,
          md: 5,
          ...columnParams,
        },
        ...rest,
      }),
      operatorChangerButton: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: OperatorChangerButton,
        name: "operator",
        componentProps: {
          labelA: 'And',
          valueA: "and",
          labelB: 'Or',
          valueB: "or",
          ...componentProps,
        },
        columnParams: {
          lg: 2,
          md: 2,
          ...columnParams,
        },
        ...rest,
      }),
      kycMaxAmount: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "kycMaxAmount",
        componentProps: {
          labelText: _t("kyc_max_amount"),
          placeHolderText: _t("kyc_max_amount"),
          id: "kycMaxAmount",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      maxSingleAmount: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "maxSingleAmount",
        componentProps: {
          labelText: _t("max_single_amount"),
          placeHolderText: _t("max_single_amount"),
          id: "maxSingleAmount",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      maxCumulativeAmount: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "maxCumulativeAmount",
        componentProps: {
          labelText: _t("max_cumulative_amount"),
          placeHolderText: _t("max_cumulative_amount"),
          id: "maxCumulativeAmount",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      cumulativeAmountCheckTimeFrame: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "cumulativeAmountCheckTimeFrame",
        componentProps: {
          labelText: _t("cumulative_amount_check_time_frame"),
          placeHolderText: _t("cumulative_amount_check_time_frame"),
          id: "cumulativeAmountCheckTimeFrame",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      cumulativeAmountCheckLength: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "cumulativeAmountCheckLength",
        componentProps: {
          labelText: _t("cumulative_amount_check_length"),
          placeHolderText: _t("cumulative_amount_check_length"),
          id: "cumulativeAmountCheckLength",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      alertType: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Select,
        name: "alertType",
        componentProps: {
          labelText: _t("alert_type"),
          placeHolderText: _t("alert_type"),
          id: "alert_type",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      countriesSupported: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "countriesSupported",
        componentProps: {
          items: componentProps?.options,
          label: _t("countries_supported"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      maxAmount: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "maxAmount",
        componentProps: {
          labelText: _t("max_amount"),
          placeHolderText: _t("max_amount"),
          id: "maxAmount",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      pushDepositIpnUrl: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "pushDepositIpnUrl",
        componentProps: {
          labelText: _t("push_deposit_ipn_url"),
          placeHolderText: _t("push_deposit_ipn_url"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      v1IpnUrl: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "v1IpnUrl",
        componentProps: {
          labelText: _t("v1_ipn_url"),
          placeHolderText: _t("v1_ipn_url"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      withdrawalIpnUrl: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "withdrawalIpnUrl",
        componentProps: {
          labelText: _t("withdrawal_ipn_url"),
          placeHolderText: _t("withdrawal_ipn_url"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      depositIpnUrl: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "depositIpnUrl",
        componentProps: {
          labelText: _t("deposit_ipn_url"),
          placeHolderText: _t("deposit_ipn_url"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      ipns: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "ipns",
        componentProps: {
          labelText: _t("ipns"),
          placeHolderText: _t("ipns"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      descriptor: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "descriptor",
        componentProps: {
          labelText: _t("descriptor"),
          placeHolderText: _t("descriptor"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      mcc: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "mcc",
        componentProps: {
          labelText: _t("mcc"),
          placeHolderText: _t("mcc"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      ThreeDStatus: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "3dStatus",
        componentProps: {
          labelText: _t("3d_status"),
          placeHolderText: _t("3d_status"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      address: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "address",
        componentProps: {
          labelText: _t("address"),
          placeHolderText: _t("address"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      street: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "street",
        componentProps: {
          labelText: _t("street"),
          placeHolderText: _t("street"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      domain: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "domain",
        componentProps: {
          labelText: _t("domain"),
          placeHolderText: _t("domain"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      walletId: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "walletId",
        componentProps: {
          labelText: _t("wallet_id"),
          placeHolderText: _t("wallet_id"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      comType: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "comType",
        componentProps: {
          labelText: _t("com_type"),
          placeHolderText: _t("com_type"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      city: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "city",
        componentProps: {
          labelText: _t("city"),
          placeHolderText: _t("city"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      postcode: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "postcode",
        componentProps: {
          labelText: _t("postcode"),
          placeHolderText: _t("postcode"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      state: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "state",
        componentProps: {
          labelText: _t("state"),
          placeHolderText: _t("state"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      password: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: InputPassword,
        name: "password",
        componentProps: {
          labelText: _t("password"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      confirm_password: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: InputPassword,
        name: name || "confirm_password",
        componentProps: {
          labelText: _t("confirm_password"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      email: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "email",
        componentProps: {
          labelText: _t("email"),
          placeholder: _t("email"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      securityGroupId: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "securityGroupId",
        componentProps: {
          items: componentProps?.options,
          labelText: _t("roles"),
          light: true,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      merchants: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "merchants",
        componentProps: {
          items: componentProps?.options,
          label: _t("merchants"),
          titleText: _t("merchants"),
          light: false,
          labelWithAll: false,
          type: "default",
          twoColumns: true,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      authority: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: CheckboxPanel,
        name: name,
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      paymentType: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "paymentType",
        componentProps: {
          items: componentProps?.options,
          labelText: _t("payment_type"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      configurationType: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "configurationType",
        componentProps: {
          items: componentProps?.options,
          labelText: _t('configuration_type'),
          placeholderText: _t('select_configuration_type'),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      transactionType: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: name ? name :"transactionType",
        componentProps: {
          items: componentProps?.options,
          labelText: _t("transaction_type"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      strategy: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: name ?  name : "strategy" ,
        componentProps: {
          items: componentProps?.options,
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: 'small-input__container-right',
          ...columnParams
        },
        ...rest,
      }),
      redirectType: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "redirectType",
        componentProps: {
          items: componentProps?.options,
          labelText: _t("redirect_type"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      select: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: name ? name : "select",
        componentProps: {
          items: componentProps?.options,
          labelText: componentProps?.labelText || "Select",
          ...componentProps,
        },
        columnParams: {
          lg: 5,
          md: 3,
          ...columnParams,
        },
        ...rest,
      }),
      needToMaskSensitiveData: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "needToMaskSensitiveData",
        componentProps: {
          labelText: _t("mask_sensitive_data"),
          id: "needToMaskSensitiveData",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      errorsInARowCheckEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "errorsInARowCheckEnabled",
        componentProps: {
          labelText: _t("errors_in_row_check_enabled"),
          id: "errorsInARowCheckEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      depositsCheckingEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "depositsCheckingEnabled",
        componentProps: {
          labelText: _t("deposits_checking_enabled"),
          id: "depositsCheckingEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      withdrawalsCheckingEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "withdrawalsCheckingEnabled",
        componentProps: {
          labelText: _t("withdrawals_checking_enabled"),
          id: "withdrawalsCheckingEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      successRateCheckEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "successRateCheckEnabled",
        componentProps: {
          labelText: _t("success_rate_check_enabled"),
          id: "successRateCheckEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      maxCumulativeAmountCheckEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "maxCumulativeAmountCheckEnabled",
        componentProps: {
          labelText: _t("max_cumulative_amount"),
          id: "maxCumulativeAmountCheckEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      maxSingleAmountCheckEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "maxSingleAmountCheckEnabled",
        componentProps: {
          labelText: _t("max_single_amount"),
          id: "maxSingleAmountCheckEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      defaultToggle: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "default",
        componentProps: {
          labelText: _t("default"),
          id: "defaultToggle",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      allCountries: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "allCountries",
        componentProps: {
          labelText: _t("all_countries"),
          id: "allCountries",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      refundable: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "refundable",
        componentProps: {
          labelText: _t("refundable"),
          id: "refundable",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      storedSupported: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "storedSupported",
        componentProps: {
          labelText: _t("stored_supported"),
          id: "storedSupported",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      voucherAuto: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "voucherAuto",
        componentProps: {
          labelText: _t("voucher_auto"),
          id: "voucherAuto",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      usePushDepositUrl: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "usePushDepositUrl",
        componentProps: {
          labelText: _t("use_push_deposit_ipn_url"),
          placeHolderText: _t("use_push_deposit_ipn_url"),
          id: "usePushDepositUrl",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      useSeparateUrlForV1Api: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "useSeparateUrlForV1Api",
        componentProps: {
          labelText: _t("use_separate_url_for_v1_api"),
          placeHolderText: _t("use_separate_url_for_v1_api"),
          id: "useSeparateUrlForV1Api",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      useSeparateUrlV2DepositAndWithdrawal: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "useSeparateUrlV2DepositAndWithdrawal",
        componentProps: {
          labelText: _t("use_separate_url_for_v2_deposit_and_withdrawal"),
          placeHolderText: _t("use_separate_url_for_v2_deposit_and_withdrawal"),
          id: "useSeparateUrlV2DepositAndWithdrawal",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      getAllGateways: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "getAllGateways",
        componentProps: {
          labelText: _t("show_all_gateways"),
          placeHolderText: _t("show_all_gateways"),
          withControlledValue: true,
          id: "getAllGateways",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      allowPartialRefund: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "allowPartialRefund",
        componentProps: {
          labelText: _t("partial_refund"),
          placeHolderText: _t("partial_refund"),
          id: "allowPartialRefund",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      allowPartialCapture: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "allowPartialCapture",
        componentProps: {
          labelText: _t("partial_capture"),
          placeHolderText: _t("partial_capture"),
          id: "allowPartialCapture",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      isCascadingAllowed: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "cascadingEnabled",
        componentProps: {
          labelText: _t("is_cascading_allowed"),
          placeHolderText: _t("is_cascading_allowed"),
          id: "cascadingEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      allowDynamicDescriptor: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "allowDynamicDescriptor",
        componentProps: {
          labelText: _t("allow_dynamic_descriptor"),
          placeHolderText: _t("allow_dynamic_descriptor"),
          id: "allowDynamicDescriptor",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      disableToggle: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "disable",
        componentProps: {
          labelText: _t("disable"),
          placeHolderText: _t("disable"),
          id: "disableToggle",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      avsCheck: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "avsCheck",
        componentProps: {
          labelText: _t("avs_check"),
          placeHolderText: _t("avs_check"),
          id: "avsCheck",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      apiV2: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "API V2",
        componentProps: {
          labelText: _t("api_v2"),
          placeHolderText: _t("api_v2"),
          id: "API V2",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      activeToggle: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: name ? name : "active",
        componentProps: {
          labelText: _t("active"),
          id: "active",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      ip: ({ isEdit, componentProps, columnParams, ...rest }: any = {}) => ({
        component: isEdit ? Input : InputWithFilterTag,
        name: "ip",
        componentProps: {
          labelText: _t("ip"),
          placeHolderText: _t("ip"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      code: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "code",
        componentProps: {
          labelText: _t("code"),
          placeholder: _t("code"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      weightValue: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "weightValue",
        componentProps: {
          labelText: _t("weight_value"),
          placeholder: _t("weight_value"),
          ...componentProps,
        },
        columnParams: {
          lg: 4,
          md: 2,
          ...columnParams,
        },
        ...rest,
      }),
      json: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: JsonEditor,
        name: "properties",
        componentProps: {
          labelText: "JsonEditor(properties)",
          placeholder: _t("value"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      authenticationCode: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "authenticationCode",
        componentProps: {
          labelText: _t("enter_code"),
          placeholder: _t("code"),
          className: "bx--text-input--inverted",
          autoComplete: "off",
          autoFocus: true,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      rememberMe: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Checkbox,
        name: "rememberMe",
        componentProps: {
          labelText: _t("remember_me"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      selectedPaymentMethods: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "selectedPaymentMethods",
        componentProps: {
          items: componentProps?.options,
          label: _t("payment_methods"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      paymentProviders: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "paymentProviders",
        componentProps: {
          items: componentProps?.options,
          titleText: _t("payment_providers"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      selectedCurrencies: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "selectedCurrencies",
        componentProps: {
          items: componentProps?.options,
          label: _t("currency"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      selectCurrencies: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        openModal: true,
        name: "selectedCurrencies",
        componentProps: {
          items: componentProps?.options,
          label: _t("currency"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      selectedCredentials: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        openModal: true,
        name: "selectedCredentials",
        componentProps: {
          items: componentProps?.options,
          label: _t("credentials"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      operationType: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        openModal: true,
        name: "operationType",
        componentProps: {
          items: componentProps?.options,
          labelText: _t('operation_types'),
          type: "default",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      priority: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "priority",
        componentProps: {
          labelText: _t("priority"),
          id: "priority",
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      dateManual: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Calendar,
        openModal: true,
        name: "date",
        componentProps: {
          labelText: _t("date"),
          // dateName: 'holdAmountReturnDate',
          dateFormat: config.dateFormat,
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      validFrom: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Calendar,
        openModal: true,
        name: "validFrom",
        componentProps: {
          labelText: _t("valid_from"),
          // dateName: 'holdAmountReturnDate',
          dateFormat: config.dateFormat,
          placeholder: _t("select_date"),
          ...componentProps,
        },
        columnParams: {
          className: 'small-input__container-left',
          lg: 6,
          md: 3,
          ...columnParams,
        },
        ...rest,
      }),
      monthPicker: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MonthPicker,
        openModal: true,
        name: "validFrom",
        componentProps: {
          labelText: _t("valid_from"),
          // dateName: 'holdAmountReturnDate',
          dateFormat: config.dateFormat,
          placeholder: _t("select_date"),
          ...componentProps,
        },
        columnParams: {
          className: 'small-input__container-left',
          lg: 6,
          md: 3,
          ...columnParams,
        },
        ...rest,
      }),
      amount: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "amount",
        componentProps: {
          labelText: _t("amount"),
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      depositFees: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "depositFee",
        componentProps: {
          labelText: `${_t("deposit_fees")}, %` ,
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      treeViewMultiselect: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: TreeViewMultiselect,
        name: "advancedSettings",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      withdrawalFees: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "withdrawalFee",
        componentProps: {
          labelText: `${_t("withdrawal_fees")}, %` ,
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      fixedFee: ({
        componentProps,
        columnParams,
        name = '',
        ...rest
      }: any = {}) => ({
        component: Input,
        name: name ? name : 'fixedFee',
        componentProps: {
          labelText: '' ,
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      rrForDeposit: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "rollingReserveRate",
        componentProps: {
          labelText: `${_t("rr_for_deposits")}, %` ,
          ...componentProps,
        },
        columnParams: {
          lg: 4,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      settlementsFees: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "settlementFee",
        componentProps: {
          labelText: `${_t("settlement_fees")}, %` ,
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      numberOfDays: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "numberOfDays",
        componentProps: {
          labelText: _t("number_of_days") ,
          ...componentProps,
        },
        columnParams: {
          lg: 4,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      percentage: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "percentage",
        componentProps: {
          labelText: _t("percentage") ,
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      transactionFees: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "transactionFees",
        componentProps: {
          labelText: `${_t("transaction_fees")}, EUR` ,
          ...componentProps,
        },
        columnParams: {
          lg: 8,
          md: 8,
          ...columnParams,
        },
        ...rest,
      }),
      statusReasonCode: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "code",
        componentProps: {
          labelText: _t("status_reason_code"),
          placeHolderText: _t("status_reason_code"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      statusReasonTitle: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "title",
        componentProps: {
          labelText: _t("status_reason_title"),
          placeHolderText: _t("status_reason_title"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      statusReasonDescription: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "description",
        componentProps: {
          labelText: _t("status_reason_description"),
          placeHolderText: _t("status_reason_description"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      statusReasonCodePriority: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "codePriority",
        componentProps: {
          labelText: _t("code_priority"),
          placeHolderText: _t("set_code_priority"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      statusReasonSequenceNumber: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "sequenceNumber",
        componentProps: {
          labelText: _t("sequence_number"),
          placeHolderText: _t("set_sequence_number"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      errorDetailKey: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "errorDetailKey",
        componentProps: {
          labelText: _t("error_detail_key"),
          placeHolderText: _t("set_error_detail_key"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      slackChannelName: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "channelName",
        componentProps: {
          labelText: _t("slack_channel_name"),
          placeholder: _t("slack_channel_name"),
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          ...columnParams,
        },
        ...rest,
      }),
      merchantSlackChannelSelect: ({
        componentProps,
        columnParams,
        name = '',
        ...rest
      }: any = {}) => ({
        component: SelectWithSearch,
        name: name ? name : "channelId",
        componentProps: {
          items: componentProps?.options,
          labelText: _t("slack_channel"),
          placeholderText: _t("select_slack_channel"),
          ...componentProps,
        },
        columnParams: {
          md: 16,
          lg: 16,
          ...columnParams,
        },
        ...rest,
      }),
    },
    general: {
      submitBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "",
        componentProps: {
          kind: "secondary",
          type: "submit",
          label: _t("apply_filters"),
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      searchBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "",
        componentProps: {
          type: "submit",
          label: "",
          hasIconOnly: true,
          renderIcon: Search16,
          style: {
            color: "#000",
            borderColor: "#fff",
            backgroundColor: "#fff",
          },
          className: "bx--search-button",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      removeBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "removeBtn",
        componentProps: {
          type: "button",
          kind: "tertiary",
          hasIconOnly: true,
          renderIcon: Delete16,
          iconDescription: _t('remove'),
          style: {
            color: "#000",
            borderColor: "#fff",
            backgroundColor: "#fff",
          },
          ...componentProps,
        },
        columnParams: {
          className: "fields-array__remove-button align-input-without-label",
          lg: 1,
          md: 1,
          ...columnParams,
        },
        ...rest,
      }),
      dragDropBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "dragDropBtn",
        componentProps: {
          type: "button",
          kind: "tertiary",
          hasIconOnly: true,
          renderIcon: Draggable16,
          style: {
            color: "#000",
            borderColor: "#fff",
            backgroundColor: "#fff",
          },
          ...componentProps,
        },
        columnParams: {
          className: "align-input-without-label",
          lg: 1,
          md: 1,
          ...columnParams,
        },
        ...rest,
      }),
      reorderArrows: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: ReorderArrows,
        name: "reorderArrows",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 2,
          md: 1,
          ...columnParams,
        },
        ...rest,
      }),
      saveBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "",
        componentProps: {
          kind: "primary",
          type: "submit",
          label: _t("save_changes"),
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      divider: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Divider,
        name: "",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          sm: 16,
          ...columnParams,
        },
        ...rest,
      }),
      infoBlock: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: InfoBlock,
        name: "",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          sm: 16,
          ...columnParams,
        },
        ...rest,
      }),
      historyLink: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: HistoryLink,
        name: "",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 16,
          md: 16,
          sm: 16,
          ...columnParams,
        },
        ...rest,
      }),
    },
  };

  return fields;
};

import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useMemo } from "react";

export const useModuleData = (id: number): any => {
  // const { data: methods } = useGetMerchantAvailablePaymentMethods(id);
  /*  const methodsOptions = arrayOptionsMap(methods, {
    labelKey: "name",
    valueKey: "id",
  });*/

  return {};
};

export const useModifyCredentialTemplate = (
  data: any = [],
  id: string = "-1"
) =>
  useMemo(() => {
    const template =
      id == "-1" ? data[0] : data.filter((item: any) => item.id == id)[0];
    const restrictedFields =
      id == "-1"
        ? ["updatedDate", "createdDate", "id"]
        : ["updatedDate", "createdDate"];
    let mainTemplateData: any = {};
    for (let key in template) {
      if (!restrictedFields.includes(key)) {
        mainTemplateData[key] = template[key];
      }
    }
    return mainTemplateData;
  }, [data, id]);

import Card from "components/atoms/Card";
import Loader from "components/atoms/Loader";
import SwaggerUI from "swagger-ui-react";
import 'swagger-ui-react/swagger-ui.css';
import { getToken } from "system/helpers/helperFunctions";
import { useTrans } from "system/translations/hooks";
import { useGetSwaggerDocumentationData } from "./apiHooks";

const SwaggerDocumentation = () => {
  const { _t } = useTrans()
  const { data, isLoading} = useGetSwaggerDocumentationData();

  const requestInterceptor = (request: any) => {
    const token = getToken();
    request.headers['Authorization'] = token;
    return request;
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Card title={_t("api_documentation")}>
        <SwaggerUI spec={data} requestInterceptor={requestInterceptor}/>
    </Card>
);
};

export default SwaggerDocumentation;

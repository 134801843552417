import React from "react"
import Select from "components/atoms/Select"
import MultiSelect from "components/atoms/MultiSelect"
import Input from "components/atoms/Input"
import SelectWithSearch from "components/atoms/SelectWithSearch"

const UniversalFormItem = ({
  component,
  value,
  onChange,
  items,
  titleText,
  ...restProps
}: {
  component: 'input' | 'select' | 'multiSelect' | 'twoInputs' | 'selectWithSearch',
  value: any,
  onChange: any,
  items?: any,
  titleText?: any,
}) => {
  const onTwoInputsChange = (data: any, index: number) => {
    const valueChange = value ? [...value] : []
    valueChange[index] = data
    onChange(valueChange)
  }

  const props: any = {...restProps}
  const twoInputsInvalidText = Array.isArray(props?.invalidText)
    ? props?.invalidText
    : typeof props?.invalidText === 'string'
      ? [props?.invalidText, props?.invalidText]
      : [undefined, undefined]
  
  switch(component) {
    case 'select': 
      return <Select value={value} onChange={onChange} items={items} {...restProps}/>
    case 'selectWithSearch': 
      return <SelectWithSearch value={value} onChange={onChange} items={items} {...restProps}/>
    case 'multiSelect': 
      return <MultiSelect value={value} onChange={onChange} items={items} titleText={titleText} {...restProps}/>
    case 'twoInputs':
      return <div className="two-inputs__container">
        <div className="two-inputs__item">
          <Input 
            value={value && value[0]} 
            onChange={(data) => onTwoInputsChange(data, 0)} 
            {...restProps} 
            invalid={!!twoInputsInvalidText[0]}  
            id={'value1'} 
            invalidText={twoInputsInvalidText[0]}
            />
        </div>
        <div className="two-inputs__item">
          <Input
            value={value && value[1]}
            onChange={(data) => onTwoInputsChange(data, 1)} 
            {...restProps}
            invalid={!!twoInputsInvalidText[1]}
            id={'value2'}
            invalidText={twoInputsInvalidText[1]}
          />
          </div>
        </div>
      default:
        return <Input value={value} onChange={onChange} {...restProps}/>
  }
}

export default UniversalFormItem
import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const createEditValidation = (_t: TransType, params: any) => {
  return Yup.object().shape({
    paymentType: Yup.string().required(_t("required")),
    paymentMethod: Yup.string().required(_t("required")),
    paymentGateway: Yup.number().required(_t("required")),
    credential: Yup.number().required(_t("required")),
  });
};

import React from "react";
import Create from "modules/merchantWhiteList/components/Create";

const MerchantWhiteListCreatePage = () => {
  return (
    <div>
      <Create />
    </div>
  );
};

export default MerchantWhiteListCreatePage;

import React from "react";
import Checkbox from "components/atoms/Checkbox";
import { CheckboxPanelProps } from "components/molecules/CheckboxPanel/types";

const CheckboxPanel: React.FC<CheckboxPanelProps> = ({
  value,
  onChange,
  title,
  description,
  name,
}) => {
  return (
    <div className={"checkbox-panel"} key={name}>
      <div className={"checkbox-panel__info"}>
        {title && <div className={"checkbox-panel__info__title"}>{title}</div>}
        {description && (
          <div className={"checkbox-panel__info__description"}>
            {description}
          </div>
        )}
      </div>
      <div className={"checkbox-panel__checkbox"}>
        <Checkbox id={name} value={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default CheckboxPanel;

import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UseMonitoringMerchantModuleDataRes } from "modules/monitoringMerchant/types";
import { useGetPaymentGatewaysCodes } from "modules/payment/apiHooks";
import { useGetPaymentGateways } from "modules/merchantsBalanceV2/apiHooks";

export const useMonitoringMerchantModuleData =
  (): UseMonitoringMerchantModuleDataRes => {
    const { data: merchantsList = [] } = useGetMerchantsNameList();
    const { data: paymentGateways = [] } = useGetPaymentGatewaysCodes();
    const merchantsOptions = arrayOptionsMap(merchantsList, {
      labelKey: "name",
      valueKey: "id",
    });
    const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
      labelKey: "code",
      valueKey: "code",
    });

    return { merchantsOptions, paymentGatewaysOptions };
  };

  export const useMonitoringMerchantSearchModuleData = (): UseMonitoringMerchantModuleDataRes => {
    const { data: merchantsList = [] } = useGetMerchantsNameList();
    const { data: paymentGateways = [] } = useGetPaymentGateways();
  
    const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
      labelKey: "label",
      valueKey: "value",
    });
  
    const merchantsOptions = arrayOptionsMap(merchantsList, {
      labelKey: "name",
      valueKey: "id",
    });
  
    return { merchantsOptions, paymentGatewaysOptions };
  };

import React from "react";
import Create from "modules/MerchantsFees/components/Create";

const MerchantsFeesCreatePage = () => {
  return (
    <div>
      <Create />
    </div>
  );
};

export default MerchantsFeesCreatePage;
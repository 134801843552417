import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useGetMerchantSlackChannels } from "./apiHooks";
import { UseModuleDataRes } from "./types";

export const useIsSubMerchants = () => {
  const location = useLocation();
  return useMemo((): boolean => {
    return location.pathname.includes("sub");
  }, [location.pathname]);
};

export const usePrepareDataForMerchantIpn = () => {
   const getIpnsUrl = (data: any) => {
    if(!data) {
      return {}
    }
    const normalizeData = cloneDeep(data)
  
    if ((!data.ipns?.depositIpnUrl && !data.ipns?.withdrawalIpnUrl) || (data.ipns?.depositIpnUrl === data.ipns?.withdrawalIpnUrl)) {
      normalizeData.ipns.v2IpnUrl = data.ipns?.depositIpnUrl
    } else {
      normalizeData.useSeparateUrlV2DepositAndWithdrawal = true
      normalizeData.ipns.v2IpnUrl = ""
    }
  
    if (data?.ipns.pushDepositIpnUrl) {
      normalizeData.usePushDepositUrl = true
    } else {
      normalizeData.ipns.pushDepositIpnUrl = ""
    }
  
    if (data.ipns?.v1IpnUrl) {
      normalizeData.useSeparateUrlForV1Api = true
    } else {
      normalizeData.ipns.v1IpnUrl = ""
    }
  
    return normalizeData
  }

  const normalizeDataBeforeSave = (data: any) => {
    const normalizeData = cloneDeep(data)

    if (!normalizeData.usePushDepositUrl) {
      delete normalizeData.ipns.pushDepositIpnUrl
    }

    if (!normalizeData.useSeparateUrlV2DepositAndWithdrawal) {
      normalizeData.ipns.depositIpnUrl = normalizeData.ipns.v2IpnUrl
      normalizeData.ipns.withdrawalIpnUrl = normalizeData.ipns.v2IpnUrl
    }

    if (!normalizeData.useSeparateUrlForV1Api) {
      delete normalizeData.ipns.v1IpnUrl
    }

    delete normalizeData.usePushDepositUrl
    delete normalizeData.useSeparateUrlForV1Api
    delete normalizeData.useSeparateUrlV2DepositAndWithdrawal
    delete normalizeData.ipns.v2IpnUrl

    return normalizeData

  }

  return { getIpnsUrl, normalizeDataBeforeSave }
}

export const useModuleData = (merchantId: number): UseModuleDataRes => {
  const { data: merchantSlackChannelsList = [] } = useGetMerchantSlackChannels(merchantId);

  const merchantSlackChannelsOptions = arrayOptionsMap(merchantSlackChannelsList, {
    labelKey: "label",
    valueKey: "value",
  });

  return { merchantSlackChannelsOptions };
};

import { CloseOutline16 } from "@carbon/icons-react";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { TransType } from "system/translations/types";
import { useUpdatePaymentInstrument } from "./apiHooks";
import { PaymentInstrumentDataType, PaymentInstrumentItemState, PaymentInstrumentItemType } from "./types";

export const useGetOnActionStatusUpdate = (data: PaymentInstrumentDataType, _t: TransType) => {
  const { mutate: onUpdatePaymentInstrument, isLoading: onUpdatePaymentInstrumentLoading } = useUpdatePaymentInstrument();
  const { onConfirm } = useConfirmModal();
  const onActionUpdate = ({
    id,
    updateToggleValueState,
    deletedStatus
  }: {
    id: any,
    updateToggleValueState?: (value: boolean) => any,
    deletedStatus?: "DELETED"
  }) => {
    let updatedPaymentInstrument: any = data.find((paymentInstrument: PaymentInstrumentItemType) => paymentInstrument.id === id);
    const dangerModalAttributes = {
      onOkKind: "danger",
      renderIcon: CloseOutline16,
      isShowScale: false,
    }

    if (!onUpdatePaymentInstrumentLoading) {
      const handleUpdatePaymentInstrument = (status: PaymentInstrumentItemState) => {
        onUpdatePaymentInstrument({
          id: id,
          status: status
        });
        updateToggleValueState && updateToggleValueState(true);
      };

      if (!!deletedStatus) {
        onConfirm({
          onOk: () => handleUpdatePaymentInstrument(deletedStatus),
          bodyText: _t("do_you_want_to_remove_mandate") + "<br/>" +  _t("action_cannot_be_updone"),
          onOkText: _t("remove"),
          ...dangerModalAttributes
        });
      } else if (updatedPaymentInstrument.active) {
        onConfirm({
          onOk: () => handleUpdatePaymentInstrument("SUSPENDED"),
          bodyText: _t("do_you_want_to_deactivate_mandate"),
          onOkText: _t("deactivate"),
          ...dangerModalAttributes
        });
      } else {
        handleUpdatePaymentInstrument("ACTIVE");
      }

    }
  };


  return { onActionUpdate }
}
import React from "react";
import { Button, ButtonProps } from "carbon-components-react";
import Loader from "components/atoms/Loader";

const Btn: React.FC<ButtonProps & { label: string; isLoading: boolean }> = ({
  label,
  isLoading = false,
  disabled,
  ...rest
}) => {  
  return (
    <Button disabled={disabled || isLoading} {...rest}>
      {isLoading ? <Loader /> : label}
    </Button>
  );
};

export default Btn;

import React, { useMemo } from "react";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import { useFilters } from "system/helpers/hooks";
import Loader from "components/atoms/Loader";
import { useGetMerchantsFeesHistory, useGetMerchantsFeesHistoryByField } from "../apiHooks";
import { useTableHistoryColumns } from "../constantHooks";

const HistoryDetails = ({data, isLoading}: any) => {
  const columns = useTableHistoryColumns();
  return (
      <Table
        columns={columns}
        isLoading={isLoading}
        data={data}
      />
  );
};

export default HistoryDetails;

import { useMutation, useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MERCHANTS_BALANCE,
  API_GET_MERCHANT_BALANCE_CURRENCIES,
  API_GET_MERCHANTS_BALANCE_EXPORT,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import {
  MerchantsBalanceCurrencyDataType,
  MerchantsBalanceDataType,
} from "modules/merchantsBalance/types";
import { useToast } from "modules/toast/hooks";
import { useTrans } from "system/translations/hooks";

const getMerchantsBalanceKey = "getMerchantsBalanceKey";
const getMerchantBalanceCurrencies = "getMerchantBalanceCurrencies";
export const useGetMerchantsBalance = (filter = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantsBalanceKey, filter],
    (): Promise<MerchantsBalanceDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS_BALANCE, filter),
        method: "get",
      })
  );
};
export const useGetMerchantBalanceCurrencies = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantBalanceCurrencies],
    (): Promise<MerchantsBalanceCurrencyDataType> =>
      apiRequest({
        url: API_GET_MERCHANT_BALANCE_CURRENCIES,
        method: "get",
      })
  );
};
export const useGetMerchantsBalanceExport = () => {
  const [apiRequest] = useApiRequest();
  const toastHandler = useToast();
  const { _t } = useTrans();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS_BALANCE_EXPORT, data),
        method: "get",
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          caption: _t("export_csv_notification"),
          kind: "success",
        });
      },
    }
  );
};

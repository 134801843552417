import React from "react";
import { MobileMenuProps } from "components/organisms/MobileMenu/types";
import { useConstants } from "system/helpers/hooks";
import { useHistory } from "react-router-dom";
import MenuBottom from "./../../../modules/mobileMenu/menuBottom";
import MenuFullScreen from "./../../../modules/mobileMenu/menuFullScreen";
import { useModal } from "modules/modal/hooks";
import { useTrans } from "system/translations/hooks";

const MobileMenu: React.FC<MobileMenuProps> = () => {  
  const {sidebarMenu} = useConstants()
  const mobileMenuItems = sidebarMenu.filter(item => item?.presentInMobileMenu)
  const history = useHistory()
  const {showModal, hideModal} = useModal()
  const { _t } = useTrans()

  const linkHandler = (link: string) => {
    history.push(link);
    hideModal("mobile-menu");
  }

  const openMenuModal = () => {
    showModal({
      modalHeading: _t("menu"),
      isShowScale: false,
      componentProps: {
        linkHandler: linkHandler,
        menuItems: sidebarMenu,
      },
      component: MenuFullScreen,
    }, "mobile-menu");
  }

  return (
   <>
    <MenuBottom
      linkHandler = {linkHandler}
      bottomMenuItems = {mobileMenuItems}
      openFullScreenMenu = {() => openMenuModal()}
    />
  </>
  );
};

export default MobileMenu;

import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useMemo } from "react";
import {
  useGetSettingPaymentMethods,
  useGetSettingCurrencies,
  useGetSettingCountries,
  useGetSettingOperationType,
} from "modules/settings/apiHooks";
import { UseModuleDataRes } from "modules/settings/types";

export const useModuleData = (): UseModuleDataRes => {
  const { data: paymentMethods = [] } = useGetSettingPaymentMethods();
  const { data: currencies = [] } = useGetSettingCurrencies();
  const { data: countries = [] } = useGetSettingCountries();
  const { data: operationType = [] } = useGetSettingOperationType();
  const paymentMethodsOptions = arrayOptionsMap(paymentMethods, {
    labelKey: "code",
    valueKey: "id",
  });
  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "id",
  });
  const countriesOptions = arrayOptionsMap(countries, {
    labelKey: "description",
    valueKey: "id",
  });
  const operationOptions = arrayOptionsMap(operationType, {
    labelKey: "code",
    valueKey: "code",
  });
  const credentialsOptions: any = [];

  return {
    paymentMethodsOptions,
    currenciesOptions,
    credentialsOptions,
    countriesOptions,
    operationOptions
  };
};

export const useModifySettingsTemplate = (data: any = [], id: string = "-1") =>
  useMemo(() => {
    const template = id == "-1" ? data : data;
    const restrictedFields =
      id == "-1"
        ? [
            "updatedDate",
            "createdDate",
            "id",
            "paymentMethods",
            "credentials",
            "currencies",
          ]
        : [
            "updatedDate",
            "createdDate",
            "paymentMethods",
            "credentials",
            "currencies",
          ];
    let mainTemplateData: any = {};
    for (let key in template) {
      if (!restrictedFields.includes(key)) {
        mainTemplateData[key] = template[key];
      }
    }
    return mainTemplateData;
  }, [data, id]);

import Checkbox from "components/atoms/Checkbox";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { useTrans } from "system/translations/hooks";
import { ExportReportTypeChooserProps } from "./types";
import {  v4 as uuidv4 } from "uuid"

const ExportReportTypeChooser: React.FC<ExportReportTypeChooserProps> = ({ selectedData, onUpdateCheckBox, isBothPermission }) => {
  const { _t } = useTrans();

  const handleCheckboxChange = (dataType: 'deviceData' | 'feesData') => {
    onUpdateCheckBox(selectedData === dataType ? null : dataType);
  };

  return (
    <>
      <RoleWrapper accessTo={"ROLE_VIEW_REPORT_WITH_DEVICE_DATA"}>
        <Checkbox
          id={uuidv4()}
          key={uuidv4()}
          value={selectedData === 'deviceData'}
          labelText={_t('include_device_data_to_export_report')}
          onChange={() => handleCheckboxChange('deviceData')}
          className={'checkbox--with-margin-bottom'}
        />
      </RoleWrapper>
      <RoleWrapper accessTo={"ROLE_VIEW_TRANSACTION_FEES"}>
        <Checkbox
          id={uuidv4()}
          key={uuidv4()}
          value={selectedData === 'feesData'}
          labelText={_t('include_fees_data_to_export_report')}
          onChange={() => handleCheckboxChange('feesData')}
          className={'checkbox--with-margin-bottom'}
        />
      </RoleWrapper>
      {isBothPermission && (
        <div className={"confirm-modal-body"} >{_t('warning_user_can_select_only_one_data_type_for_export_report')}</div>
      )}
    </>
  );
};

export default ExportReportTypeChooser;

import { Add16, Information16 } from "@carbon/icons-react"
import PaymentOrchestrationTreeViewItem from "components/atoms/PaymentOrchestrationTreeViewItem"
import Toggle from "components/atoms/Toggle";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTrans } from "system/translations/hooks";
import AddConditionsButton from "modules/routingRules/components/AddConditionsButton";
import OperatorChangerButton from "components/atoms/OperatorChangerButton";
import {
  useGetParametrsRoutingRulesTreeItems,
  useGetUpdateRoutingRulesTreeItems,
  useGetWarningSaveDataWithoutDefaultStartegy,
  useGetApplyToAllCheckBoxHandlers,
  useOmitIdComparison
} from "../hooks";
import { AuthType, PaymentOrchestrationTreeViewItemRecursiveType } from "../types";
import { useCreateEditConfigAttributes } from "../constantHooks";
import PaymentOrchestrationContainerWithIndentation from "components/atoms/PaymentOrchestrationContainerWithIndentation";
import classNames from "classnames";
import { Checkbox, Tooltip } from "carbon-components-react";
import { isEqual, cloneDeep } from "lodash"
import { initialValue } from "../constants";

const PaymentOrchestrationTreeViewItemRecursive: React.FC<PaymentOrchestrationTreeViewItemRecursiveType> =
  ({
    data,
    onChange,
    operator,
    operatorHandler,
    isConditionGroup = false,
    authType,
    getOrchestartionFormConfig
  }) => {
    const { _t } = useTrans()
    const ref = useRef<any>([])
    const refDashedLine = useRef<any>(null)
    const { getStrategyParametrs, getConditionParametrs } = useGetParametrsRoutingRulesTreeItems()
    const {
      addAttributHandler,
      addAttributConditonsGroupHandler,
      addStrategyHandler,
      addConditionsHandler,
      addConditionsGroupHandler,
      operatorChangerHandler,
      onRemoveStrategy,
      onRemoveAttribute,
      onUpdateStrategy,
      onUpdateConditions,
      clearStrategyValues,
      clearConditionsValues,
      onTitleValueChange
    } = useGetUpdateRoutingRulesTreeItems(onChange, data)

    const addConditionMenuItems = [
      {
        title: "Add Condition",
        onClick: addConditionsHandler,
      },
      {
        title: "Add Group",
        onClick: addConditionsGroupHandler,
      },
    ];
    const addAttributesConditionMenuItems = [
      {
        title: "Add Condition",
        onClick: addAttributHandler,
      },
      {
        title: "Add Group",
        onClick: addAttributConditonsGroupHandler,
      },
    ];

    useEffect(() => {
      if (refDashedLine.current) {
        const dashedLine: any = refDashedLine.current
        dashedLine.style.height = (dashedLine.parentElement.getBoundingClientRect().top + window.scrollY) - (dashedLine.parentElement.parentElement.parentElement.getBoundingClientRect().top + window.scrollY) + 'px'
      }

      if (ref.current) {
        const line: any = ref.current
        line.forEach((item: any) => {
          if (item) {
            item.style.height = (item.parentElement.getBoundingClientRect().top + window.scrollY) - (item.parentElement.parentElement.parentElement.getBoundingClientRect().top + window.scrollY) + 'px';
          }
        })
      }
    }, [data])

    return (
      <>
        {data.map((item: any, idx: number) => {
          if (item.strategy && !item.attributes) {
            return (
              <PaymentOrchestrationContainerWithIndentation>
                <div className="attribute__container strategy">
                  <div className="line line--green" ref={el => ref.current[idx] = el} ></div>
                  <PaymentOrchestrationTreeViewItemRecursive
                    data={item.conditions}
                    onChange={onChange}
                    operator={item.operator}
                    operatorHandler={(operator) => operatorChangerHandler(item, operator)}
                    isConditionGroup={!item.conditions.conditions}
                    authType={authType}
                    getOrchestartionFormConfig={getOrchestartionFormConfig}
                  />
                  {item.conditions.length < 3 && <AddConditionsButton items={addConditionMenuItems} clickParams={item} />}
                </div>

                <PaymentOrchestrationContainerWithIndentation>
                  <div className="strategy">
                    <div className="line"></div>
                    <PaymentOrchestrationTreeViewItem
                      data={item.strategy}
                      title={'Strategy'}
                      parametrs={getStrategyParametrs(item.strategy)}
                      type={'strategy'}
                      onRemove={() => onRemoveStrategy(item)}
                      onUpdate={(data: any) => onUpdateStrategy(data, item)}
                      onClearValues={() => clearStrategyValues(item)}
                      getOrchestartionFormConfig={getOrchestartionFormConfig}
                    />
                  </div>
                  <div className="button-container">
                    <div className="line line--green line--dashed" ></div>
                    <AddConditionsButton
                      items={addAttributesConditionMenuItems}
                      buttonText={_t("add_attribute")}
                      clickParams={{ item, isFirstLevel: false }} />
                  </div>
                </PaymentOrchestrationContainerWithIndentation>
                {data.length >= 1 && idx === data.length - 1 &&

                  <div className="button-container">
                    <div className="line line--green line--dashed" ref={refDashedLine}></div>
                    <AddConditionsButton items={addAttributesConditionMenuItems} buttonText={_t("add_attribute")} clickParams={{ item, isFirstLevel: true }} />
                  </div>
                }
              </PaymentOrchestrationContainerWithIndentation>
            )
          } else if (item.strategy && item.attributes) {
            return (
              <>
                <PaymentOrchestrationContainerWithIndentation >
                  <div className="attribute__container strategy">
                    <div className="line line--green" ref={el => ref.current[idx] = el}></div>
                    <PaymentOrchestrationTreeViewItemRecursive
                      data={item.conditions}
                      onChange={onChange}
                      operator={item.operator}
                      operatorHandler={(operator) => operatorChangerHandler(item, operator)}
                      isConditionGroup={!item.conditions.conditions}
                      authType={authType}
                      getOrchestartionFormConfig={getOrchestartionFormConfig}
                    />
                    {item.conditions.length < 3 && <AddConditionsButton items={addConditionMenuItems} clickParams={item} />}
                  </div>
                  <PaymentOrchestrationContainerWithIndentation>
                    <div className="strategy">
                      <div className="line"></div>
                      <PaymentOrchestrationTreeViewItem
                        data={item.strategy}
                        title={'Strategy'}
                        parametrs={getStrategyParametrs(item.strategy)}
                        type={'strategy'}
                        onRemove={() => onRemoveStrategy(item)}
                        onUpdate={(data: any) => onUpdateStrategy(data, item)}
                        onClearValues={() => clearStrategyValues(item)}
                        getOrchestartionFormConfig={getOrchestartionFormConfig}
                      />
                    </div>
                  </PaymentOrchestrationContainerWithIndentation>

                  <PaymentOrchestrationTreeViewItemRecursive
                    data={item.attributes}
                    onChange={onChange}
                    authType={authType}
                    getOrchestartionFormConfig={getOrchestartionFormConfig}
                  />

                </PaymentOrchestrationContainerWithIndentation>
                {data.length >= 1 && idx === data.length - 1 &&
                  <PaymentOrchestrationContainerWithIndentation >
                    <div className="button-container">
                      <div className="line line--green line--dashed" ref={refDashedLine} ></div>
                      <AddConditionsButton items={addAttributesConditionMenuItems} buttonText={_t("add_attribute")} clickParams={{ item, isFirstLevel: true }} />
                    </div>
                  </PaymentOrchestrationContainerWithIndentation>
                }
              </>
            )
          } else if (item.attributes) {
            return (
              <PaymentOrchestrationContainerWithIndentation>
                <div className="attribute__container strategy">
                  <div className="line line--green" ref={el => ref.current[idx] = el}></div>
                  <PaymentOrchestrationTreeViewItemRecursive
                    data={item.conditions}
                    onChange={onChange}
                    operator={item.operator}
                    operatorHandler={(operator) => operatorChangerHandler(item, operator)}
                    isConditionGroup={!item.conditions.conditions}
                    authType={authType}
                    getOrchestartionFormConfig={getOrchestartionFormConfig}
                  />
                  {item.conditions.length < 3 && <AddConditionsButton items={addConditionMenuItems} clickParams={item} />}
                </div>
                <PaymentOrchestrationContainerWithIndentation>
                  {item && (
                    <div className="button-container">
                      <div className="line line--dashed"></div>
                      <button type="button" onClick={() => addStrategyHandler(item)} className="tree-view-rule--add-button">
                        <Add16 /> {_t("add_strategy")}
                      </button>
                    </div>
                  )}
                </PaymentOrchestrationContainerWithIndentation>

                <PaymentOrchestrationTreeViewItemRecursive
                  data={item.attributes}
                  onChange={onChange}
                  authType={authType}
                  getOrchestartionFormConfig={getOrchestartionFormConfig}
                />

                {data.length >= 1 && idx === data.length - 1 &&
                  <div className="button-container">
                    <div className="line line--green line--dashed" ref={refDashedLine}></div>
                    <AddConditionsButton
                      items={addAttributesConditionMenuItems}
                      buttonText={_t("add_attribute")}
                      clickParams={{ item, isFirstLevel: true }} />
                  </div>
                }
              </PaymentOrchestrationContainerWithIndentation>
            )
          } else if (item.conditions && !isConditionGroup) {
            return (
              <>
                <PaymentOrchestrationContainerWithIndentation>
                  <div className="attribute__container strategy">
                    <div className="line line--green" ref={el => ref.current[idx] = el} ></div>
                    <PaymentOrchestrationTreeViewItemRecursive
                      data={item.conditions}
                      onChange={onChange}
                      operator={item.operator}
                      operatorHandler={(operator) => operatorChangerHandler(item, operator)}
                      isConditionGroup={!item.conditions.conditions}
                      authType={authType}
                      getOrchestartionFormConfig={getOrchestartionFormConfig}
                    />
                    {item.conditions.length < 3 && <AddConditionsButton items={addConditionMenuItems} clickParams={item} />}
                  </div>
                </PaymentOrchestrationContainerWithIndentation>
                <PaymentOrchestrationContainerWithIndentation>
                  <PaymentOrchestrationContainerWithIndentation>
                    {item && (
                      <>
                        <div className="button-container">
                          <div className="line line--dashed"></div>
                          <button type="button" onClick={() => addStrategyHandler(item)} className="tree-view-rule--add-button"><Add16 /> {_t("add_strategy")}</button>
                        </div>
                        <div className="button-container">
                          <div className="line line--green line--short line--dashed"></div>
                          <AddConditionsButton items={addAttributesConditionMenuItems} buttonText={_t("add_attribute")} clickParams={{ item, isFirstLevel: false }} />
                        </div>
                      </>
                    )}
                  </PaymentOrchestrationContainerWithIndentation>
                  {data.length >= 1 && idx === data.length - 1 &&
                    <div className="button-container">
                      <div className="line line--green line--dashed" ref={refDashedLine}></div>
                      <AddConditionsButton items={addAttributesConditionMenuItems} buttonText={_t("add_attribute")} clickParams={{ item, isFirstLevel: true }} />
                    </div>
                  }
                </PaymentOrchestrationContainerWithIndentation>
              </>
            )
          } else if (item) {
            return (
              <div className="attribute__container" key={Math.random().toString(36).substr(2, 9)}>
                {operator && idx === 1 &&
                  <OperatorChangerButton
                    value={operator}
                    labelA={"And"}
                    labelB="Or"
                    valueA="and"
                    valueB="or"
                    onChange={operatorHandler}
                  />
                }
                {operator && idx === 2 && <OperatorChangerButton value={operator} />}
                <PaymentOrchestrationTreeViewItem
                  title={item?.name || ''}
                  onTitleChange={(newTitleValue: string) => onTitleValueChange(item, newTitleValue)}
                  data={item}
                  type={item.conditions && item.operator ? 'conditionGroup' : 'condition'}
                  onRemove={() => onRemoveAttribute(idx, authType)}
                  onUpdate={(data: any) => onUpdateConditions(data, idx)}
                  parametrs={getConditionParametrs(item)}
                  onClearValues={() => clearConditionsValues(item)}
                  getOrchestartionFormConfig={getOrchestartionFormConfig}
                  key={Math.random().toString(36).substr(2, 9)}
                />
              </div>
            )
          }
        })}
      </>
    );
  }


const PaymentOrchestrationTreeViewRule: React.FC<{
  value: any;
  onChange: any;
  selectedMerchantId?: number
}> = ({ value, onChange, selectedMerchantId }) => {
  const { _t } = useTrans()
  const [ authType, setAuthType] = useState<AuthType>('token')
  const { isEqualWithoutIds } = useOmitIdComparison(value)
  const isInitial = useMemo(() => isEqual(value, initialValue), [value, initialValue]);
  const [isApplyToAll, setIsApplyToAll] = useState<boolean>(false);
  
  useEffect(() => {
    setIsApplyToAll(isInitial ? false : isEqualWithoutIds);
  }, [isInitial, isEqualWithoutIds]);

  const getOrchestartionFormConfig = useCreateEditConfigAttributes(selectedMerchantId)
  const {
    addAttributHandler,
    addAttributConditonsGroupHandler,
    onUpdateStrategy,
    clearStrategyValues,
  } = useGetUpdateRoutingRulesTreeItems(onChange, value)

  const { getStrategyParametrs } = useGetParametrsRoutingRulesTreeItems()
  const { openWarningWhenMerchantNotSelected }  = useGetWarningSaveDataWithoutDefaultStartegy()
  const { applyToAllClickHandler, getWarningModalOnChangeDataWhenApplyToAllChecked } = useGetApplyToAllCheckBoxHandlers()

  const addAttributesConditionMenuItems = [
    {
      title: _t('add_condition'),
      onClick: addAttributHandler,
    },
    {
      title: _t('add_group'),
      onClick: addAttributConditonsGroupHandler,
    },
  ];

  useEffect(() => {
    if (isApplyToAll) {
      const authTypeKeyToUpdate: AuthType = authType === 'token' ? 'newInstrument' : 'token'
      onChange({...value, [authTypeKeyToUpdate] : cloneDeep(value[authType])})
    }
  }, [isApplyToAll])


  useEffect(() => {
    if (isApplyToAll && !isEqualWithoutIds) {
      getWarningModalOnChangeDataWhenApplyToAllChecked()
      setIsApplyToAll(false)
    }
  }, [value, isEqualWithoutIds])

  return (
    <div className="tree-view-rule"  onClick={() => openWarningWhenMerchantNotSelected(selectedMerchantId)}>
      <div className="tree-view-rule__title">{_t('rule_set_parametrs')}</div>
      <div className="tree-view-rule__information">
        <Information16 />
        {_t('red_line_represents_green_line_represents')}
      </div>
      <div className={classNames("tree-view-rule__blocks-container", !selectedMerchantId && "tree-view-rule__blocks-container--disabled")}>
        <PaymentOrchestrationTreeViewItem
          isEdit={false}
          isAuthType={true}
          type={'authType'}
          isDeleted={false}
          getOrchestartionFormConfig={getOrchestartionFormConfig}
          parametrs={
            <div className="tree-view-item__bottom--auth-type">
              <Toggle
                id="token"
                size={"sm"}
                value={authType === 'token' || isApplyToAll}
                withControlledValue={true}
                onChange={(e) => e ? setAuthType('token') : setAuthType('newInstrument')}
                labelText={'Token'}
              />
              <Toggle
                id="newInstrument"
                size={"sm"}
                withControlledValue={true}
                value={authType === 'newInstrument' || isApplyToAll}
                onChange={(e) => e ? setAuthType('newInstrument') : setAuthType('token')}
                labelText={'New Instrument'} />
                <div className="tree-view-item-authType__checkbox-container">
                  <Checkbox
                    id={'CheckboxApplyAll'}
                    checked={isApplyToAll}
                    indeterminate={false}
                    labelText={_t('apply_to_all')}
                    onChange={(value: boolean) => applyToAllClickHandler(value, isApplyToAll, setIsApplyToAll) }
                  />
                  <Tooltip autoOrientation={true} direction="top" showIcon={true}>
                    {_t('apply_to_all_orchestrator_tooltip_text_1')}
                    <br/>
                    {_t('apply_to_all_orchestrator_tooltip_text_2')}
                  </Tooltip>
                </div>
            </div>
          }
        />
        <PaymentOrchestrationContainerWithIndentation>
          <div className="strategy">
            <div className="line"></div>
            <PaymentOrchestrationTreeViewItem
              data={value[authType]?.strategy || {}}
              title={'Strategy'}
              parametrs={getStrategyParametrs(value[authType]?.strategy || {}) || 'no set'}
              type={'strategy'}
              onUpdate={(data: any) => onUpdateStrategy(data, value[authType])}
              onClearValues={() => clearStrategyValues(value[authType])}
              isDeleted={false}
              getOrchestartionFormConfig={getOrchestartionFormConfig}
            />
          </div>

          {!value[authType]?.attributes?.length && (
            <div className="button-container">
              <div className="line line--green line--dashed"></div>
              <AddConditionsButton items={addAttributesConditionMenuItems} buttonText={_t("add_attribute")} clickParams={{ item: value[authType], isFirstLevel: false }} />
            </div>
          )}
        </PaymentOrchestrationContainerWithIndentation>
        <PaymentOrchestrationTreeViewItemRecursive
          data={value[authType]?.attributes || []}
          onChange={onChange}
          authType={authType}
          getOrchestartionFormConfig={getOrchestartionFormConfig}
        />
      </div>
    </div>
  )
}
export default PaymentOrchestrationTreeViewRule

import React from "react";
import classNames from "classnames";
import { Loading } from "carbon-components-react";
import { LoaderProps } from "./types";

const Loader: React.FC<LoaderProps> = ({
  className,
  small = false,
  withOverlay = false,
  formOverlay = false,
  bgOpacity = 0,
  active,
  description,
}) => {
  return (
    <div
      className={classNames(
        "bx--loading__wrapper",
        formOverlay && "form-overlay",
        `form-overlay__opacity--${bgOpacity}`
      )}
    >
      <Loading
        className={classNames(className)}
        small={small}
        withOverlay={withOverlay}
        active={active}
        description={description}
      />
      {description && (
        <span className={"bx--loading__text"}>{description}</span>
      )}
    </div>
  );
};

export default Loader;

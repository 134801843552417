import { useGetRoles } from "modules/roles/apiHooks";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useConstants } from "system/helpers/hooks";
import { UseMonitoringModuleDataRes } from "modules/pendingTransactionsMonitoring/types";
import { useGetPaymentGateways } from "modules/pendingTransactionsMonitoring/apiHooks";

export const useMonitoringModuleData = (): UseMonitoringModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGateways = [] } = useGetPaymentGateways();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const gatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  return { merchantsOptions, gatewaysOptions };
};

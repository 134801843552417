import React from "react";
import { PasswordInput as CarbonPasswordInput } from "carbon-components-react";
import { InputProps } from "components/atoms/InputPassword/types";

const InputPassword: React.FC<InputProps> = ({
  id = "input",
  labelText,
  value,
  onChange,
  hideLabel = false,
  size = "xl",
  ...rest
}) => {
  return (
    <CarbonPasswordInput
      {...rest}
      id={id}
      onChange={(e) => onChange(e?.target?.value)}
      value={value}
      labelText={labelText}
      size={"xl"}
    />
  );
};

export default InputPassword;

import React from "react";
import FormBuilder from "../../formBuilder/FormBuilder";
import { useTrans } from "../../../system/translations/hooks";
import { useCreateMerchantSlackChannelSetup, useDeleteSlackChannel, useGetMerchantSlackChannels } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { useSetupSlackChannelConfig } from "../constantHooks";
import { useParams } from "react-router-dom";
import Loader from "components/atoms/Loader";
import { createEditSlackChannelsValidation } from "../validation"

const SetupSlackChannel: React.FC = () => {
  const { _t } = useTrans();
  const routeParams = useParams<{ merchantId: string }>();
  const { data: slackChannels = [], isLoading } = useGetMerchantSlackChannels(+routeParams.merchantId);
  const { mutate: onSetup} = useCreateMerchantSlackChannelSetup();
  const { mutate: onDelete } = useDeleteSlackChannel();
  const formConfig = useSetupSlackChannelConfig(slackChannels);
  
  const onSubmit = async (data: any) => {

    if (slackChannels && (data?.channels.length < slackChannels.length)) {
      const partialIds = new Set(data?.channels.map((item: any) => item.id));
      const deletedChannel = slackChannels.filter(item => !partialIds.has(item.id));
      onDelete(deletedChannel[0])
    } else {
      const newChannel = data?.channels.slice(-1)[0]
      onSetup({...newChannel, merchantId: +routeParams.merchantId });
    }
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }
  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("setup_slack_channel")}>
                <FormBuilder
                showSubmit={false}
                formItemsConfig={formConfig}
                formProps={{
                  onSubmit: onSubmit,
                  enableReinitialize: true,
                  initialValues: {"channels" : [...slackChannels] },
                  validationSchema: createEditSlackChannelsValidation(_t)
                }}
              />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default SetupSlackChannel;

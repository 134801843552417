import React from "react";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useUpdateStatusConfig } from "modules/transactions/constantHooks";
import { Column, Grid, Row } from "carbon-components-react";
import { useUpdateTransactionStatus } from "modules/transactions/apiHooks";
import { updateTransactionValidation } from "modules/transactions/validation";

const UpdateTransactionStatus = () => {
  const { _t } = useTrans();
  const { mutate, isLoading } = useUpdateTransactionStatus();
  const formConfig = useUpdateStatusConfig({ isLoading });
  const onSubmit = (data: any) => {
    mutate(data);
  };

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 12, offset: 2 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("change_transactions_status")}>
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                onSubmit: onSubmit,
                validationSchema: updateTransactionValidation(_t),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default UpdateTransactionStatus;

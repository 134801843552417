import * as Yup from "yup";
import { CreateEditMonitoringMerchantValidationParams } from "./types";
import { TransType } from "system/translations/types";

export const createEditMonitoringMerchantValidation = (
  _t: TransType,
  params: CreateEditMonitoringMerchantValidationParams
) => {
  return Yup.object().shape({
    merchantId: Yup.string().required(_t("required")),
    paymentGatewayCode: Yup.string().required(_t("required")),
    maxSingleAmount: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    maxCumulativeAmount: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    cumulativeAmountCheckTimeFrame: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    cumulativeAmountCheckLength: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
  });
};

import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import {
  useCreateSetting,
  useCreateSettingCredentials,
  useGetSettingTemplate,
} from "modules/settings/apiHooks";
import { createEditValidation } from "modules/settings/validation";
import { useCreateEditConfig } from "modules/settings/constantHooks";
import { useModifySettingsTemplate } from "modules/settings/hooks";
import Loader from "components/atoms/Loader";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onCreate } = useCreateSetting(routeParams?.settingId);
  const { mutate: onCreateCredentials } = useCreateSettingCredentials(
    routeParams?.settingId
  );
  const isCredentials = routeParams?.settingId === "credentials";
  const { data, isLoading } = useGetSettingTemplate({
    settingId: routeParams?.settingId,
  });
  const templateData = useModifySettingsTemplate(data);
  const formConfig = useCreateEditConfig(templateData, {
    settingId: routeParams?.settingId,
  });
  const onSubmit = (data: any) => {
    if (isCredentials) {
      onCreateCredentials({ data: data, type: data?.type });
    } else {
      onCreate(data);
    }
  };
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed 
      className="update-settings-content">
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_setting")}>
            <FormBuilder
              formItemsConfig={formConfig}
              formProps={{
                submitBtnLabel: _t("create"),
                onSubmit: onSubmit,
                initialValues: { type: routeParams?.settingId },
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

import React from "react";
import FormBuilder from "../../formBuilder/FormBuilder";
import { useTrans } from "../../../system/translations/hooks";
import { useCreateMerchant } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { createEditValidation } from "../validation";
import { useCreateEditConfig } from "../constantHooks";
import { getInitialValuesForCreate } from "../constants";
import { usePrepareDataForMerchantIpn } from "../hooks";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { mutate: onCreate } = useCreateMerchant();
  const { normalizeDataBeforeSave } = usePrepareDataForMerchantIpn()
  const formConfig = useCreateEditConfig({});
  const onSubmit = async (data: any) => {
    onCreate(normalizeDataBeforeSave(data));
  };

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_merchant")}>
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                onSubmit: onSubmit,
                initialValues: getInitialValuesForCreate({isSubMerchant: false}),
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

import { eachDayOfInterval, eachHourOfInterval, eachMonthOfInterval, eachWeekOfInterval, eachYearOfInterval, endOfWeek, format } from "date-fns";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { useGetSetting } from "modules/settings/apiHooks";
import { useEffect, useState } from "react";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useGetPaymentGateways, useGetPaymentMethods } from "../payment/apiHooks";
import { UseModuleDataRes } from "./types";
import { isEmpty } from "lodash";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentMethods = [] } = useGetPaymentMethods();
  const { data: paymentGateways = [] } = useGetPaymentGateways();
  const { data: currencies = [] } = useGetSetting('currencies');

  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const paymentMethodsOptions = arrayOptionsMap(paymentMethods, {
    labelKey: "label",
    valueKey: "value",
  });
  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "codeLiteral",
  });
  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  return { merchantsOptions, currenciesOptions, paymentGatewaysOptions, paymentMethodsOptions };
};

export const useGetDataByFilter = (filters: any, convertedFilters: any) => {
  const dateArrayFrom = filters.fromDate.split('-')
  const dateArrayTo = filters.toDate.split('-')
  const yearForm = dateArrayFrom[0]
  const monthFrom = dateArrayFrom[1] - 1
  const dayFrom = dateArrayFrom[2]


  const yearTo = dateArrayTo[0]
  const monthTo = dateArrayTo[1] - 1
  const dayTo = dateArrayTo[2]

  const result = eachDayOfInterval({
    start: new Date(filters.fromDate),
    end: new Date(filters.toDate)
  })

  function getDaysInMonth() {
    const days = result.map(date => {
      const fullDate = new Date(date).toString().split(' ')
      const prepareObj = {
        name: [fullDate[1], fullDate[2]].join(' ') + ', ' + fullDate[3],
        date: format(new Date(date), "yyyy-MM-dd HH:mm"),
        withdrawalCount: 0,
        depositCount: 0,
      }
      return prepareObj
    })

    return days;
  }

  function getHoursInPeriod() {
    const hours = eachHourOfInterval({
      start: new Date(yearForm, monthFrom, dayFrom, 0),
      end: new Date(yearTo, monthTo, dayTo, 23)
    })
    const prepareDate = hours.map(date => {

      const prepareObj = {
        name: new Date(date).getHours().toString()+ ':00' + '(' + new Date(date).toString().split(' ').slice(1, 3).join('') + ')',
        date: format(new Date(date), "yyyy-MM-dd HH:mm"),
        withdrawalCount: 0,
        depositCount: 0,
      }
      return prepareObj
    })

    return prepareDate;
  }

  function getWeeksInPeriod() {
    const weeks = eachWeekOfInterval({
      start: new Date(yearForm, monthFrom, dayFrom),
      end: new Date(yearTo, monthTo, dayTo)
    }, { weekStartsOn: 1 })
    const prepareDate = weeks.map(date => {
      const fullDate = new Date(date).toString().split(' ')
      const endWeekDate = endOfWeek(new Date(date), { weekStartsOn: 1 }).toString().split(' ')
      const weeksYear = fullDate[3] === endWeekDate[3]
        ? fullDate[3]
        : fullDate[3] +  ' - ' + endWeekDate[3]
      
      const prepareObj = {
        name: fullDate.slice(1, 3).join('') + ' - ' + endWeekDate.slice(1, 3).join('') + ', ' + weeksYear,
        date: format(new Date(date), "yyyy-MM-dd HH:mm"),
        withdrawalCount: 0,
        depositCount: 0,
      }
      return prepareObj
    })

    return prepareDate;
  }

  function getMonthInPeriod() {

    const month = eachMonthOfInterval({
      start: new Date(yearForm, monthFrom, dayFrom),
      end: new Date(yearTo, monthTo, dayTo)
    })
    
    const prepareDate = month.map(date => {
      const fullDate = new Date(date).toString().split(' ')
      const prepareObj = {
        name: [fullDate[1], fullDate[3]].join(', ')  ,
        date: format(new Date(date), "yyyy-MM-dd HH:mm"),
        withdrawalCount: 0,
        depositCount: 0,
      }
      return prepareObj
    })

    return prepareDate;
  }

  function getYearsInPeriod() {
    const years = eachYearOfInterval({
      start: new Date(yearForm, monthFrom, dayFrom),
      end: new Date(yearTo, monthTo, dayTo)
    })
    const prepareDate = years.map(date => {
      const prepareObj = {
        name: new Date(date).toString().split(' ')[3],
        date: format(new Date(date), "yyyy-MM-dd HH:mm"),
        withdrawalCount: 0,
        depositCount: 0,
      }
      return prepareObj
    })

    return prepareDate;
  }

  const [data, setData] = useState<any>(getDaysInMonth())

  useEffect(() => {
    switch (filters.differentiation) {
      case 'hour':
        setData(getHoursInPeriod())
        break;
      case 'day':
        setData(getDaysInMonth())
        break;
      case 'week':
        setData(getWeeksInPeriod())
        break;
      case 'month':
        setData(getMonthInPeriod())
        break;
      default:
        setData(getYearsInPeriod())
    }

  }, [filters, convertedFilters])

  return {data}
}


export const usePrepareDataToAnalyticSection = ({
  analyticsData,
  data ,
  convertedFilters,
  setCurrenciesOptions
}: {
  analyticsData: any,
  data : any,
  convertedFilters: any,
  setCurrenciesOptions: (value: any) => void
}) => {
    useEffect(() => {

    const currenciesOptions: any = [{ depositCurrencies: [] }, { withdrawalCurrencies: [] }]

    if (analyticsData.data) {
      Object.keys(analyticsData.data).map(item => {
        const prepareDataDate = item.includes('Z') 
          ? format(new Date(item.substring(0, item.length - 1)), "yyyy-MM-dd HH:mm")
          : format(new Date(item), "yyyy-MM-dd HH:mm")
        const idx = data.findIndex((dat: any) => dat.date === prepareDataDate);
        
        analyticsData.data[item].data.map((dataObj: any) => {

          if (dataObj.transactionType === 'DEPOSIT' && !currenciesOptions[0].depositCurrencies.includes(dataObj.currency)) {
            currenciesOptions[0].depositCurrencies = [...currenciesOptions[0].depositCurrencies, dataObj.currency]
          } else if (dataObj.transactionType === 'WITHDRAWAL' && !currenciesOptions[1].withdrawalCurrencies.includes(dataObj.currency)) {
            currenciesOptions[1].withdrawalCurrencies = [...currenciesOptions[1].withdrawalCurrencies, dataObj.currency]
          }

          if (dataObj.transactionType === 'DEPOSIT') {
            data[idx] = {
              ...data[idx],
              depositCount: (data[idx]?.depositCount || 0) + dataObj.count,
              depositAmountCurrency: {
                ...data[idx]?.depositAmountCurrency,
                [dataObj.currency]: !isEmpty(convertedFilters)
                  ? +((data[idx]?.depositAmountCurrency?.[dataObj.currency] || 0) + dataObj.amount).toFixed(2).replace(/(\.0+|0+)$/, '')
                  : dataObj.amount,
              },
            }

          } else {
            data[idx] = {
              ...data[idx],
              withdrawalCount: (data[idx]?.withdrawalCount || 0) + dataObj.count,
              withdrawalAmountCurrency: {
                ...data[idx]?.withdrawalAmountCurrency,
                [dataObj.currency]: !isEmpty(convertedFilters)
                  ? +((data[idx]?.withdrawalAmountCurrency?.[dataObj.currency] || 0) + dataObj.amount).toFixed(2).replace(/(\.0+|0+)$/, '')
                  : dataObj.amount,
              }
            }
          }
        })

        setCurrenciesOptions(currenciesOptions)
        return item
      })
    }}, [data, analyticsData])


    return { data }
}

import React, { useMemo } from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateRole, useGetRolesAuthorities } from "modules/roles/apiHooks";
import { createEditValidation } from "modules/roles/validation";
import { modifyCreateUpdateReqData } from "../helper";
import { useRoleInitialData } from "../hooks";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/roles/constantHooks";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { mutate: onCreate } = useCreateRole();
  const { data: rolesAuthorities = [], isLoading } = useGetRolesAuthorities();
  const formConfig = useCreateEditConfig({
    rolesAuthorities,
  });
  const { allAuthorities } = useRoleInitialData(rolesAuthorities, {});
  const onSubmit = async (data: any) => {
    const reqData = modifyCreateUpdateReqData(data);
    onCreate(reqData);
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 12, offset: 2 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_role")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                onSubmit: onSubmit,
                initialValues: { authorities: allAuthorities },
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UseAlertsModuleDataRes } from "modules/alerts/types";
import { useTrans } from "system/translations/hooks";

export const useAlertsModuleData = (): UseAlertsModuleDataRes => {
  const { _t } = useTrans();
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });

  const alertsTypesOptions = [
    { label: _t("cumulative_amount"), value: "cumulative_amount" },
    { label: _t("errors_in_a_row"), value: "errors_in_a_row" },
    { label: _t("single_amount"), value: "single_amount" },
    { label: _t("success_rate"), value: "success_rate" },
  ];

  return { merchantsOptions, alertsTypesOptions };
};

import React, { useEffect, useMemo } from "react";
import { Dropdown } from "carbon-components-react";
import { SelectProps } from "./types";
import Loader from "components/atoms/Loader";

const Select: React.FC<SelectProps> = ({
  id = "Select",
  onChange,
  items = [],
  labelText,
  placeholderText,
  value,
  hideLabel = false,
  disabled,
  size = "xl",
  isLoading = false,
  light = false,
  ...rest
}) => {
  const dropdownInitialVal = useMemo(() => {
    let initial = null;
    items?.forEach((item: any) => {
      if (item.value === value) {
        initial = item;
      }
    });
    return initial;
  }, [value]);
  return (
    <Dropdown
      {...rest}
      light={light}
      disabled={disabled}
      id={id}
      label={<>{isLoading ? <Loader /> : placeholderText}</>}
      titleText={<>{labelText}</>}
      initialSelectedItem={dropdownInitialVal}
      onChange={(data: any) => onChange(data?.selectedItem?.value)}
      selectedItem={
        items.find((item: any) => item.value === value) || null
      }
      items={items}
      size={"xl"}
      hideLabel={hideLabel}
    />
  );
};

export default Select;

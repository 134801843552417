import React from "react";
import Sidebar from "components/organisms/Sidebar";
import { APP_ROUTES } from "../../system/router/constants";
import { Search20, Notification20, AppSwitcher20 } from "@carbon/icons-react";
import { useLocation } from "react-router-dom";
import { useConstants } from "../../system/helpers/hooks";

const MainSidebar = ({ isSideNavExpanded, onClickSideNavExpand }: any) => {
  let location = useLocation();
  const { sidebarMenu } = useConstants();

  return (
    <Sidebar
      isSideNavExpanded={isSideNavExpanded}
      onClickSideNavExpand={onClickSideNavExpand}
      menuList={sidebarMenu}
      activeLocation={location.pathname}
    />
  );
};

export default MainSidebar;

import React from "react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useGetUser, useUpdateUser } from "../apiHooks";
import Card from "components/atoms/Card";
import { Button, Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { createEditUserValidation } from "../validation";
import Loader from "components/atoms/Loader";
import Settings from "modules/users/components/Settings";
import { useCreateEditConfig, useDeleteUserConfig } from "modules/users/constantHooks";
import { useDeleteUserModal } from "../hooks";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useUpdateUser();
  const { data: userData, isLoading } = useGetUser(routeParams?.id);
  const formConfig = useCreateEditConfig({
    isEdit,
  });
  const deleteUserFormConfig = useDeleteUserConfig();
  const onSubmit = async (data: any) => {
    const normalize = { ...data };
    if (isEdit && !normalize.password) {
      delete normalize.password;
    }
    onUpdate({ data: normalize, id: routeParams?.id });
  };
  const { OnDeleteWithComment } = useDeleteUserModal(deleteUserFormConfig, onUpdate)

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 12, offset: 2 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card
            title={_t("edit_user")}
            extra={
              <Button onClick={() => OnDeleteWithComment(userData)}>
                {_t("deactivateUser")}
              </Button>
            }
          >
            <Row narrow>
              <Column lg={{ span: 10 }} md={{ span: 10 }} sm={{ span: 8 }}>
                <FormBuilder
                  formItemsConfig={formConfig}
                  formProps={{
                    submitBtnLabel: _t("save_changes"),
                    submitBtnKind: "primary",
                    onSubmit: onSubmit,
                    initialValues: {
                      ...userData,
                    },
                    validationSchema: createEditUserValidation(_t, {
                      isEdit,
                    }),
                  }}
                />
              </Column>
              <Column lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 8 }}>
                {userData && <Settings userData={userData} />}
              </Column>
            </Row>
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

import React, { createContext } from "react";
import { InjectTransContextProps } from "./types";

export const TransContext = createContext<InjectTransContextProps | null>(null);

export const InjectTransContext: React.FC<InjectTransContextProps> = ({
  locale,
  messages,
  children,
}) => {
  return (
    <TransContext.Provider value={{ locale, messages }}>
      {children}
    </TransContext.Provider>
  );
};

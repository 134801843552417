import React, { useMemo } from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import {
  useGetSettingById,
  useUpdateSetting,
  useUpdateSettingCredentials,
} from "modules/settings/apiHooks";
import { createEditValidation } from "modules/settings/validation";
import { useCreateEditConfig } from "modules/settings/constantHooks";
import { useModifySettingsTemplate } from "modules/settings/hooks";
import Loader from "components/atoms/Loader";
import { useGetCredential } from "modules/credentials/apiHooks";
import { useGetQuery } from "system/helpers/hooks";
import "styles/pages/settings/update.scss"

const Update: React.FC = () => {
  const { _t } = useTrans();
  const query = useGetQuery();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useUpdateSetting(routeParams?.settingId);
  const { mutate: onUpdateCredentials } = useUpdateSettingCredentials(
    routeParams?.settingId
  );
  const isCredentials = routeParams?.settingId === "credentials";
  const { data: dataSetting, isLoading: isLoadingSetting } = useGetSettingById({
    settingId: routeParams?.settingId,
    id: routeParams?.id,
  });
  const { data: dataCredential, isLoading: isLoadingCredential } =
    useGetCredential(routeParams?.id);
  const credentialDataItem = useMemo(() => {
    return dataCredential
      ? dataCredential?.filter(
          (item: any) => `${item.id}` == query.get("paramId")
        )
      : [];
  }, [query, dataCredential]);
  const data = isCredentials ? credentialDataItem?.[0] : dataSetting;
  const isLoading = isLoadingCredential || isLoadingSetting;
  const templateData = useModifySettingsTemplate(data, routeParams.id);
  const formConfig = useCreateEditConfig(templateData, {
    settingId: routeParams?.settingId,
    optionsData: data,
    isEdit: true,
  });
  const onSubmit = (data: any) => {
    const updateFunc = isCredentials ? onUpdateCredentials : onUpdate;
    updateFunc({
      data: { ...data },
      id: routeParams?.settingId,
      type: data.type,
    });
  };
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed
      className="update-settings-content"
    >
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("update")}>
            <FormBuilder
              formItemsConfig={formConfig}
              formProps={{
                submitBtnLabel: _t("update"),
                onSubmit: onSubmit,
                initialValues: templateData,
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

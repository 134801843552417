import React from "react";
import {
  SideNav,
  SideNavLink,
  SideNavMenu,
  SideNavMenuItem,
} from "carbon-components-react";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { path } from "system/helpers/pathHelper";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { SidebarProps } from "components/organisms/Sidebar/types";

const Sidebar: React.FC<SidebarProps> = ({
  isSideNavExpanded,
  onClickSideNavExpand,
  menuList,
  activeLocation,
}) => {
  const location = useLocation();
  const history = useHistory();

  const linkHandler = (link: string) => history.push(link);

  const checkMatch = (url: string) => {
    const route = location?.pathname + location.search;
    return route === url || (route === "/" && url === path("/:lang/"));
  };

  return (
    <SideNav
      aria-label="Side navigation"
      isRail
      expanded={isSideNavExpanded}
      onOverlayClick={onClickSideNavExpand}
    >
      <ul className={"bx--side-nav__items"}>
        {menuList.map((item, index: number) =>
          item.submenu ? (
            <RoleWrapper key={index} accessTo={item?.accessTo}>
              <SideNavMenu
                key={index}
                title={item.title}
                renderIcon={item.icon}
              >
                {item.submenu.map((subItem: any, index: number) => (
                  <RoleWrapper key={index} accessTo={subItem?.accessTo}>
                    <SideNavMenuItem
                      key={index}
                      href={subItem.link}
                      onClick={(e: any) => {
                        e.preventDefault();
                        if (e.ctrlKey || e.metaKey) {
                          window.open(subItem.link, "_blank")
                        } else {
                          linkHandler(subItem.link);
                        }
                      }}
                      className={classNames(
                        checkMatch(subItem.link) &&
                          "bx--side-nav__menu-item_active"
                      )}
                    >
                      {subItem.title}
                    </SideNavMenuItem>
                  </RoleWrapper>
                ))}
              </SideNavMenu>
            </RoleWrapper>
          ) : (
            <RoleWrapper key={index} accessTo={item.accessTo}>
              <SideNavLink
                key={index}
                renderIcon={item.icon}
                href={item.link}
                className={classNames(
                  checkMatch(item.link) && "bx--side-nav__link_active"
                )}
                onClick={(e: any) => {
                  e.preventDefault();
                  if (e.ctrlKey || e.metaKey) {
                    window.open(item.link, "_blank")
                  } else {
                    linkHandler(item.link);
                  }
                }}
              >
                {item.title}
              </SideNavLink>
            </RoleWrapper>
          )
        )}
      </ul>
    </SideNav>
  );
};

export default Sidebar;

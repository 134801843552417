import React, { useMemo } from "react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { useChangePassword } from "modules/admin/apiHooks";
import { useChangePasswordFormConfig } from "modules/admin/constantHooks";
import { changePasswordValidation } from "modules/admin/validation";
import { useGetCurrentAdmin } from "modules/auth/apiHooks";

const ChangePassword: React.FC = () => {
  const { _t } = useTrans();
  const { mutate } = useChangePassword();
  const { data } = useGetCurrentAdmin();
  const formConfig = useChangePasswordFormConfig();
  const onSubmit = async (val: any) => {
    const password = val.password;
    const requestData = { id: data?.id, data: { password } };
    mutate(requestData);
  };
  const validationSchema = useMemo(() => changePasswordValidation(_t), []);

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("change_password")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnKind: "primary",
                onSubmit: onSubmit,
                validationSchema: validationSchema,
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import { ComboBox } from "carbon-components-react";
import { SelectWithSearchProps } from "./types";

const SelectWithSearch: React.FC<SelectWithSearchProps> = ({
  id = "Select",
  onChange,
  items = [],
  labelText,
  placeholderText,
  value,
  hideLabel = false,
  disabled,
  size = "xl",
  isLoading = false,
  light = false,
  onConfirmChange,
  ...rest
}) => {
  const [visibleItems, setVisibleItems] = useState(items);
  const [ input, setInput ] = useState<string>('')
  useEffect(() => { 
    setVisibleItems(items) 
  },[JSON.stringify(items)]) 

  const getValueLabel = (value: any) => {
    return typeof value === 'object'
              ? items?.find((item: any) => JSON.stringify(item.value) === JSON.stringify(value))?.label || ""
              : items?.find((item: any) => item.value === value)?.label || ""
  }

  useEffect(() => {
    !input && setInput('')
    input?.trim() 
        ? input === getValueLabel(value)
          ? setVisibleItems(items)
          : setVisibleItems([...items].filter((item: any) => item.label.toLowerCase().includes(input?.trim().toLowerCase())))
        : setVisibleItems(items)

  }, [input])

  useEffect(() => {
    setInput(getValueLabel(value))
  }, [value, items])

  const onChangeHandler = (data: any) => {
    !value
      ? setInput(data?.selectedItem?.label)
      : setInput(getValueLabel(value))
  
    onConfirmChange && value
      ? onConfirmChange({
          onOk: () => onChange(data?.selectedItem?.value || ''), 
          value: value, 
          valueAfterChange: data?.selectedItem?.value || '',
        })
      : onChange(data?.selectedItem?.value || '')
  }
  return (
    <ComboBox
      {...rest}
      light={light}
      disabled={disabled}
      id={id}
      placeholder={placeholderText || ''}
      titleText={<>{labelText}</>}
      onChange={onChangeHandler}
      selectedItem={
        items.find((item: any) => {
          if (typeof item.value === 'object') {
            return JSON.stringify(item.value) === JSON.stringify(value)
          }

          return item.value === value
        }) || null
      }
      items={visibleItems}
      size={"xl"}
      value={input}
      downshiftProps={{
        onStateChange: (state: any) => {
            if (state?.type === "__autocomplete_change_input__" || state?.type === 11) {
              setInput(state?.inputValue)
            }
          }
      }}
    />
  );
};

export default SelectWithSearch;

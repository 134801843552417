import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_CREDENTIALS,
  API_GET_CREDENTIAL,
  API_GET_CREDENTIAL_TEMPLATE,
  API_CREATE_CREDENTIAL,
  API_UPDATE_CREDENTIAL,
  API_DELETE_CREDENTIAL,
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  CredentialDataType,
  CredentialsDataType,
} from "modules/credentials/types";

const getCredentials = "getCredentials";
const getCredential = "getCredential";

export const useGetCredentials = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getCredentials],
    (): Promise<CredentialsDataType> =>
      apiRequest({
        url: API_GET_CREDENTIALS,
        method: "get",
      })
  );
};
export const useGetCredential = (credentialId: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getCredential, credentialId],
    (): Promise<CredentialDataType> =>
      apiRequest({
        url: generatePath(API_GET_CREDENTIAL, { credentialId }),
        method: "get",
      }),
    {
      enabled: !!credentialId,
    }
  );
};

export const useGetCredentialTemplate = ({ credentialId, id }: any) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getCredential, credentialId, id],
    (): Promise<CredentialDataType> =>
      apiRequest({
        url: generatePath(API_GET_CREDENTIAL_TEMPLATE, { credentialId }),
        method: "get",
      })
  );
};

export const useCreateCredential = (credentialId: string) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_CREDENTIAL, { credentialId }),
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getCredential);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateCredential = (credentialId: string) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_CREDENTIAL, {
          credentialId,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getCredential);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteCredential = (credentialId: string) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_CREDENTIAL, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getCredential);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

import React from "react";
import Table from "modules/table";
import { useGetRequestOrchestationHistory } from "./apiHooks";
import { useTableColumns } from "./constantHooks";
import { OrchestrationHistoryItem } from "./types";
import Details from "./components/Details";

const RequestOrchestrationHistory = ({ initialFilters = {} }) => {
  const { data = {}, isLoading } = useGetRequestOrchestationHistory(initialFilters);
  const columns = useTableColumns();
  const tableData: OrchestrationHistoryItem[] = Object.values(data)?.[0]
    ? Object.values(data)?.[0]
    : [];

    return (
      <Table
        columns={columns}
        isLoading={isLoading}
        data={tableData}
        rowSubComponent={
          <Details />
        }
      />
    );
};

export default RequestOrchestrationHistory;

import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import { 
  API_CLOSE_PERIOD_MERCHANTS_BALANCE_V2,
  API_CREATE_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION,
  API_GET_ACTIVE_GATEWAYS_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION,
  API_GET_LAST_CLOSED_PERIOD_MERCHANTS_BALANCE_V2, API_GET_MERCHANTS_BALANCE_V2_AMOUNT,
  API_GET_MERCHANTS_BALANCE_V2_CURRENCY,
  API_GET_MERCHANTS_BALANCE_V2_DETAILS_ATTACHMENT_ITEM,
  API_GET_MERCHANTS_BALANCE_V2_DETAILS_EXPORT,
  API_GET_MERCHANTS_BALANCE_V2_DETAILS_TRANSACTION_EXPORT,
  API_UPDATE_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION_ITEM
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { MerchantBalanceDetailsPeriodData, MerchantsBalanceDetailsPSPBalancesType, MerchantsBalanceTransactionsDetailsDataType } from "./types";
import { useTrans } from "system/translations/hooks";
import { useToast } from "modules/toast/hooks";

const getMerchantBalanceDetailsList = "getMerchantBalanceDetailsList";
const getMerchantBalanceDetailsAmount = "getMerchantBalanceDetailsAmount";
const getGatewaysListActiveKey = "getGatewaysListActiveKey";
const getMerchantBalanceLastClosedPeriodKey = "getMerchantBalanceLastClosedPeriodKey";
const getMerchantBalanceDetailsAttachmentItemKey = "getMerchantBalanceDetailsAttachmentItemKey";

export const useGetMerchantBalanceDetailsList = (
  merchantId: number,
  currency: string,
  filters: any
) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantBalanceDetailsList, merchantId, currency, filters],
    (): Promise<MerchantBalanceDetailsPeriodData> =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_MERCHANTS_BALANCE_V2_CURRENCY, { merchantId, currency }),
          filters
        ),
        method: "get",
      })
  );
};

export const useGetMerchantBalanceDetailsAmount = (
  merchantId: number,
  currency: string,
  filters: any
) => {  
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantBalanceDetailsAmount, merchantId, currency, filters],
    (): Promise<MerchantsBalanceTransactionsDetailsDataType> =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_MERCHANTS_BALANCE_V2_AMOUNT, { merchantId, currency }),
          filters
        ),
        method: "get",
      })
  );
};

export const useCreateManualTransaction = (merchantId: number, currency: string, filters: any) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION, {merchantId, currency}),
        method: "post",
        data: data,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantBalanceDetailsList);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateManualTransactionItem = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION_ITEM, { id: data.id } ),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantBalanceDetailsList)
          .then(() => apiSuccessMessage())
        
      },
      onError: (err: ErrorType) => apiErrors(err, true),
    }
  );
};

export const useClosePeriod = (data: {merchantId : number, id: number, dateFrom: string, dateTo: string}) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    () =>
      apiRequest({
        url: generatePath(API_CLOSE_PERIOD_MERCHANTS_BALANCE_V2, {id: data.id}),
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantBalanceDetailsList);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useGetGatewaysListActive = (merchantId: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getGatewaysListActiveKey, merchantId],
    (): Promise<string[]> =>
      apiRequest({
        url: generatePath(API_GET_ACTIVE_GATEWAYS_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION, {merchantId}),
        method: "get",
      })
  );
};

export const useGetMerchantBalanceLastClosedPeriod = (
  merchantId: number,
  currency: string,
) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantBalanceLastClosedPeriodKey, merchantId, currency],
    (): Promise<MerchantsBalanceDetailsPSPBalancesType> =>
      apiRequest({
        url: generatePath(API_GET_LAST_CLOSED_PERIOD_MERCHANTS_BALANCE_V2, { merchantId, currency }),
        
        method: "get",
      })
  );
};

export const useGetMerchantsBalanceV2DetailsExport = () => {
  const [apiRequest] = useApiRequest();
  const toastHandler = useToast();
  const { _t } = useTrans();
  return useMutation(
    ({ merchantId, currency, filters }: any) =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_MERCHANTS_BALANCE_V2_DETAILS_EXPORT, {merchantId, currency}),
          filters
          ),
        method: "get",
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          caption: _t("export_csv_notification"),
          kind: "success",
        });
      },
    }
  );
};


export const useGetMerchantsBalanceV2DetailsTransactionExport = () => {
  const [apiRequest] = useApiRequest();
  const toastHandler = useToast();
  const { _t } = useTrans();
  return useMutation(
    ({ merchantId, currency, filters }: any) =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_MERCHANTS_BALANCE_V2_DETAILS_TRANSACTION_EXPORT, {merchantId, currency}),
          filters
          ),
        method: "get",
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          caption: _t("export_csv_notification"),
          kind: "success",
        });
      },
    }
  );
};

export const useGetMerchantBalanceDetailsAttachmentItem = () => {  
  const [apiRequest] = useApiRequest();
  const toastHandler = useToast();
  const { _t } = useTrans();

  return useMutation(
    ({ id, type }: any) =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_MERCHANTS_BALANCE_V2_DETAILS_ATTACHMENT_ITEM, { id }),
          {type}
          ),
        responseType: 'arraybuffer',
        method: "get",
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          caption: _t("file_downloaded"),
          kind: "success",
        });
      },
    }
  );
};

import React from "react";
import * as Yup from "yup";
import { ArrowRight20 } from "@carbon/icons-react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { isValidEmail } from "system/helpers/validationHelper";
import { useLoginFormConfig } from "modules/auth/constantHooks";

interface LoginFormProps {
  onSuccess: (data: any) => void;
  onLoginAsync: (data: any) => any;
  isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSuccess,
  isLoading,
  onLoginAsync,
}) => {
  const { _t } = useTrans();
  const formConfig = useLoginFormConfig();
  const onSubmit = async (data: any) => {
    const res = await onLoginAsync(data);

    if (res?.tfaenabled) {
      onSuccess && onSuccess({ rememberMe: data.rememberMe });
    }
  };

  const SignupSchema = Yup.object().shape({
    email: isValidEmail(_t).required(),
    password: Yup.string().required(),
  });

  return (
    <FormBuilder
      formItemsConfig={formConfig}
      formProps={{
        onSubmit: onSubmit,
        initialValues: { email: "", password: "", rememberMe: false },
        validationSchema: SignupSchema,
        submitBtnKind: "primary",
        submitBtnLabel: _t("continue"),
        renderIcon: ArrowRight20,
        isLoading,
      }}
    />
  );
};

export default LoginForm;

import React from "react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useCreateMerchantPendingTransactionsMonitoring } from "../apiHooks";
import Card from "components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { createEditValidation } from "../validation";
import { useCreateConfig } from "../constantHooks";
import { useFormHelpers } from "../hooks";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { mutate: onCreate } = useCreateMerchantPendingTransactionsMonitoring();
  const { initialValues, normalizeData } = useFormHelpers({ isEdit: false })
  const formConfig = useCreateConfig();
  const onSubmit = async (data: any) => {
    onCreate(normalizeData(data));
  };

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create")}>
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                onSubmit: onSubmit,
                initialValues: initialValues,
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

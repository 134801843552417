import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Column, Grid, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import {
  useGetTranslations,
  useGetTranslationsItem,
  useUpdateTranslations,
} from "modules/translations/apiHooks";
import { createEditValidation } from "modules/translations/validation";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/translations/constantHooks";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const history = useHistory()  
  const { data: translationsData, isLoading } = useGetTranslationsItem(routeParams.id);
  const { mutateAsync: onUpdate } = useUpdateTranslations();
  const translationData = useMemo(
    () =>
      translationsData
        ? {
            key: routeParams?.id,
            ...translationsData?.[0]?.translations?.[routeParams?.id],
          }
        : {},
    [translationsData]
  );
  const languages = translationsData?.[0].languages || [];
  const formConfig = useCreateEditConfig({ languages, isEdit: true });
  const onSubmit = async (data: any) => {
    const { key, ...rest } = data;
    const { status } = await onUpdate({ data: rest, id: key });
    status === "APPROVED" && history.goBack();
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card
            title={_t("edit_translation")}
            /*        extra={<Button>{_t("deactivateTranslation")}</Button>}*/
          >
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                onSubmit: onSubmit,
                initialValues: {
                  ...translationData,
                },
                validationSchema: createEditValidation(_t, {
                  isEdit,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import {
  useGetMerchantPaymentMethodById,
  useGetMerchantProvidersById,
  useMerchantEditPaymentMethod,
} from "modules/merchantPayments/apiHooks";
import { createEditValidation } from "modules/merchantPayments/validation";
import { useCreateEditConfig } from "modules/merchantPayments/constantHooks";
import Loader from "components/atoms/Loader";

const Update: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useMerchantEditPaymentMethod();
  const { data, isLoading } = useGetMerchantPaymentMethodById({
    merchantId: routeParams?.merchantId,
    id: routeParams?.id,
    paymentType: routeParams?.type,
  });
  const editMethodOptions = [
    { label: data?.paymentMethodName, value: data?.paymentMethod },
  ];
  const { data: editProviders } = useGetMerchantProvidersById({
    paymentId: data?.paymentMethod,
  });
  const formConfig = useCreateEditConfig({
    merchantId: routeParams?.merchantId,
    isEdit: true,
    initialValues: data,
    editMethodOptions,
    editProviders,
  });
  const onSubmit = async (data: any) => {
    onUpdate({
      ...data,
      merchantId: routeParams?.merchantId,
      id: routeParams?.id,
    });
  };
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("update_merchant_payment")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("update"),
                onSubmit: onSubmit,
                initialValues: {
                  ...data,
                  paymentType: routeParams?.type,
                },
                validationSchema: createEditValidation(_t, {
                  isEdit: true,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

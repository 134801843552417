import React from "react";
import {User20} from "@carbon/icons-react";
import {InfoUserProps} from "./types";
import { useGetUser } from "modules/users/apiHooks";

const InfoUser: React.FC<InfoUserProps> = ({ id }) => {
  const { data } = useGetUser(id + "");
  return (
      <div className="info-user">
        <div className="info-user--logo">
          <User20 className={"bx--header__menu-item__icon"} />
        </div>
        <div className='info-user--wrap'>
          <div className='info-user--username'>
            {data?.name}
          </div>
          <div className='info-user--email'>
            {data?.email}
          </div>
        </div>
      </div>
  );
};

export default InfoUser;

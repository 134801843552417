import React, { useMemo } from "react";
import DescriptionList from "../../../components/molecules/DescriptionList";
import { useGetTransaction } from "../apiHooks";
import Loader from "components/atoms/Loader";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { TransactionByIdDataItemType } from "modules/transactions/types";

const TransactionDetails = ({ id }: any) => {
  const { data, isLoading } = useGetTransaction(id);
  const listData: any = useMemo(() => {
    const res = [];
    let key: keyof TransactionByIdDataItemType;
    if (data?.transaction) {
      for (key in data?.transaction) {
        res.push({ title: key, value: data?.transaction?.[key] });
      }
    }
    return res;
  }, [data]);
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return <DescriptionList list={listData} />;
};

const TransactionDetailsWrapped = (props: any) => {
  return (
    <RoleWrapper accessTo={"ROLE_VIEW_TRANSACTION_DETAILS"}>
      <TransactionDetails {...props} />
    </RoleWrapper>
  );
};

export default TransactionDetailsWrapped;

import React from "react";
import { Content, Grid, Row, Column } from "carbon-components-react";
import HeaderWrapper from "../../components/organisms/Header";
import MobileMenu from "../../components/organisms/MobileMenu";

export const MainTemplate: any = ({ children }: any) => (
  <>
    <HeaderWrapper />
    <Content>
      <Grid>
        <Row>
          <Column>
            <div style={{ position: "relative" }}>{children}</div>
          </Column>
        </Row>
      </Grid>
    </Content>
    <MobileMenu/>
  </>
);

export default MainTemplate;

import { CreateEditAlertsValidationParams } from "modules/alerts/types";
import * as Yup from "yup";
import { TransType } from "../../system/translations/types";
import { MerchantsFeesWithChangeFromTableKeys } from "./types";

export const createEditMerchantFeesValidation = (
  _t: TransType,
  params: CreateEditAlertsValidationParams
) => {

  const createSchema = Yup.object().shape({
    merchantId: Yup.object().required(_t("required")),
    paymentProviders: Yup.array().min(1).required(_t("required")),
  })

  const baseSchema = Yup.object().shape({
    depositFee: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    depositFeeValidFrom: Yup.string().required(_t("required")),
    withdrawalFee: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    withdrawalFeeValidFrom: Yup.string().required(_t("required")),
    depositAddedFee: Yup.number().min(0).when('depositAddedFeeValidFrom', {
      is: (depositAddedFeeValidFrom: any) => !!depositAddedFeeValidFrom,
      then: Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    depositAddedFeeValidFrom: Yup.string().when('depositAddedFee', {
      is: (depositAddedFee: any) => depositAddedFee >= 0,
      then: Yup.string().typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    depositFixedFee: Yup.number().min(0).when(['depositFixedFeeCurrency', 'depositFixedFeeValidFrom'], {
      is: (depositFixedFeeCurrency: any, depositFixedFeeValidFrom: any) => !!depositFixedFeeCurrency || !!depositFixedFeeValidFrom,
      then: Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    depositFixedFeeCurrency: Yup.string().when(['depositFixedFee', 'depositFixedFeeValidFrom'], {
      is: (depositFixedFee: any, depositFixedFeeValidFrom: any) => depositFixedFee >= 0 || !!depositFixedFeeValidFrom,
      then: Yup.string().required(_t("required"))
    }),
    depositFixedFeeValidFrom: Yup.string().when(['depositFixedFee', 'depositFixedFeeCurrency'], {
      is: (depositFixedFee: any, depositFixedFeeCurrency: any) => depositFixedFee >= 0 || !!depositFixedFeeCurrency,
      then: Yup.string().required(_t("required"))
    }),
    withdrawalAddedFee: Yup.number().min(0).when('withdrawalAddedFeeValidFrom', {
      is: (withdrawalAddedFeeValidFrom: any) => !!withdrawalAddedFeeValidFrom,
      then: Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    withdrawalAddedFeeValidFrom: Yup.string().when('withdrawalAddedFee', {
      is: (withdrawalAddedFee: any) => withdrawalAddedFee >= 0,
      then: Yup.string().typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    withdrawalFixedFee: Yup.number().min(0).when(['withdrawalFixedFeeCurrency', 'withdrawalFixedFeeValidFrom'], {
      is: (withdrawalFixedFeeCurrency: any, withdrawalFixedFeeValidFrom: any) => !!withdrawalFixedFeeCurrency || !!withdrawalFixedFeeValidFrom,
      then: Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    withdrawalFixedFeeCurrency: Yup.string().when(['withdrawalFixedFee', 'withdrawalFixedFeeValidFrom'], {
      is: (withdrawalFixedFee: any, withdrawalFixedFeeValidFrom: any) => withdrawalFixedFee >= 0 || !!withdrawalFixedFeeValidFrom,
      then: Yup.string().required(_t("required"))
    }),
    withdrawalFixedFeeValidFrom: Yup.string().when(['withdrawalFixedFee', 'withdrawalFixedFeeCurrency'], {
      is: (withdrawalFixedFee: any, withdrawalFixedFeeValidFrom: any) => withdrawalFixedFee >= 0 || !!withdrawalFixedFeeValidFrom,
      then: Yup.string().required(_t("required"))
    }),
    settlementFee: Yup.number().min(0).when([ 'settlementFeeType', 'settlementFeeValidFrom', 'settlementFeeCurrency'], {
      is: (settlementFeeType: any, settlementFeeValidFrom: any, settlementFeeCurrency: any) => 
        settlementFeeType === _t("fixed_fee")
          ? !!settlementFeeValidFrom || !!settlementFeeCurrency
          : !!settlementFeeValidFrom,
      then: Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    settlementFeeCurrency: Yup.string().when(['settlementFeeType', 'settlementFeeValidFrom', 'settlementFee'], {
      is: (settlementFeeType: any,settlementFeeValidFrom: any, settlementFee: any) => 
        settlementFeeType === _t("fixed_fee") && (!!settlementFeeValidFrom || settlementFee >= 0),
      then: Yup.string().typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    settlementFeeValidFrom: Yup.string().when(['settlementFeeType', 'settlementFeeCurrency', 'settlementFee'], {
      is: (settlementFeeType: any, settlementFeeCurrency: any, settlementFee: any) => 
        settlementFeeType === _t("fixed_fee") 
          ? !!settlementFeeCurrency || settlementFee >= 0 
          : settlementFee >= 0,
      then: Yup.string().typeError(_t("must_be_a_number")).required(_t("required"))
    })
  }, [
    ['depositFixedFee', 'depositFixedFeeValidFrom'],
    ['depositFixedFeeCurrency', 'depositFixedFeeValidFrom'],
    ['depositFixedFee', 'depositFixedFeeCurrency'],
    ['withdrawalFixedFeeCurrency', 'withdrawalFixedFeeValidFrom'],
    ['withdrawalFixedFee', 'withdrawalFixedFeeValidFrom'],
    ['withdrawalFixedFee', 'withdrawalFixedFeeCurrency'],
    ['depositAddedFee','depositAddedFeeValidFrom'],
    ['withdrawalAddedFee','withdrawalAddedFeeValidFrom'],
    ['settlementFeeType', 'settlementFeeValidFrom'],
    ['settlementFeeType', 'settlementFeeCurrency'],
    ['settlementFeeType', 'settlementFee'],
    ['settlementFeeCurrency', 'settlementFeeValidFrom'],
    ['settlementFeeCurrency', 'settlementFee'],
    ['settlementFeeValidFrom', 'settlementFee'],
  ]);

  return params.isEdit ? baseSchema : createSchema.concat(baseSchema)
};

export const createEditMerchantFeesItemCellValidation = (_t: TransType, type: MerchantsFeesWithChangeFromTableKeys | "settlementFeeWithCurrency" ) => {

  switch(type) {
      case 'depositFee':
        return Yup.object().shape({
          depositFee: Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t('required'))
        });
      case 'depositAddedFee':
        return Yup.object().shape({
          depositAddedFee: Yup.number().min(0).when('depositAddedFeeValidFrom', {
            is: (depositAddedFeeValidFrom: any) => !!depositAddedFeeValidFrom,
            then: (schema) => Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
          }),
          depositAddedFeeValidFrom: Yup.string().when('depositAddedFee', {
            is: (depositAddedFee: any) => depositAddedFee >= 0,
            then: (schema) => Yup.string().typeError(_t("must_be_a_number")).required(_t("required"))
          }),
        }, [['depositAddedFee','depositAddedFeeValidFrom']]);
      case 'withdrawalFee':
        return Yup.object().shape({
          withdrawalFee: Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t('required'))
        });
      case 'withdrawalAddedFee':
        return Yup.object().shape({
          withdrawalAddedFee: Yup.number().min(0).when('withdrawalAddedFeeValidFrom', {
            is: (withdrawalAddedFeeValidFrom: any) => !!withdrawalAddedFeeValidFrom,
            then: (schema) => Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
          }),
          withdrawalAddedFeeValidFrom: Yup.string().when('withdrawalAddedFee', {
            is: (withdrawalAddedFee: any) => withdrawalAddedFee >= 0,
            then: (schema) =>  Yup.string().typeError(_t("must_be_a_number")).required(_t("required"))
          }),
        }, [["withdrawalAddedFeeValidFrom", "withdrawalAddedFee"]]);
      case 'depositFixedFee':
        return Yup.object().shape({
          depositFixedFee: Yup.number().min(0).when(['depositFixedFeeCurrency', 'depositFixedFeeValidFrom'], {
            is: (depositFixedFeeCurrency: any, depositFixedFeeValidFrom: any) => !!depositFixedFeeCurrency || !!depositFixedFeeValidFrom,
            then: (schema) => Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
          }),
          depositFixedFeeCurrency: Yup.string().when(['depositFixedFee', 'depositFixedFeeValidFrom'], {
            is: (depositFixedFee: any, depositFixedFeeValidFrom: any) => depositFixedFee >= 0 || !!depositFixedFeeValidFrom,
            then: (schema) => Yup.string().required(_t("required"))
          }),
          depositFixedFeeValidFrom: Yup.string().when(['depositFixedFee', 'depositFixedFeeCurrency'], {
            is: (depositFixedFee: any, depositFixedFeeCurrency: any) => depositFixedFee >= 0 || !!depositFixedFeeCurrency,
            then: (schema) => Yup.string().required(_t("required"))
          }),
        }, [
          ['depositFixedFeeCurrency', 'depositFixedFeeValidFrom'],
          ['depositFixedFee', 'depositFixedFeeValidFrom'],
          ['depositFixedFee', 'depositFixedFeeCurrency']
        ])
      case 'withdrawalFixedFee':
        return Yup.object().shape({
          withdrawalFixedFee: Yup.number().min(0).when(['withdrawalFixedFeeCurrency', 'withdrawalFixedFeeValidFrom'], {
            is: (withdrawalFixedFeeCurrency: any, withdrawalFixedFeeValidFrom: any) => !!withdrawalFixedFeeCurrency || !!withdrawalFixedFeeValidFrom,
            then: (schema) => Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
          }),
          withdrawalFixedFeeCurrency: Yup.string().when(['withdrawalFixedFee', 'withdrawalFixedFeeValidFrom'], {
            is: (withdrawalFixedFee: any, withdrawalFixedFeeValidFrom: any) => withdrawalFixedFee >= 0 || !!withdrawalFixedFeeValidFrom,
            then: (schema) => Yup.string().required(_t("required"))
          }),
          withdrawalFixedFeeValidFrom: Yup.string().when(['withdrawalFixedFee', 'withdrawalFixedFeeCurrency'], {
            is: (withdrawalFixedFee: any, withdrawalFixedFeeValidFrom: any) => withdrawalFixedFee >= 0 || !!withdrawalFixedFeeValidFrom,
            then: (schema) => Yup.string().required(_t("required"))
          }),
        }, [
        ['withdrawalFixedFeeCurrency', 'withdrawalFixedFeeValidFrom'],
        ['withdrawalFixedFee', 'withdrawalFixedFeeValidFrom'],
        ['withdrawalFixedFee', 'withdrawalFixedFeeCurrency']
      ]);
      case 'settlementFee':
        return Yup.object().shape({
          settlementFee: Yup.number().min(0).when('settlementFeeValidFrom', {
            is: (settlementFeeValidFrom: any) => !!settlementFeeValidFrom,
            then: (schema) => Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
          }),
          settlementFeeValidFrom: Yup.string().when('settlementFee', {
            is: (settlementFee: any) => settlementFee >= 0,
            then: (schema) => Yup.string().typeError(_t("must_be_a_number")).required(_t("required"))
          }),
        },[["settlementFee", "settlementFeeValidFrom"]]);
      case 'settlementFeeWithCurrency':
        return Yup.object().shape({
          settlementFee: Yup.number().min(0).when(['settlementFeeCurrency', 'settlementFeeValidFrom'], {
            is: (settlementFeeCurrency: any, settlementFeeValidFrom: any) => !!settlementFeeCurrency || !!settlementFeeValidFrom,
            then: (schema) => Yup.number().min(0).typeError(_t("must_be_a_number")).required(_t("required"))
          }),
          settlementFeeCurrency: Yup.string().when(['settlementFee', 'settlementFeeValidFrom'], {
            is: (settlementFee: any, settlementFeeValidFrom: any) => settlementFee >= 0 || !!settlementFeeValidFrom,
            then: (schema) => Yup.string().required(_t("required"))
          }),
          settlementFeeValidFrom: Yup.string().when(['settlementFee', 'settlementFeeCurrency'], {
            is: (settlementFee: any, settlementFeeCurrency: any) => settlementFee >= 0 || !!settlementFeeCurrency,
            then: (schema) => Yup.string().required(_t("required"))
          }),
        }, [
          ['settlementFeeCurrency','settlementFeeValidFrom'],
          ['settlementFee', 'settlementFeeValidFrom'],
          ['settlementFee', 'settlementFeeCurrency']
      ]);
      default:
        return Yup.object().shape({});
    }
  
};


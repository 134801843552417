import React, { useMemo, useState } from "react";
import Toggle from "components/atoms/Toggle";
import JsonEditor from "components/atoms/JsonEditor";
import Input from "components/atoms/Input";

const JsonOrInput = ({ value, onChange, labelText }: any) => {
  const initial = useMemo(() => {
    let result;
    try {
      JSON.parse(value);
      result = true;
    } catch (e) {
      result = false;
    }
    return result;
  }, []);

  const [toggle, setToggle] = useState(initial);
  return (
    <>
      <Toggle
        labelA={"Input"}
        labelB={"JsonEditor"}
        onChange={setToggle}
        value={toggle}
      />
      {labelText}
      {toggle ? (
        <JsonEditor value={value} onChange={onChange} />
      ) : (
        <Input value={value} onChange={onChange} />
      )}
    </>
  );
};

export default JsonOrInput;

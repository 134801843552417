import { Information16 } from "@carbon/icons-react"
import classNames from "classnames"

const InfoBlock = ({text, additionalText, withIcon = true}: { text: string, additionalText: string, withIcon: boolean}) => {
  return (
  <div className="info-block__information">
    {withIcon && <Information16 /> }
    <div className="info-block__text-container">
      <div className={classNames(!withIcon && "info-block__title-text")}>{text && text}</div> 
      <div>{additionalText && additionalText}</div> 
    </div>
  </div>
)
}

export default InfoBlock
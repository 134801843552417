import React from "react";
import { DescriptionListProps } from "./types";
import Description from "../../atoms/Description";
import { DescriptionProps } from "../../atoms/Description/types";

const DescriptionList: React.FC<DescriptionListProps> = ({ list }: any) => {
  return (
    <div className={"description-list"}>
      {list.map((item: DescriptionProps) => {
        return (
          <Description
            title={item.title}
            dontConvertToString={item.dontConvertToString}
            value={item.value}
          />
        );
      })}
    </div>
  );
};

export default DescriptionList;

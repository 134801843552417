import React from "react";
import { Search as CarbonSearch, Button } from "carbon-components-react";
import { Search16 } from "@carbon/icons-react";
import { SearchProps } from "components/atoms/Search/types";
import classNames from "classnames";

const Search: React.FC<SearchProps> = ({
  id = "input",
  labelText,
  placeHolderText,
  value,
  onChange,
  showSearch = false,
  disabled = false,
  searchIconDescription,
  formOnSubmit,
  size = "xl",
  className = '',
  ...rest
}) => {
  return (
    <div className={classNames(className, "bx--search-wrapper")}>
      <CarbonSearch
        id={id}
        onChange={(e) => onChange(e?.target?.value)}
        value={value}
        labelText={labelText}
        renderIcon={className ? <Search16/> : <></>}
        onClear={formOnSubmit}
        size={size}
        placeHolderText={placeHolderText || ""}
        disabled={disabled}
      />
      {showSearch && (
        <Button
          style={{ color: "#000" }}
          className={"bx--search-button"}
          type={"submit"}
          hasIconOnly
          iconDescription={searchIconDescription}
          renderIcon={Search16}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default Search;

import React, { useEffect, useMemo } from "react";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Table from "modules/table";
import { useModal } from "modules/modal/hooks";
import { useTrans } from "system/translations/hooks";
import { useFilters } from "system/helpers/hooks";
import {
  useGetMerchantsBalanceV2,
  useGetMerchantsBalanceExport,
} from "modules/merchantsBalanceV2/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/merchantsBalanceV2/constantHooks";
import { Button } from "carbon-components-react";
import { format } from "date-fns";
import config from "system/config";
import { DocumentExport16, Filter16, Information16 } from "@carbon/icons-react";
import DotsMenu from "components/atoms/DotsMenu";
import { generatePath, useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import NotConfiguretGatewaysModal from "components/molecules/NotConfiguretGatewaysModal";
import { useGetSavedFilters } from "./hooks";

const MerchantsBalanceV2 = () => {
  const { _t } = useTrans();
  const { showModal, hideModal } = useModal();
  const history:any = useHistory();
  const filterInitialValues = useMemo(
    () => ({
        fromDate: format(new Date(), config.dateFormat),
        toDate: format(new Date(), config.dateFormat),
      }),
    []
  );
  const savedFilters = useGetSavedFilters(filterInitialValues)
  const { filters, setFilters } = useFilters(savedFilters);
  
  const requestParams = useMemo(() => ({ ...filters }), [filters]);  
  const { data, isLoading } = useGetMerchantsBalanceV2(requestParams, {
    enabled: !!filters.merchantId,
  });
  const { mutate: onGetExport, isLoading: isLoadingExport } =
    useGetMerchantsBalanceExport();
  const columns = useTableColumns(data);
  const searchFormConfig = useSearchFormConfig();
  const onFilterSubmit = (data: any) => {
    const normalizeDate = {
      ...data,
      sendTime: new Date().getTime() // for refreshing page on ApplyFilter click
    };
    if (data?.date) {
      normalizeDate.fromDate = data?.date?.fromDate;
      normalizeDate.toDate = data?.date?.toDate;
    }
    delete normalizeDate.date;
    delete normalizeDate.searchFrom;    
    hideModal('filters');

    setFilters(normalizeDate);
  };
  const onExport = () => {
    onGetExport(filters);
  };

  const showDetails = ({ merchantId, currency }: any) => {
    const link = generatePath(APP_ROUTES.merchantsBalanceV2.detailsInCurrency.index, {
      merchantId,
      currency,
      fromDate: filters.fromDate,
      toDate: filters.toDate
    })
    history.push(link, history.location);
  };
  const isMobile = window.matchMedia('(max-width: 671px)').matches;

  const actionMenuItemsExport = [
    {
      title: _t("export_CSV"),
      onClick: onExport,
      icon: <DocumentExport16 />,
    }
  ];

  const actionMenuItems = [
    {
      title: _t("receive_details"),
      onClick: showDetails,
      icon: <Information16 />,
    }
  ];

  const showFilters = () => {
    showModal({
      modalHeading: _t("filters"),
      isShowScale: false,
      componentProps: {
        showSubmit: false,
        formItemsConfig: searchFormConfig,
        formProps: {
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: filterInitialValues,
        },
      },
      component: FormBuilder,
    }, 'filters');
  }

  const showNotClosedPeriod = (data: any) => {
    showModal({
      isShowScale: false,
      component: NotConfiguretGatewaysModal,
      size: 's',
      componentProps: {
        data: data,
        titleText: _t('not_configuret_payments_providers'),
        secondaryText: _t('not_included_in_calculation')
      },
      footer: Button,
        footerProps: {
          onClick: () => hideModal(),
          kind: "tertiary",
          children: 'Continue'
        }
    })
  }
  
  const preparedData = useMemo(
    () => (data 
      ? [...data?.result,
          { 
            name: "TOTAL",
            balanceInEuro: data?.totalBalance,
            availableBalance: data?.availableBalance
          }
        ]
      : []
    ),[data])

    useEffect(() => {
      if (!!data?.gatewaysWithoutSettings?.config || !!data?.gatewaysWithoutSettings?.fee) {
        showNotClosedPeriod(data?.gatewaysWithoutSettings)
      } 

    }, [data, requestParams, isLoading])
  return (
    <Card
      title={_t("merchants_balance")}
      extra={
        <>
          {!isMobile 
            ? <Button
                kind="tertiary"
                onClick={() => {}}
                renderIcon={DocumentExport16}
                disabled={isLoadingExport}
                >
                  {_t("export_CSV")}
              </Button>
            : <DotsMenu items={actionMenuItemsExport}/>
          }
        </>
      }
    >
      {!isMobile 
      ? <FormBuilder
          formItemsConfig={searchFormConfig}
          showSubmit={false}
          showModal={true}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: filterInitialValues,
            values: savedFilters.merchantId
                ? { 
                    ...savedFilters,
                    date: {
                      fromDate: savedFilters.fromDate,
                      toDate: savedFilters.toDate,
                    } 
                  } 
                : {}
          }}
        />
      : <>
          <Button
            kind="ghost"
            style={{ color: "#252C32" }}
            onClick={() => showFilters()}
            renderIcon={Filter16}
            >
              {_t("filters")}
          </Button>
        </>
    }
      <Table
        columns={columns}
        isLoading={isLoading}
        data={preparedData}
        cellProps={{
          showDetails,
          actionMenuItems
        }}
      />
    </Card>
  );
};

export default MerchantsBalanceV2;


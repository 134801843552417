import React from "react";
import { Button } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import { ConfirmModalButtonsProps } from "components/atoms/ConfirmModalButtons/types";
import { CloseOutline16 } from "@carbon/icons-react";

const ConfirmModalButtons: React.FC<ConfirmModalButtonsProps> = ({
  onOk,
  onCancel,
  onCancelText,
  onOkText,
  renderIcon,
  onOkKind = "secondary",
  className = ''
}) => {
  const { _t } = useTrans();
  return (
    <>
      <Button kind="tertiary" onClick={onCancel} className={className}>
        {onCancelText ? onCancelText : _t('cancel')}
      </Button>
      <Button 
        kind = {onOkKind} 
        onClick={onOk} 
        renderIcon = {renderIcon}
        className={className}
      >
        <span>{onOkText ? onOkText : _t("on_ok")}</span>
      </Button>
    </>
  );
};

export default ConfirmModalButtons;

import React from "react";
import Card from "components/atoms/Card";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import { useTableColumns } from "modules/merchantBlackList/constantHooks";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Add16, Edit16, TrashCan16 } from "@carbon/icons-react";
import {
  useGetMerchantBlackList,
  useDeleteMerchantBlackList,
} from "modules/merchantBlackList/apiHooks";
import { useListPagination } from "system/helpers/hooks";
import config from "system/config";
import { useConfirmModal } from "modules/modal/predefinedModals";

const MerchantBlackList = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { onConfirm } = useConfirmModal();
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { data, isLoading } = useGetMerchantBlackList(
    routeParams?.merchantId,
    pagination
  );
  const { mutate: onDelete } = useDeleteMerchantBlackList(
    routeParams?.merchantId
  );

  const columns = useTableColumns();
  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.merchants.blacklist.update, {
      merchantId: routeParams?.merchantId,
      id,
    });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };
  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <Edit16 /> },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];

  return (
    <Card
      title={_t("blacklist")}
      extra={
        <ButtonLink
          isExpressive
          renderIcon={Add16}
          linkTo={generatePath(APP_ROUTES.merchants.blacklist.create, {
            merchantId: routeParams?.merchantId,
          })}
          title={_t("create")}
        />
      }
    >
      <Table
        totalRecords={data?.totalElements}
        onPagination={setPagination}
        columns={columns}
        isLoading={isLoading}
        data={data?.content || []}
        isPagination
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        cellProps={{
          actionMenuItems,
        }}
      />
    </Card>
  );
};

export default MerchantBlackList;

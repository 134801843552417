import { useMemo } from "react";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useDeleteRole } from "modules/roles/apiHooks";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";

export const useRoleInitialData = (authorities: any, role: any) => {
  const allAuthorities: any = {};
  const initialAuthorities: any = {};
  useMemo(() => {
    if (authorities && role) {
      authorities?.forEach((item: any) => {
        initialAuthorities[item.name] = false;
        allAuthorities[item.name] = false;
      });
      role?.authorities?.forEach((item: any) => {
        initialAuthorities[item] = true;
      });
    }
  }, [authorities, role]);

  return { allAuthorities, initialAuthorities };
};

export const useDeleteWithConfirm = (linkToRolePage = false) => {
  const { mutate: onDelete } = useDeleteRole();
  const { onConfirm } = useConfirmModal();
  const history = useHistory()
  return ({ id }: any) =>{  
    onConfirm({ onOk: () => {
      onDelete({ id })
      linkToRolePage && history.push(APP_ROUTES.roles.index)
    } });
  }
};

import * as Yup from "yup";
import { CreateEditMonitoringPerMerchantValidationParams } from "./types";
import { TransType } from "system/translations/types";

export const createEditValidation = (
  _t: TransType,
  params: CreateEditMonitoringPerMerchantValidationParams
) => {
  return Yup.object().shape({
    transactionType: Yup.string().required(_t("required")),
    merchant: Yup.object().required(_t("required")),
    paymentGatewayCode: Yup.string().required(_t("required")),
    successRateCheckLength: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    channel: Yup.object().required(_t("required")),
    successRateThreshold: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    errorsInARowThreshold: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
  });
};

import DotsMenu from "components/atoms/DotsMenu";
import InfoTag from "components/atoms/InfoTag";
import Toggle from "components/atoms/Toggle";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { FormConfigType } from "modules/formBuilder/types";
import { TableColumnType } from "modules/table/types";
import { useMemo, useState } from "react";
import { ArrayOptionsMapRes } from "system/helpers/types";
import { useTrans } from "system/translations/hooks";
import { filterOptions } from "./constants";
import { PaymentInstrumentItemState } from "./types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();

  const activeStatusColumn = [{
    maxWidth: 140,
    Header: _t("status"),
    mobileVisible: true,
    Cell: (props: any) => {
      const activationStatus = props?.row?.original?.active
      const activationState: PaymentInstrumentItemState = props?.row?.original?.state
      const isActive = activationStatus && activationState !== "ACTIVE"
      const isSuspended = !activationStatus && activationState !== "SUSPENDED"
      const isDeleted = activationState === "DELETED"

      const [ isDisabled, setDisabled ] = useState(isActive || isSuspended || isDeleted)
      const label = activationStatus ? _t("active") : _t("inactive")
      const status = activationStatus ? "active" : "deactivated"
       
      return (
        <RoleWrapper 
          accessTo={"ROLE_MODIFY_EXTERNAL_CUSTOMER_IDENTIFIERS"}
          defaultComponent={<InfoTag type={"secondary"} status={status} label={label} />}
        >
          <Toggle
            key={"Toggle" + props?.row?.original?.id}
            id={"Toggle" + props?.row?.original?.id}
            value={activationStatus}
            onChange={(e) => {
              props.onActionUpdate(props?.row?.original, setDisabled)
            }}
            labelA = {_t("inactive")}
            labelB = {_t("active")}
            withControlledValue = {true}
            disabled={isDisabled}
          />
        </RoleWrapper>

      );
    },
  }]

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t('transactionUUID'),
        accessor: "previousTransactionId",
        id: "previousTransactionId",
        mobileVisible: true,
        isMobileTitle: true,
        minWidth: 250,
      },
      {
        Header: _t("customer_id"),
        accessor: "customerId",
        id: "customerId",
        width: 100,
        mobileVisible: true,
      },
      {
        Header: _t("identification_key"),
        accessor: "identificationKey",
        id: "identificationKey",
        mobileVisible: true,
        width: 180
      },
      {
        Header: _t('billing_name'),
        accessor: "name",
        id: "name",
        mobileVisible: true,
        Cell: ({row}: any) => {
          return (row?.original?.firstName + " " + row?.original?.lastName) || ''
        }
      },
      {
        Header: _t("payment_gateway_code"),
        accessor: "paymentGatewayCode",
        id: "paymentGatewayCode",
        width: 180,
        mobileVisible: true,
      },
      {
        Header: _t("payment_gateway"),
        accessor: "paymentGatewayName",
        id: "paymentGatewayName",
        mobileVisible: true,
      },
      ...activeStatusColumn,
      {
        Header: _t("created_date"),
        accessor: "createdDate",
        id: "createdDate",
        mobileVisible: true,
      },
      {
        Header: _t("additional_info"),
        accessor: "additionalData",
        id: "additionalData",
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <RoleWrapper accessTo={"ROLE_MODIFY_EXTERNAL_CUSTOMER_IDENTIFIERS"}>
              <DotsMenu
                clickParams={{
                  id: props?.row?.original?.id,
                  allData: props?.row?.original,
                }}
                items={props?.actionMenuItems}
              />
            </RoleWrapper>
          );
        },
      },
    ],
    [activeStatusColumn]
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const filterByOptions: ArrayOptionsMapRes = useMemo(
    () => filterOptions(_t),
    [_t]
  );
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        name: "value"
      }),
      fields.filters.filterField({
        name: "filter",
        componentProps: {
          items: filterByOptions,
        },
      }),
      fields.general.submitBtn(),
    ];
  }, []);
  return formConfig;
};
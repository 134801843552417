import { useGetRoles } from "modules/roles/apiHooks";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useConstants } from "system/helpers/hooks";
import { UseMonitoringModuleDataRes } from "modules/monitoring/types";
import { useGetPaymentGatewaysCodes } from "modules/payment/apiHooks";

export const useMonitoringModuleData = (): UseMonitoringModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGateways = [] } = useGetPaymentGatewaysCodes();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "code",
    valueKey: "code",
  });

  return { merchantsOptions, paymentGatewaysOptions };
};

import { useLocation, useParams } from "react-router-dom";
import { useTrans } from "../../system/translations/hooks";

export const useModifyCrumbsPathnames = () => {
  const { _t } = useTrans();
  const location = useLocation();
  const routeParams: any = useParams();
  const pathnamesModified = location.pathname
    .split("/")
    .filter((item) => item)
    .map((item, index) => {
      return { item, index };
    });
  const pathnamesOriginal = location.pathname
    .split("/")
    .filter((item) => item)
    .map((item, index) => {
      return { item, index };
    });
  switch (pathnamesModified[0]?.item) {
    case "users":
    case "roles":
    case "translations": {
      if (routeParams?.id) {
        pathnamesModified[pathnamesModified.length - 1].item = _t("edit");
      }
      break;
    }
    case "merchants": {
      if (routeParams?.id) {
        if (pathnamesModified[3]?.item) {
          if (
            pathnamesModified[2].item != "blacklist" &&
            pathnamesModified[2].item != "whitelist" &&
            pathnamesModified[2].item != "payments"
          ) {
            pathnamesModified.splice(3, 1);
          }

          if (pathnamesModified[2].item == "update") {
            pathnamesModified[2].item = _t("edit");
          }
        } else {
          pathnamesModified[1].item = _t("edit");
        }
      }
      if (routeParams?.merchantId) {
        if (pathnamesModified[1]?.item == "sub") {
          pathnamesModified.splice(1, 2);
        } else {
          pathnamesModified.splice(1, 1);
        }
      } else {
        if (pathnamesModified[1]?.item == "sub") {
          pathnamesModified.splice(1, 1);
          pathnamesOriginal.splice(3, 1);
        }
      }

      if (routeParams?.merchantId && routeParams?.id) {
        pathnamesModified[pathnamesModified.length - 1].item = _t("edit");
      }
      break;
    }
    case "settings":
    case "credentials": {
      if (routeParams?.id && pathnamesModified[2]) {
        pathnamesModified[2].item = _t("edit");
      }
      break;
    }
    case "merchants-balance-v2": {      
      if (routeParams?.merchantId && routeParams?.currency && pathnamesModified.length >= 4) {
        pathnamesModified.splice(1, 4);
      }
      break;
    }
    case "merchants-balance-V2-merchant-configuration": {
      if (routeParams.id && routeParams.merchantId && routeParams.paymentGateway) {
        pathnamesModified[pathnamesModified.length - 1].item = _t("edit");
        pathnamesModified.splice(1,2);
      }
      break;
    }
  }
  return { pathnamesModified, pathnamesOriginal };
};

import React from "react";
import { NavLink } from "react-router-dom";
import { BreadcrumbItem } from "carbon-components-react";
import { itemCrumbProps } from "../../../modules/breadcrumbs/constant";

type CrumbProps = {
  item: itemCrumbProps;
  index: number;
  pathnames: itemCrumbProps[];
};

const Crumb: React.FC<CrumbProps> = ({ item, index, pathnames }) => {
  const getCrumbUrl = (index: number) => {
    const correctPath = pathnames
      .filter((path: itemCrumbProps) => {
        return path.index <= index;
      })
      .map((item: itemCrumbProps) => item.item);
    return `/${correctPath.join("/")}`;
  };

  return (
    <BreadcrumbItem style={{ textTransform: "capitalize" }}>
      {index == pathnames[pathnames.length - 1].index ||
      item.item == "deposit" ||
      item.item == "withdrawal" ? (
        <span className={"crumb"}>{item.item}</span>
      ) : (
        <NavLink to={`${getCrumbUrl(index)}`}>{item.item}</NavLink>
      )}
    </BreadcrumbItem>
  );
};

export default Crumb;

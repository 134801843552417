import React, { useMemo } from "react";
import { FormConfigType } from "../formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { useTrans } from "system/translations/hooks";

export const useLoginFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.email({
        componentProps: {
          className: "bx--text-input--inverted",
          autoComplete: "username",
        },
      }),
      fields.formFields.password({
        componentProps: {
          className: "bx--text-input--inverted",
          autoComplete: "current-password",
        },
      }),
      fields.formFields.rememberMe(),
    ];
  }, []);
  return formConfig;
};

export const useForgotPasswordFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.email({
        componentProps: {
          className: "bx--text-input--inverted",
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useResetPasswordFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.password({
        componentProps: {
          labelText: _t("new_password"),
          placeholder: _t("new_password_placeholder"),
          className: "bx--text-input--inverted",
          autoComplete: "off",
        },
      }),
      fields.formFields.confirm_password({
        name: "repeatPassword",
        componentProps: {
          className: "bx--text-input--inverted",
          autoComplete: "off",
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useTfaEnableFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.code({
        componentProps: {
          className: "bx--text-input--inverted",
          autoComplete: "off",
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useTfaProcessFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [fields.formFields.authenticationCode()];
  }, []);
  return formConfig;
};

import * as Yup from "yup";
import { CreateEditMonitoringValidationParams } from "./types";
import { TransType } from "system/translations/types";

export const createEditMonitoringValidation = (
  _t: TransType,
  params: CreateEditMonitoringValidationParams
) => {
  return Yup.object().shape({
    paymentGatewayCode: Yup.string().required(_t("required")),
    successRateCheckLength: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    successRateThreshold: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    errorsInARowThreshold: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
  });
};

import React, { useMemo } from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import {
  useDuplicateSettingPaymentGW,
  useGetSettingById,
} from "modules/settings/apiHooks";
import { createEditValidation } from "modules/settings/validation";
import { useCreateEditConfig } from "modules/settings/constantHooks";
import { useModifySettingsTemplate } from "modules/settings/hooks";
import Loader from "components/atoms/Loader";
import { useGetQuery } from "system/helpers/hooks";
import "styles/pages/settings/update.scss"

const Duplicate: React.FC = () => {  
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onCreate } = useDuplicateSettingPaymentGW();
  const { data, isLoading } = useGetSettingById({
    settingId: routeParams?.settingId,
    id: routeParams?.id,
  });
  const templateData = useModifySettingsTemplate(data);
  const formConfig = useCreateEditConfig(templateData, {
    settingId: routeParams?.settingId,
    optionsData: data,
    isEdit: true,
  });
  const onSubmit = (data: any) => {
    const normalizeData = {...data};
    delete normalizeData.id;
    onCreate(normalizeData);    
  };
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed
      className="update-settings-content"
    >
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create")}>
            <FormBuilder
              formItemsConfig={formConfig}
              formProps={{
                submitBtnLabel: _t("create"),
                onSubmit: onSubmit,
                initialValues: templateData,
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Duplicate;

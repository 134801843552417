import CustomTooltipItem from "components/atoms/CustomTooltipItem";
import React from "react";
import { useTrans } from "system/translations/hooks";

const CustomTooltip = ({ active, payload, label }: any, ) => {
  const { _t } = useTrans()
  let withdrawalQuantity: any
  let depositQuantity: any
  let depositPayload: any = []
  let withdrawalPayload: any = []

  payload.forEach((item: any) => {
    if (item.dataKey.includes('deposit') && item.dataKey !== 'depositCount') {
      depositPayload = [...depositPayload, item]
    } else if (item.dataKey === 'depositCount') {
      depositQuantity = item
    } else if (item.dataKey.includes('withdrawal') && item.dataKey !== 'withdrawalCount') {
      withdrawalPayload = [...withdrawalPayload, item]
    } else if (item.dataKey === 'withdrawalCount') {
      withdrawalQuantity = item
    }
  })

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="custom-tooltip__label">{label}</p>
        {depositQuantity && (
          <CustomTooltipItem
            name={depositQuantity.name}
            value={depositQuantity.value.toLocaleString()}
            color={depositQuantity.color}
            isQuantity={true}
          />
        )}
        {depositPayload.map((item: any) => {
          return (
            <CustomTooltipItem
              name={item.name}
              value={item.value.toLocaleString()}
              color={item.color}
            />
          )
        })}
        {depositQuantity && withdrawalQuantity && <div className="custom-tooltip__divider">{_t('withdrawals')}</div>}
        {withdrawalQuantity && (
          <CustomTooltipItem
            name={withdrawalQuantity.name}
            value={withdrawalQuantity.value.toLocaleString()}
            color={withdrawalQuantity.color}
            isQuantity={true}
          />
        )}
        {withdrawalPayload.map((item: any) => {
          return (
            <CustomTooltipItem
              name={item.name}
              value={item.value.toLocaleString()}
              color={item.color}
            />
          )
        })}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;

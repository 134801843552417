import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UseModuleDataRes } from "modules/merchantsBalanceV2/types";
import { useHistory } from "react-router-dom";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });

  return { merchantsOptions };
};

export const useGetSavedFilters = (
  filterInitialValues: { fromDate: string, toDate: string }
) => {
  const history:any = useHistory();
  const isBackFromCard = history.location?.state?.isBack
  
  if (history.action === 'PUSH' && isBackFromCard) {
    const normalizeHistoryState = { ...history.location?.state }
    delete normalizeHistoryState.isBack

    return normalizeHistoryState
  }

  return filterInitialValues
}

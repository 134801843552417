import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useMonitoringMerchantModuleData, useMonitoringMerchantSearchModuleData } from "modules/monitoringMerchant/hooks";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { CreateEditFormConfigParams } from "modules/monitoringMerchant/types";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("id"),
        accessor: "id",
        id: "id",
        width: 60,
        mobileVisible: true,
      },
      {
        Header: _t("merchant_id"),
        accessor: "merchantId",
        id: "merchantId",
        minWidth: 100,
        mobileVisible: true,
      },
      {
        Header: _t('merchant'),
        accessor: "merchantName",
        id: "merchantName",
        disableSortBy: true,
        minWidth: 100,
        mobileVisible: true,
      },
      {
        Header: _t("payment_gateway"),
        accessor: "paymentGatewayCode",
        id: "paymentGatewayCode",
        minWidth: 230,
        mobileVisible: true,
        isMobileTitle: true,
      },
      {
        Header: _t("cumulative_amount_check_length"),
        accessor: "cumulativeAmountCheckLength",
        id: "cumulativeAmountCheckLength",
        minWidth: 230,
        mobileVisible: true,
        isMobileHidden: true,
      },
      {
        Header: _t("cumulative_amount_check_time_frame"),
        accessor: "cumulativeAmountCheckTimeFrame",
        id: "cumulativeAmountCheckTimeFrame",
        minWidth: 260,
        mobileVisible: true,
        isMobileHidden: true,
      },
      {
        Header: _t("max_cumulative_amount"),
        accessor: "maxCumulativeAmount",
        id: "maxCumulativeAmount",
        minWidth: 180,
        mobileVisible: true,
        isMobileHidden: true,
      },
      {
        Header: _t("max_cumulative_amount_check_enabled"),
        accessor: "maxCumulativeAmountCheckEnabled",
        id: "maxCumulativeAmountCheckEnabled",
        minWidth: 270,
        mobileVisible: true,
        isMobileHidden: true,
        Cell: (props: any) => {
          return <>{`${props.row.original.maxCumulativeAmountCheckEnabled}`}</>;
        },
      },
      {
        Header: _t("max_single_amount"),
        accessor: "maxSingleAmount",
        id: "maxSingleAmount",
        minWidth: 150,
        mobileVisible: true,
        isMobileHidden: true,
      },
      {
        Header: _t("max_single_amount_check_enabled"),
        accessor: "maxSingleAmountCheckEnabled",
        id: "maxSingleAmountCheckEnabled",
        mobileVisible: true,
        isMobileHidden: true,
        minWidth: 240,
        Cell: (props: any) => {
          return <>{`${props.row.original.maxSingleAmountCheckEnabled}`}</>;
        },
      },
      {
        Header: _t("created_date"),
        accessor: "createdDate",
        id: "createdDate",
        minWidth: 180,
        mobileVisible: true,
      },
      {
        Header: _t("updated_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        minWidth: 180,
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};
const isMobile = window.matchMedia('(max-width: 671px)').matches;
export const useSearchFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { merchantsOptions, paymentGatewaysOptions } = useMonitoringMerchantSearchModuleData();
  const { _t} = useTrans()
  const formConfig = useMemo((): FormConfigType => {
    return  [
      fields.filters.merchantIdSingle({
        openModal: true,
        valuePreview: true,
        componentProps: {
          items: merchantsOptions,
          light: false,
          type: "default",
          labelWithAll: false,
          withSelectAll: false,
          singleSelect: true,
          label: _t('merchant'),
          openModal: true,
        },
        columnParams: {
          lg: 4,
          md: 3,
          className: 'bx--col'
        },
      }),
      fields.filters.paymentGateway({
        openModal: true,
        componentProps: {
          items: paymentGatewaysOptions,
          light: false,
          type: "default",
          labelWithAll: false,
          withSelectAll: false,
          singleSelect: true,
          hideLabel: true,
          label: _t("gateway")
        },
        columnParams: {
          lg: 4,
          md: 3,
          className: 'bx--col'
        },
      }),
      fields.general.submitBtn({ emptySpace: {} })
    ];
  }, [merchantsOptions]);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { merchantsOptions, paymentGatewaysOptions } =
    useMonitoringMerchantModuleData();
  /*private Long id;
private Long merchantId;
private String paymentGatewayCode;
private Integer maxSingleAmount;
private Integer maxCumulativeAmount;
private Integer cumulativeAmountCheckTimeFrame;
private Integer cumulativeAmountCheckLength;
private boolean maxSingleAmountCheckEnabled;
private boolean maxCumulativeAmountCheckEnabled;*/
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.merchantIdSingle({
        componentProps: {
          items: merchantsOptions,
          light: false,
        },
      }),
      fields.formFields.paymentGatewayCode({
        componentProps: {
          items: paymentGatewaysOptions,
          light: false,
        },
      }),
      fields.formFields.maxSingleAmount(),
      fields.formFields.maxCumulativeAmount(),
      fields.formFields.cumulativeAmountCheckTimeFrame(),
      fields.formFields.cumulativeAmountCheckLength(),
      fields.formFields.maxSingleAmountCheckEnabled({
        columnParams: {
          lg: 16,
          md: 16,
        },
      }),
      fields.formFields.maxCumulativeAmountCheckEnabled({
        columnParams: {
          lg: 16,
          md: 16,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, [merchantsOptions, paymentGatewaysOptions]);
  return formConfig;
};

import React from "react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useCreateAlerts } from "../apiHooks";
import Card from "components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { createEditAlertsValidation } from "../validation";
import { useCreateEditConfig } from "modules/alerts/constantHooks";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { mutate: onCreate } = useCreateAlerts();
  const formConfig = useCreateEditConfig({
    isEdit: false,
  });
  const onSubmit = async (data: any) => {
    const normalize = { ...data, recipient: data?.recipient?.join(",") };
    onCreate(normalize);
  };

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create")}>
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                onSubmit: onSubmit,
                initialValues: {},
                validationSchema: createEditAlertsValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

import React from "react";
import { Checkbox as CarbonCheckbox } from "carbon-components-react";
import { CheckboxProps } from "components/atoms/Checkbox/types";

const Checkbox: React.FC<CheckboxProps> = ({
  id = "Checkbox",
  labelText,
  value,
  onChange,
  className = '',
}) => {
  return (
    <CarbonCheckbox
      id={id}
      defaultChecked={value}
      onChange={onChange}
      indeterminate={false}
      labelText={labelText}
      className={className}
    />
  );
};

export default Checkbox;

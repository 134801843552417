import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_SETTINGS,
  API_GET_SETTING,
  API_GET_SETTING_BY_ID,
  API_GET_SETTING_TEMPLATE,
  API_CREATE_SETTING,
  API_UPDATE_SETTING,
  API_DELETE_SETTING,
  API_UPDATE_SETTING_CRON_JOB,
  API_GET_SETTING_PAYMENT_METHODS,
  API_GET_SETTING_CURRENCIES,
  API_GET_SETTING_COUNTRIES,
  API_UPDATE_SETTING_CREDENTIALS,
  API_CREATE_SETTING_CREDENTIALS,
  API_DUPLICATE_SETTING_PAYMENT_GW,
  API_GET_SETTING_OPERATIONS_TYPES
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { ErrorType } from "system/helpers/types";
import { useSuccessToast } from "system/helpers/hooks";
import {
  SettingCountriesDataType,
  SettingCurrenciesDataType,
  SettingDataType,
  SettingOperationTypesDataType,
  SettingPaymentMethodDataType,
  SettingsDataType,
} from "modules/settings/types";

const getSettings = "getSettings";
const getSetting = "getSetting";
const getSettingById = "getSettingById";
const getSettingTemplate = "getSettingTemplate";
const getSettingPaymentMethods = "getSettingPaymentMethods";
const getSettingCurrencies = "getSettingCurrencies";
const getSettingCountries = "getSettingCountries";
const getSettingOperationType = "getSettingOperationType";

export const useGetSettings = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSettings],
    (): Promise<SettingsDataType> =>
      apiRequest({
        url: API_GET_SETTINGS,
        method: "get",
      })
  );
};
export const useGetSetting = (settingId: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSetting, settingId],
    (): Promise<SettingDataType> =>
      apiRequest({
        url: generatePath(API_GET_SETTING, { settingId }),
        method: "get",
      })
  );
};
export const useGetSettingById = ({ settingId, id }: any) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSettingById, settingId, id],
    (): Promise<SettingDataType> =>
      apiRequest({
        url: generatePath(API_GET_SETTING_BY_ID, { settingId, id }),
        method: "get",
      })
  );
};
export const useGetSettingTemplate = ({ settingId, id }: any) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSettingTemplate, settingId, id],
    (): Promise<SettingDataType> =>
      apiRequest({
        url: generatePath(API_GET_SETTING_TEMPLATE, { settingId }),
        method: "get",
      })
  );
};

export const useCreateSetting = (settingId: string) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_SETTING, { settingId }),
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSetting);
        queryClient.invalidateQueries(getSettingById);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDuplicateSettingPaymentGW = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_DUPLICATE_SETTING_PAYMENT_GW,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSetting);
        queryClient.invalidateQueries(getSettingById);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateSetting = (settingId: string) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) => {
      return apiRequest({
        url: generatePath(API_UPDATE_SETTING, {
          settingId,
        }),
        method: "post",
        data: data.data,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSetting);
        queryClient.invalidateQueries(getSettingById);
        queryClient.invalidateQueries(getSettingTemplate);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateSettingCredentials = (settingId: string) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_SETTING_CREDENTIALS, {
          settingId,
          type: data?.type,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSetting);
        queryClient.invalidateQueries(getSettingTemplate);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useCreateSettingCredentials = (settingId: string) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_SETTING_CREDENTIALS, {
          settingId,
          type: data?.type,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSetting);
        queryClient.invalidateQueries(getSettingTemplate);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteSetting = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_SETTING, {
          id: data.id,
          settingId: data.settingId,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSetting);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateSettingCronJob = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (id: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_SETTING_CRON_JOB, {
          id,
        }),
        method: "post",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSetting);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useGetSettingPaymentMethods = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSettingPaymentMethods],
    (): Promise<SettingPaymentMethodDataType> =>
      apiRequest({
        url: API_GET_SETTING_PAYMENT_METHODS,
        method: "get",
      })
  );
};
export const useGetSettingCurrencies = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSettingCurrencies],
    (): Promise<SettingCurrenciesDataType> =>
      apiRequest({
        url: API_GET_SETTING_CURRENCIES,
        method: "get",
      })
  );
};
export const useGetSettingCountries = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSettingCountries],
    (): Promise<SettingCountriesDataType> =>
      apiRequest({
        url: API_GET_SETTING_COUNTRIES,
        method: "get",
      })
  );
};

export const useGetSettingOperationType = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSettingOperationType],
    (): Promise<SettingOperationTypesDataType> =>
      apiRequest({
        url: API_GET_SETTING_OPERATIONS_TYPES,
        method: "get",
      })
  );
};

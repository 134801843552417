import { useMemo } from "react";

export const useTransData = (data: any) =>
  useMemo(() => {
    if (!data?.[0]?.translations) {
      return [];
    }
    const keys = Object.keys(data?.[0]?.translations);
    const values = Object.values(data?.[0]?.translations);
    const mergedTrans = values.map((item: any, idx) => ({
      ...item,
      key: keys[idx],
    }));
    return mergedTrans;
  }, [data?.[0]?.translations]);

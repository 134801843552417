import * as Yup from "yup";
import { CreateEditAlertsValidationParams } from "./types";
import { TransType } from "system/translations/types";
import { isValidEmail } from "system/helpers/validationHelper";

export const createEditAlertsValidation = (
  _t: TransType,
  params: CreateEditAlertsValidationParams
) => {
  return Yup.object().shape({
    merchantId: Yup.string().required(_t("required")),
    alertType: Yup.string().required(_t("required")),
    name: Yup.string().required(_t("required")),
    description: Yup.string().required(_t("required")),
  });
};
/* Yup.lazy((val: string[]) =>
      Array.isArray(val)
        ? Yup.array().of(isValidEmail(_t).required())
        : Yup.array().defined()
    )*/

import axios, { Axios } from "axios";
import backendUrl from "system/api/backendEnvUrl";
import { getToken } from "system/helpers/helperFunctions";

const axiosClient: Axios = axios.create({
  baseURL: backendUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosClient.interceptors.request.use((config: any) => {
  const token = getToken();
  if (token) {
    config.headers["authorization"] = token;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    const modifiedRes = { ...response };
    if (response.headers.hasOwnProperty("x-total-count")) {
      modifiedRes.data = {
        data: response.data,
        total: response.headers["x-total-count"],
      };
      //perform the manipulation here and change the response object
    }
    return modifiedRes;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;

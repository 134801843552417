import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_CREATE_STATUS_REASON,
  API_DELETE_STATUS_REASON_ITEM,
  API_GET_STATUS_REASONS,
  API_GET_STATUS_REASON_ITEM,
  API_GET_TEMPLATE_STATUS_REASONS,
  API_UPDATE_STATUS_REASON
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { ErrorType } from "system/helpers/types";
import { useSuccessToast } from "system/helpers/hooks";
import { StatusReasonConnectionTemplateDataType, StatusReasonsDataType, StatusReasonsItemType } from "./types";

const getStatusReasons = "getStatusReasons";
const getStatusReasonItem = "getSetgetStatusReasonItemting";
const getStatusReasonConnectionTemplate = "getStatusReasonConnectionTemplate";

export const useGetStatusReasons = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getStatusReasons],
    (): Promise<StatusReasonsDataType> =>
      apiRequest({
        url: API_GET_STATUS_REASONS,
        method: "get",
      })
  );
};

export const useGetStatusReasonItem = (id: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getStatusReasonItem, id],
    (): Promise<StatusReasonsItemType> =>
      apiRequest({
        url: generatePath(API_GET_STATUS_REASON_ITEM, { id }),
        method: "get",
      })
  );
};

export const useGetStatusReasonConnectionTemplate = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getStatusReasonConnectionTemplate],
    (): Promise<StatusReasonConnectionTemplateDataType> =>
      apiRequest({
        url: API_GET_TEMPLATE_STATUS_REASONS,
        method: "get",
      })
  );
};

export const useCreateStatusReason = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_STATUS_REASON,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getStatusReasons);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateStatusReason = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) => {
      return apiRequest({
        url: API_UPDATE_STATUS_REASON,
        method: "post",
        data: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getStatusReasonItem);
        queryClient.invalidateQueries(getStatusReasons);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteStatusReason = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_STATUS_REASON_ITEM, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getStatusReasons);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans()
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("code"),
        id: "code",
        accessor: "code",
        mobileVisible: true,
        isMobileTitle: true,
      },
      {
        Header: _t("title"),
        id: "title",
        accessor: "title",
        mobileVisible: true,
      },
      {
        Header: _t("description"),
        id: "description",
        accessor: "description",
        mobileVisible: true,
      },
      {
        Header: _t("is_cascading_allowed"),
        id: "cascadingEnabled",
        accessor: "cascadingEnabled",
        mobileVisible: true,
        Cell: ({row}: any) => {
          return row?.original?.cascadingEnabled == null ? '-' : row?.original?.cascadingEnabled?.toString()
        }
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <RoleWrapper accessTo={"ROLE_MODIFY_STATUS_REASONS"}>
              <DotsMenu
                clickParams={{
                  id: props?.row?.original?.id,
                  allData: props?.row?.original,
                }}
                items={props?.actionMenuItems}
              />
            </RoleWrapper>
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useCreateEditConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.statusReasonCode(),
      fields.formFields.statusReasonTitle(),
      fields.formFields.statusReasonDescription(),
      fields.formFields.isCascadingAllowed({
        columnParams: {
          lg: 8,
          md: 4,
        },
      }),
      fields.general.divider()
    ];
  }, []);
  return formConfig;
};

import React from "react";
import { Toggle as CarbonToggle } from "carbon-components-react";
import { ToggleProps } from "components/atoms/Toggle/types";

const Toggle: React.FC<ToggleProps> = ({
  id = "Toggle",
  labelText,
  value,
  onChange,
  labelA = "",
  labelB = "",
  withControlledValue = false,
  size = "md",
  className = "toggle-custom",
  setColumnOrderPSPToMerchant = () => {},
  disabled = false
}) => {
  return (
    <CarbonToggle
      size={size}
      className={className}
      key={id}
      id={id}
      labelA={labelA}
      labelB={labelB}
      defaultToggled={withControlledValue ? undefined : value}
      toggled = {withControlledValue ? value : undefined}
      onToggle={(e) => {
        onChange(e);
        setColumnOrderPSPToMerchant(e)
      }}
      labelText={labelText}
      disabled={disabled}
    />
  );
};

export default Toggle;

import { Column as ColumnType } from "react-table";
import { generatePath, useHistory } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "modules/formBuilder/types";
import { useModuleData } from "modules/merchantsBalanceV2/hooks";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import DotsMenu from "components/atoms/DotsMenu";
import { APP_ROUTES } from "system/router/constants";
import { Link, Tooltip } from "carbon-components-react";

export const useTableColumns = (data: any): TableColumnType[] => {
  const { _t } = useTrans();
  const history = useHistory();
  const columns = useMemo((): TableColumnType[] => [
    {
      Header: _t("partner"),
      id: "merchantName",
      accessor: "merchantName",
      mobileVisible: true,
      isMobileTitle: true,
      Cell: ({ row }: any) => {
        if (row.original?.name === 'TOTAL') {
          return <strong>{_t("total")}</strong> 
        }
        return row.original?.merchantName
      }
    },
    {
      Header: _t("currency_balance"),
      id: "currency",
      accessor: "currency",
      mobileVisible: true,
    },
    {
      Header: _t("amount_in_currency"),
      id: "currencyBalance",
      accessor: "currencyBalance",
      mobileVisible: true,
      Cell: ({ row , showDetails }: any) => {  
        return row.original?.name !== 'TOTAL' && (
          <div style={{ display: 'flex' }}  >
            <Link
              onClick={() => {
                showDetails({
                  merchantId: row?.original?.merchantId,
                  currency: row?.original?.currency
                });
              }}
            >
              {row?.original?.currencyBalance}
            </Link>
            <Tooltip direction="top">
              {_t("receive_details")}
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: _t("rate"),
      id: "rate",
      accessor: "rate",
      mobileVisible: true,
    },
    {
      Header: _t("balance_in_euro"),
      id: "balanceInEuro",
      accessor: "balanceInEuro",
      mobileVisible: true,
      Cell: ({ row }: any) => {
        if (row.original?.name === 'TOTAL') {
          return <strong>{row.original?.balanceInEuro}</strong> 
        }
        return row.original?.balanceInEuro
      }
    },
    {
      Header: _t("available_balance"),
      id: "availableBalance",
      accessor: "availableBalance",
      mobileVisible: true,
      Cell: ({ row }: any) => {
        if (row.original?.name === 'TOTAL') {
          return <strong>{row.original?.availableBalance}</strong> 
        }
        return row.original?.availableBalance
      }
    },
    {
      maxWidth: 40,
      Header: "",
      id: "actionMenu",
      mobileVisible: true,
      Cell: (props: any) => {
        return props.row.original?.name !== 'TOTAL' 
        ? (
          <DotsMenu
            clickParams={{
              merchantId: props?.row?.original?.merchantId,
              currency: props?.row?.original?.currency,
            }}
            items={props?.actionMenuItems}
          />
        ) : <div style={{height: "40px"}}></div>

      },
    },
  ], [data]);

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const { merchantsOptions } = useModuleData();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchantIdV2({
        componentProps: {
          items: merchantsOptions,
          notSelected: true,
        },
      }),
      fields.filters.date({
        componentProps: {
          withDateType: false
        }
      }),
      fields.general.submitBtn(),
    ];
  }, [merchantsOptions]);
  return formConfig;
};

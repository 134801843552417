import React from "react";
import Create from "modules/merchantPayments/components/Create";

const MerchantPaymentsCreatePage = () => {
  return (
    <div>
      <Create />
    </div>
  );
};

export default MerchantPaymentsCreatePage;

import React from "react";
import Update from "modules/gateWayWhiteList/components/Update";

const GateWayWhiteListUpdatePage = () => {
  return (
    <div>
      <Update />
    </div>
  );
};

export default GateWayWhiteListUpdatePage;

import React from "react";
import Create from "modules/merchantsBalanceDetails/components/Create";

const MerchantsBalanceDetailsCreatePage = () => {
  return (
    <div>
      <Create />
    </div>
  );
};

export default MerchantsBalanceDetailsCreatePage;
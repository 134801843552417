import { RadioButton as CarbonRadioButton } from "carbon-components-react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { RadioButtonProps } from "./types";


const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  labelText,
  labelA = "",
  labelB = "",
  valueA = "",
  valueB = "",
  onValueChange = () => { },
  onChange,
  additionalRadioButtonsProps = [],
  invalid,
  invalidText,
}) => {
  const [type, setType] = useState(value);

  useEffect(() => {
    onValueChange(type);
    onChange && onChange(type)
  }, [type])

  const onClickHandler = (label: string, value: string) => {
    value 
      ? setType(value)
      : setType(label)
  }

  const isChecked = (label: string, value: string) =>  value ? type === value : type === label

  return (
    <div className={classNames("radio-button__container", {"radio-button__container-column" : additionalRadioButtonsProps.length > 0})}>
      <p className="radio-button__label-text">{labelText}</p>
      <div className="radio-button__buttons">
        <CarbonRadioButton
          onClick={() => onClickHandler(labelA, valueA)}
          checked={isChecked(labelA, valueA)}
          name={labelA}
          labelText={labelA}
        />
        <CarbonRadioButton
          onClick={() => onClickHandler(labelB, valueB)}
          checked={isChecked(labelB, valueB)}
          name={labelB}
          labelText={labelB} />

        {additionalRadioButtonsProps.length > 0 && (
            additionalRadioButtonsProps.map((item: any) => {
              return (
                <CarbonRadioButton
                  onClick={() => onClickHandler(item.label, item.value)}
                  checked={isChecked(item.label, item.value)}
                  name={item.label}
                  labelText={item.label}
                  disabled={!!item?.disabled}
                />
              )
            })
          

        )}
      </div>
      {invalid && !value && <div className="radio-button__invalid__text">{invalidText}</div> }
    </div>
  );
};

export default RadioButton;
import React from "react";
import { EventSchedule32, Warning32 } from "@carbon/icons-react";
import Divider from "components/atoms/Divider";
import { MerchantsBalancePeriodsToCloseData } from "modules/merchantsBalanceDetails/types";
import { useTrans } from "system/translations/hooks";

const PeriodsToClose: React.FC<{
  data: MerchantsBalancePeriodsToCloseData[]
}> = ({ data }) => {
  const { _t } = useTrans();
  return (
    <>
      {data.length > 0
        ? (
          <div className="period-to-closed__container">
            <EventSchedule32 className="period-to-closed__icon" />
            <div className="period-to-closed__text-container">
              <span className="period-to-closed__prompt">{_t('open_periods_{amount}', { amount: data.length })}</span>
              <Divider />
              {data.map((item: any) => {
                return (
                  <div className="period-to-closed__periods-container">
                    <div>
                      <span className="period-to-closed-period">{_t('period_to_close')}<strong>{item.monthName}</strong></span>
                    </div>
                    <div className="period-to-closed__date-container">
                      <span className="period-to-closed__text">{_t('from')}</span>
                      {item.dateFrom}—
                      <span className="period-to-closed__text">{_t('to')}</span>
                      {item.dateTo}
                      <span className="period-to-closed-opened">{_t('opened')}</span>
                    </div>
                    <Divider />
                  </div>
                )
              })}

            </div>
          </div>
        )
        : 
          <div className="not-closed__container">
      <Warning32 className="not-closed__icon"/>
      <div className="not-closed__text-container">
        <span className="not-closed__prompt">Theare are not available open periods by your filters</span>
        <span className="not-closed__prompt-secondary">Please change filter params and try again</span>
      </div>
    </div>
    }

    </>
  );
};

export default PeriodsToClose;

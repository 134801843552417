import React, { useEffect, useMemo, useRef, useState } from "react";
import { Calendar as SingleCalendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { format, parseISO } from "date-fns";
import { useOutsideClick } from "system/helpers/hooks"; // theme css file
import Input from "components/atoms/Input";

const Calendar = ({
  value,
  onChange,
  name,
  fromName,
  toName,
  onFormValueChange,
  dateName,
  dateFormat,
  labelText,
  placeholder,
  openLeft = false,
  minDate,
  maxDate,
  ...rest
}: any) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);

  const checkDate = (date: string) => {
    const parsedDate: any = parseISO(date);
    return parsedDate != "Invalid Date" ? parsedDate : new Date();
  };
  const handlerChange = (val: any) => {    
    const date = val["selection"].date;
    onChange(format(date, dateFormat), `${name}`);
  };

  const data = useMemo(() => {    
    return checkDate(value)
  }, [value, dateName]);
  
  useEffect(() => {
    setShow(false);
  }, [clickOutSide]);

  return (
    <div className={"date-range"} ref={ref}>
      <div onClick={() => setShow(!show)}>
        <Input
          value={value ? `${format(data, dateFormat)}` : ''}
          onChange={() => { }}
          labelText={labelText !== "Date range" ? labelText : ''}
          placeholder={placeholder}
          isCalendar={!!placeholder}
          {...rest}
        />
      </div>
        {show &&
        <div className="date-range__body" style={openLeft ? {right: 0} : {}}>
          <SingleCalendar
            displayMode="dateRange"
            minDate={minDate}
            maxDate={maxDate}
            ranges={[
              {
                startDate: data,
                endDate: data,
                key: "selection",
              },
            ]}
            className="date-calendar"
            showMonthAndYearPickers={true}
            onChange={(item: any) => {              
              handlerChange({
                "selection": {
                  date: item,
                  key: "selection",
                }
              });
            }}
          />
          </div>}
    </div>
  );
};

export default Calendar;

const config = {
  configurationType: [
    {
      label: "Rolling reserve",
      value: "rollingReserve",
    },
    {
      label: "On hold",
      value: "onHold",
    },
    // {
    //   label: "Other fees",
    //   value: "otherFees",
    // },
    {
      label: "Exchange Rate",
      value: "exchangeRate",
    },
    {
      label: "Calculation currency",
      value: "convertCurrency",
    },
  ],
  strategy: [
    {
      label: "Manual",
      value: false,
    },
    {
      label: "Automatic",
      value: true,
    },
  ],
  otherFees: {
    type: [
      {
        label: "Percentage, %",
        value: 'percentage',
      },
      {
        label: "Fixed",
        value: 'fixed',
      }
    ],
    applyTo: [
      {
        label: "Deposit",
        value: 'deposit',
      },
      {
        label: "Withdrawal",
        value: 'withdrawal',
      },
      {
        label: "Total balance",
        value: 'totalBalance',
      }
    ],
    calculationTime: [
      {
        label: "Period start",
        value: 'periodStart',
      },
      {
        label: "Period from",
        value: 'periodFrom',
      },
    ]
  },
  exchangeRate: {
    rateApplication: [
      {
        label: "Daily",
        value: 'DAILY',
      },
      {
        label: "Monthly Avarage",
        value: 'MONTHLY',
      },
    ]
  }

};

export default config;

import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { format } from "date-fns";
import { useModuleData } from "./hooks";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("merchant"),
        id: "merchantName",
        accessor: "merchantName",
        width: 200,
        mobileVisible: true,
        isMobileTitle: true,
      },
      {
        Header: _t("payment_provider"),
        id: "paymentGateway",
        accessor: "paymentGateway",
        mobileVisible: true,
      },
      {
        Header: _t("values_name"),
        id: "fieldName",
        accessor: "fieldName",
        mobileVisible: true,
      },
      {
        Header: <div className="mb--cell">{_t("values_before_change")}</div>,
        id: "valueBefore",
        accessor: "valueBefore",
        mobileVisible: true,
      },
      {
        Header: <div className="mb--cell">{_t("values_after_change")}</div>,
        id: "valueAfter",
        accessor: "valueAfter",
        mobileVisible: true,
      },
      {
        Header: _t("author"),
        id: "updatedBy",
        accessor: "updatedBy",
      },
      {
        Header: _t("date"),
        id: "updatedDate",
        accessor: "updatedDate",
        width: 120,
        Cell: ({row}: any) => {          
          return <div className="mb--cell mb--cell-text">{format(new Date(row?.original.updatedDate.split('Z')[0]), "dd/MM/yyyy HH:mm:ss")}</div>
        }
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { merchantsOptions, paymentGatewaysOptions } = useModuleData();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchantId({
        componentProps: {
          items: merchantsOptions,
        },
      }),
      fields.filters.paymentGateway({
        name: 'paymentGateway',
        componentProps: {
          items: paymentGatewaysOptions,
          label: _t("payment_provider"),
          withAsteriskOnSelectAll: true
        },
      }),
      fields.filters.date({
        emptySpace: {},
        componentProps : {
          openLeft: true,
        },
        columnParams: {
          className: "maxWidthContent" 
        },
      }),
      fields.general.submitBtn(),
    ];
  }, [merchantsOptions, paymentGatewaysOptions]);
  return formConfig;
};


import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { API_GET_PAYMENT_INSTRUMENT, API_UPDATE_PAYMENT_INSTRUMENT } from "system/api/apiUrls";
import { useApiRequest } from "system/api/hooks";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { useTrans } from "system/translations/hooks";
import { PaymentInstrumentDataType } from "./types";

const getPaymentInstrumentsKey = "getPaymentInstrumentsKey";

export const useGetPaymentInstruments = (filter = {}, params: any = {}) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getPaymentInstrumentsKey, filter],
    (): Promise<PaymentInstrumentDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_PAYMENT_INSTRUMENT, filter),
        method: "get",
      }),
    { enabled: params.enabled }
  );
};

export const useUpdatePaymentInstrument = () => {
  const { _t } = useTrans()
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_PAYMENT_INSTRUMENT, {
          id: data.id,
          status: data.status
        }),
        method: "put",
        data: {},
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPaymentInstrumentsKey);
        apiSuccessMessage(_t("request_to_change_status_sent"));
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
import React from "react";
import Create from "modules/gateWayWhiteList/components/Create";

const GateWayWhiteListCreatePage = () => {
  return (
    <div>
      <Create />
    </div>
  );
};

export default GateWayWhiteListCreatePage;

import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const createEditValidation = (_t: TransType, params: any) => {
  return Yup.object().shape({
    /*  key: Yup.string().required(_t("required")),*/
  });
};

export const createEditSlackChannelsValidation = (_t: TransType) => {
  return Yup.object().shape({
    channels: Yup.array().of(Yup.object().shape({
      channelName: Yup.string().required(_t('required')).typeError(_t("must_be_a_string"))
    }))
  });
};

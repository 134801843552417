import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateMerchantWhiteList } from "modules/merchantWhiteList/apiHooks";
import { createEditValidation } from "modules/merchantWhiteList/validation";
import { useCreateEditConfig } from "modules/merchantWhiteList/constantHooks";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onCreate } = useCreateMerchantWhiteList(
    routeParams?.merchantId
  );
  const formConfig = useCreateEditConfig({
    isEdit: false,
  });
  const onSubmit = async (data: any) => {
    onCreate(data);
  };

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_merchant_whitelist")}>
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                submitBtnLabel: _t("create"),
                onSubmit: onSubmit,
                initialValues: {},
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

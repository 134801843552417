import React from "react";
import Update from "modules/merchantWhiteList/components/Update";

const MerchantWhiteListUpdatePage = () => {
  return (
    <div>
      <Update />
    </div>
  );
};

export default MerchantWhiteListUpdatePage;

import React, { useMemo } from "react";
import DescriptionList from "components/molecules/DescriptionList";
import {
  useChangeCustomerStatus,
  useGetClient,
} from "modules/transactions/apiHooks";
import Loader from "components/atoms/Loader";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { ClientDataType } from "modules/transactions/types";
import Toggle from "components/atoms/Toggle";

const CustomerDetails = ({ id }: any) => {
  const { data, isLoading } = useGetClient(id);
  const { mutate: onUpdateStatus, isLoading: isUpdatingCustomer } =
    useChangeCustomerStatus();
  const onStatusChange = (val: any, allData: any) => {
    let data: any = {
      id: allData.id,
      verified: val,
    };
    onUpdateStatus(data);
  };
  const listData: any = useMemo(() => {
    const res = [];
    let key: keyof ClientDataType;
    for (key in data) {
      if (key === "status") {
        res.push({
          title: "Verified(status)",
          value: (
            <Toggle
              onChange={(val) => onStatusChange(val, data)}
              value={data?.["status"] === "TRUSTED"}
            />
          ),
          dontConvertToString: true,
        });
      } else {
        res.push({ title: [key], value: data?.[key] });
      }
    }
    return res;
  }, [data]);
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return <DescriptionList list={listData} />;
};

const CustomerDetailsWrapped = (props: any) => {
  return (
    <RoleWrapper accessTo={"ROLE_VIEW_TRANSACTION_CLIENT"}>
      <CustomerDetails {...props} />
    </RoleWrapper>
  );
};

export default CustomerDetailsWrapped;

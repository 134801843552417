import React from "react";

const CustomTooltipItem: React.FC<{
  name: string,
  value: number,
  color: string,
  isQuantity?: boolean 
}> = ({ name, value, color, isQuantity = false }) => {
  return (
    <div className="custom-tooltip__item">
      <div className="custom-tooltip__item-name">
        {isQuantity
          ? (
            <div className="quantity-icon" style={{ backgroundColor: color }}>
              <div style={{ backgroundColor: color }}></div>
            </div>
          )
          : (
            <div style={{
              width: "18px",
              height: "10px",
              borderRadius: "2px",
              backgroundColor: color
            }}></div>
          )
        }
        {name + ': '}
      </div>
      <div>{value}</div>
    </div>
  );
};

export default CustomTooltipItem;

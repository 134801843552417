import { useContext } from "react";
import ModalContext from "./ModalContext";

export const useModal = () => {
  const defaultModal =  "default";
  const modal: any = useContext(ModalContext);
  const showModal = (params: {}, name = defaultModal) =>
    modal.showModal(params, name)
  const updateModal = (params: {}, name = defaultModal) =>
    modal.updateModal(params, name);
  const hideModal = (name = defaultModal) => modal.hideModal(name);

  return { showModal, hideModal, updateModal };
};

import React from "react";
import { MenuFullScreenProps } from "./types";
import { Accordion, AccordionItem } from "carbon-components-react";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

import "styles/modules/mobileMenu/menuFullScreen.scss"

const MenuFullScreen: React.FC<MenuFullScreenProps> = ({
  menuItems,
  linkHandler
}) =>( 
  <ul className="menu-full-screen">
    {menuItems.map((item, index: number) =>
      item.submenu 
      ? (
          <RoleWrapper key={index + ""} accessTo={item?.accessTo}>
            <li key = {index + ""} className = "menu-with-sub-full-screen">
              <item.icon/>
              <Accordion>
                <AccordionItem
                  key={index + ""}
                  title={item.title}
                >
                    {item.submenu.map((subItem: any, subindex: number) => (
                      <RoleWrapper key={`${index + "-subItem-" + subindex}`} accessTo={subItem?.accessTo}>
                        <li
                          key={subindex}
                          onClick={(e: any) => {
                            linkHandler(subItem.link);
                          }}
                        >
                          <span>{subItem.title}</span>
                        </li>
                      </RoleWrapper>
                    ))}
                </AccordionItem>
              </Accordion>
            </li>
          </RoleWrapper>
        ) 
      : (
          <RoleWrapper key={index + ""} accessTo={item.accessTo}>
            <li
              key={index + ""}
              className = "menu-item-full-screen"
              onClick={(e: any) => {
                linkHandler(item.link);

              }}
            >
              <item.icon/>
              <span>{item.title}</span>
            </li>
          </RoleWrapper>
        )
    )}
  </ul>
)


export default MenuFullScreen;
import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import {
  useGetCredentialTemplate,
  useUpdateCredential,
} from "modules/credentials/apiHooks";
import { createEditValidation } from "modules/credentials/validation";
import { useCreateEditConfig } from "modules/credentials/constantHooks";
import { useModifyCredentialTemplate } from "modules/credentials/hooks";
import Loader from "components/atoms/Loader";

const Update: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useUpdateCredential(routeParams?.credentialId);
  const { data, isLoading } = useGetCredentialTemplate({
    credentialId: routeParams?.credentialId,
    id: routeParams?.id,
  });
  const templateData = useModifyCredentialTemplate(data, routeParams.id);
  const formConfig = useCreateEditConfig(templateData);
  const onSubmit = (data: any) => {
    onUpdate({ data: data, id: routeParams?.credentialId });
  };
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={`${_t("update")} ${routeParams?.credentialId}`}>
            <FormBuilder
              formItemsConfig={formConfig}
              formProps={{
                submitBtnLabel: _t("update"),
                onSubmit: onSubmit,
                initialValues: templateData,
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

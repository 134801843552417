import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MONITORING_MERCHANT,
  API_CREATE_MONITORING_MERCHANT,
  API_DELETE_MONITORING_MERCHANT,
  API_UPDATE_MONITORING_MERCHANT,
  API_GET_MONITORING_MERCHANT_ITEM,
  API_GET_MONITORING_MERCHANT_ALL,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  MonitoringMerchantDataType,
  MonitoringMerchantItemType,
} from "modules/monitoringMerchant/types";

const getMonitoringMerchantKey = "getMonitoringMerchantKey";
const getMonitoringMerchantItemKey = "getMonitoringMerchantItemKey";
export const useGetMonitoringMerchant = (filters: any = {}) => {  
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getMonitoringMerchantKey, filters],
    (): Promise<MonitoringMerchantDataType> =>
      apiRequest({
        url: filters.merchantId
          ? generatePath(
              generateUrlQuery(API_GET_MONITORING_MERCHANT, filters),
              { merchantId: filters.merchantId }
            )
          : generateUrlQuery(API_GET_MONITORING_MERCHANT_ALL, filters),
        method: "get",
      }),
  );
};
export const useGetMonitoringMerchantItem = (id: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMonitoringMerchantItemKey, id],
    (): Promise<MonitoringMerchantItemType> =>
      apiRequest({
        url: generatePath(API_GET_MONITORING_MERCHANT_ITEM, { id }),
        method: "get",
      })
  );
};

export const useCreateMonitoringMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MONITORING_MERCHANT,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringMerchantKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteMonitoringMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MONITORING_MERCHANT, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringMerchantKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateMonitoringMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MONITORING_MERCHANT, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringMerchantKey);
        queryClient.invalidateQueries(getMonitoringMerchantItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_PENDING_TRANSACTIONS_MONITORING,
  API_CREATE_PENDING_TRANSACTIONS_MONITORING,
  API_DELETE_PENDING_TRANSACTIONS_MONITORING,
  API_UPDATE_PENDING_TRANSACTIONS_MONITORING,
  API_GET_PENDING_TRANSACTIONS_MONITORING_ITEM,
  API_GET_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  GatewaysDataType,
  PendingTransactionsMonitoringDataType,
  PendingTransactionsMonitoringItemType,
} from "modules/pendingTransactionsMonitoring/types";

const getPaymentGatewayCodes = "getPaymentGatewayCodes";
const getPendingTransactionsMonitoringKey = "getPendingTransactionsMonitoringKey";
const getPendingTransactionsMonitoringItemKey = "getPendingTransactionsMonitoringItemKey";
export const useGetPendingTransactionsMonitoring = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPendingTransactionsMonitoringKey, filters],
    (): Promise<PendingTransactionsMonitoringDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_PENDING_TRANSACTIONS_MONITORING, filters),
        method: "get",
      })
  );
};
export const useGetPendingTransactionsMonitoringItem = (id: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPendingTransactionsMonitoringItemKey, id],
    (): Promise<PendingTransactionsMonitoringItemType> =>
      apiRequest({
        url: generatePath(API_GET_PENDING_TRANSACTIONS_MONITORING_ITEM, { id }),
        method: "get",
      })
  );
};

export const useCreatePendingTransactionsMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_PENDING_TRANSACTIONS_MONITORING,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPendingTransactionsMonitoringKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeletePendingTransactionsMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_PENDING_TRANSACTIONS_MONITORING, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPendingTransactionsMonitoringKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdatePendingTransactionsMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_PENDING_TRANSACTIONS_MONITORING, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPendingTransactionsMonitoringKey);
        queryClient.invalidateQueries(getPendingTransactionsMonitoringItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetPaymentGateways = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewayCodes],
    (): Promise<any> =>
      apiRequest({
        url: API_GET_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES,
        method: "get",
      })
  );
};

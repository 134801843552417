import { Column as ColumnType } from "react-table";
import { Link } from "carbon-components-react";
import { ChevronDown16, ChevronUp16 } from "@carbon/icons-react";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "modules/formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("transactionUUID"),
        width: 250,
        id: "transactionUUID",
        mobileVisible: true,
        Cell: ({ isExpanded, onExpand, row, showDetails, ...rest }: any) => {
          return (
            <span className={"cell-wrapper"}>
              <span className={"cell-wrapper__accordion"} onClick={onExpand}>
                {isExpanded ? <ChevronUp16 /> : <ChevronDown16 />}
              </span>
              <Link
                onClick={onExpand}
              >
                {row?.original?.transactionUUID}
              </Link>
            </span>
          );
        },
      },
      {
        Header: _t("merchant"),
        accessor: "merchantName",
        id: "merchantName",
        mobileVisible: true,
      },
      {
        Header: _t("event_type"),
        accessor: "status",
        id: "status",
        mobileVisible: true,
      },
      {
        Header: _t("event_type"),
        accessor: "recordType",
        id: "recordType",
        mobileVisible: true,
      },
      {
        Header: _t("transaction_status_before"),
        accessor: "transactionStatusBefore",
        id: "transactionStatusBefore",
        mobileVisible: true,
      },
      {
        Header: _t("transaction_status_after"),
        accessor: "transactionStatusAfter",
        id: "transactionStatusAfter",
        mobileVisible: true,
      },
      {
        Header: _t("transaction_incoming_id"),
        accessor: "transactionIncomingId",
        id: "transactionIncomingId",
        mobileVisible: true,
      },
      {
        Header: _t("updated_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        mobileVisible: true,
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.request({
        columnParams: {
          md: 6,
          lg: 6,
        },
      }),
      /*      {
        component: Search,
        name: "request",
        componentProps: {
          labelText: _t("search"),
          placeHolderText: _t("search"),
          SearchIconDescription: _t("search"),
          showSearch: true,
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
      },*/
    ];
  }, []);
  return formConfig;
};

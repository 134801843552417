import React from "react";
import { useParams } from "react-router-dom";
import { Button, Column, Grid, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import {
  useGetRole,
  useGetRolesAuthorities,
  useUpdateRole,
} from "modules/roles/apiHooks";
import { createEditValidation } from "modules/roles/validation";
import { useRoleInitialData } from "modules/roles/hooks";
import { modifyCreateUpdateReqData } from "modules/roles/helper";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/roles/constantHooks";
import { useDeleteWithConfirm } from "modules/roles/hooks";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { data: rolesAuthorities = [] } = useGetRolesAuthorities();
  const { data: roleData, isLoading } = useGetRole(routeParams?.id);
  const { mutate: onUpdate } = useUpdateRole();
  const formConfig = useCreateEditConfig({
    rolesAuthorities,
  });
  const { initialAuthorities } = useRoleInitialData(rolesAuthorities, roleData);
  const onSubmit = async (data: any) => {
    const reqData = modifyCreateUpdateReqData(data);
    onUpdate({ data: reqData, id: routeParams?.id });
  };

  const onDeleteWithConfirm = useDeleteWithConfirm(true)
  
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 12, offset: 2 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card
            title={_t("edit_role")}
            extra={<Button kind={"tertiary"}
            onClick = {() => onDeleteWithConfirm({id: roleData?.id})} 
          >
            {_t("deactivate_role")}
          </Button>}
          >
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                onSubmit: onSubmit,
                initialValues: {
                  name: roleData?.name,
                  description: roleData?.description,
                  authorities: initialAuthorities,
                },
                validationSchema: createEditValidation(_t, {
                  isEdit,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

import { useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { API_GET_REQUEST_HISTORY } from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";

const getRequestHistoryKey = "getRequestHistoryKey";

export const useGetRequestHistory = (filters: any = {}, params: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getRequestHistoryKey, JSON.stringify(filters)],
    () =>
      apiRequest({
        url: generateUrlQuery(API_GET_REQUEST_HISTORY, filters),
        method: "get",
      }),
    { enabled: params.enabled }
  );
};

import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { useGetPaymentGateways, useGetPaymentMethods } from "modules/payment/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UseModuleDataRes } from "./types";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentMethods = [] } = useGetPaymentMethods();
  const { data: paymentGateways = [] } = useGetPaymentGateways();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const paymentMethodsOptions = arrayOptionsMap(paymentMethods, {
    labelKey: "label",
    valueKey: "value",
  });
  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });
  
  return { merchantsOptions, paymentGatewaysOptions, paymentMethodsOptions };
};

import React from "react";
import { CardProps } from "./types";
import { useLocation } from "react-router-dom";
import Card from "./Card";

const CardWrapper: React.FC<CardProps> = ({
  children,
  title,
  subtitle,
  extra,
  withoutFilter,
  backLocation,
  titleClassName = '',
}) => {
  const location: any = useLocation();
  const back = backLocation 
    ? backLocation 
    : location?.state
      ? location?.state?.pathname 
      : location.pathname.split('/').filter((item: any) => item !== '').length <= 1 
        ? undefined
        : "/" + location.pathname.split('/')[1];        
  return (
    <Card
      withoutFilter={withoutFilter}
      backLocation={back}
      extra={extra}
      title={title}
      subtitle={subtitle}
      titleClassName={titleClassName}
    >
      {children}
    </Card>
  );
};

export default CardWrapper;

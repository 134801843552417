import React, { useMemo } from "react";
import DescriptionList from "../../../components/molecules/DescriptionList";
import { useGetMerchant } from "../../merchants/apiHooks";
import Loader from "components/atoms/Loader";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { MerchantDataType } from "modules/merchants/types";

const MerchantDetails = ({ id }: any) => {
  const { data, isLoading } = useGetMerchant(id);
  const listData: any = useMemo(() => {
    const res = [];
    let key: keyof MerchantDataType;
    for (key in data) {
      res.push({ title: [key], value: data?.[key] });
    }
    return res;
  }, [data]);
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return <DescriptionList list={listData} />;
};

const MerchantDetailsWrapped = (props: any) => {
  return (
    <RoleWrapper accessTo={"ROLE_VIEW_MERCHANT_DETAILS"}>
      <MerchantDetails {...props} />
    </RoleWrapper>
  );
};

export default MerchantDetailsWrapped;

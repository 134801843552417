import { useGetCurrentAdmin } from "modules/auth/apiHooks";
import { AccessToType } from "system/helpers/types";

export const usePermissionAccess = () => {
  const { data } = useGetCurrentAdmin();
  /*  const data = {
      authorities: [
        "ROLE_MODIFY_SUBMERCHANTS",
        "ROLE_VIEW_MERCHANTS",
        "ROLE_VIEW_SUBMERCHANT",
        "ROLE_MODIFY_SETTINGS",
        "ROLE_VIEW_MERCHANT_DETAILS",
      ],
    };*/
  const isSuperAdmin = data?.authorities?.includes("ROLE_SUPERADMIN");
  const checkPermission = (accessTo: AccessToType): boolean => {
    if (!!accessTo && data?.authorities) {
      if (Array.isArray(accessTo)) {
        let res = false;
        accessTo.forEach((item) => {
          if (data?.authorities.includes(item)) {
            res = true;
          }
        });
        return res;
      } else {
        return data?.authorities.includes(accessTo);
      }
    }
    return true;
  };

  return { isSuperAdmin, checkPermission };
};

import React from "react";
import { Column } from "carbon-components-react";
import classNames from "classnames";

const FieldItem = ({
  emptySpace,
  columnParams: { className, style, ...rest },
  Component,
  additionalProps,
  name,
  componentProps,
  error,
  onBlur,
  onChange,
  formik,
  formProps,
  getNestedValue,
  ...restComponentProps
}: any) => {
  return (
    <>
      {emptySpace && <Column {...emptySpace} />}
      <Column
        style={style}
        {...rest}
        className={classNames("bx--form__item", className && className)}
      >
        <Component
          id={name}
          {...componentProps}
          {...additionalProps}
          {...restComponentProps}
          name={name}
          value={getNestedValue(formik.values, name)}
          invalid={!!error}
          invalidText={error}
          onBlur={() => onBlur(name)}
          onFormValueChange={(fieldName: string, value: any) =>
            onChange(fieldName, value)
          }
          onChange={(value: any, directFieldName?: string) => {
            const fieldName: string = directFieldName || name;
            onChange(fieldName, value);
            formProps.onFormValueChange &&
              formProps.onFormValueChange(fieldName, formik);
            componentProps.onFieldChange &&
              componentProps.onFieldChange(value, formik.values, {
                setFieldValue: formik.setFieldValue,
                setFieldTouched: formik.setFieldTouched,
              });
          }}
        >
          {componentProps.children}
        </Component>
      </Column>
    </>
  );
};

export default FieldItem;

import React, { useEffect, useState } from "react";
import { Button, Column, Grid, Row } from "carbon-components-react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Loader from "components/atoms/Loader";
import {
  useCreateManualRate,
  useDeleteMerchantsConfigurationsManualRateItem,
  useGetManualRates,
  useGetMerchantsConfigurationsItem,
  useUpdateMerchantsConfigurationsItem,
  useUpdateMerchantsConfigurationsManualRate
} from "../apiHooks";
import { useEditConfig, useTableExchangeRates } from "../constantHooks";
import Table from "modules/table";
import { Add20, RecentlyViewed16, Save16, TrashCan16 } from "@carbon/icons-react";
import { useConfirmModal } from "modules/modal/predefinedModals";
import Divider from "components/atoms/Divider";
import { useModal } from "modules/modal/hooks";
import { APP_ROUTES } from "system/router/constants";
import { useGetFieldNamesManualRate, useGetWarningText } from "../hooks";
import { createEditValidation } from "../validation";
import { useGetErrorToaster } from "system/helpers/hooks";

const Update: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { data, isLoading } = useGetMerchantsConfigurationsItem(+routeParams.id);
  const [configurationType, setConfigurationType] = useState('rollingReserve')
  const [newManualRates, setNewManualRates] = useState<any>([])
  const { mutate: onUpdate , data: dataMutate, isLoading: isUpdateLoading } = useUpdateMerchantsConfigurationsItem();
  const { mutate: onCreate } = useCreateManualRate();
  const { mutate: onDelete } = useDeleteMerchantsConfigurationsManualRateItem();
  const { mutate: updateManualRate } = useUpdateMerchantsConfigurationsManualRate()
  const columns = useTableExchangeRates()
  const formConfig = useEditConfig(data, configurationType, setConfigurationType);
  const { data: manualRates, isLoading: manualRatesLoading } = useGetManualRates(+routeParams.merchantId, routeParams.paymentGateway)
  const { onConfirm } = useConfirmModal();
  const { showModal, updateModal } = useModal()
  const { openWarning } = useGetWarningText()
  const history = useHistory();
  const fieldNamesManualRate: any = useGetFieldNamesManualRate(_t)
  const { showErrorToastMessage } = useGetErrorToaster()
  
  useEffect(() => {
    if (manualRates && newManualRates && (manualRates.length < newManualRates.length) && !newManualRates[0].id ) {
      setNewManualRates([newManualRates[0],...manualRates])
    } else {
      setNewManualRates(manualRates)
    }
  }, [manualRates])

  useEffect(() => {
    if (dataMutate) {
      const link = generatePath(APP_ROUTES.merchantsBalanceV2.merchantConfiguration.update, {
        merchantId: dataMutate.merchantId,
        paymentGateway: dataMutate.paymentGatewayCode,
        id: dataMutate.id
      });
      history.replace(link);
    }
  }, [dataMutate])


  useEffect(()=> {
    updateModal(
      {
        componentProps: {
          columns,
          isLoading: manualRatesLoading,
          data: newManualRates,
          cellProps: {
            onUpdateCreatedManualRate,
            actionMenuItems,
            onUpdateManualRate
          }
        },
        modalHeading: _t("manual_rates"),
        component: Table,
        footer: Button,
        footerProps: {
          style: {
            display: 'none'
          }
        }
      }, 'manualRates'
    );
  }, [newManualRates])

  const newManualRate = {
    merchantId: +routeParams.merchantId,
    paymentGateway: routeParams.paymentGateway,
    currencyFrom: null,
    currencyTo: null,
    rate: null,
    rateCorrelation: null,
    dateFrom: null,
    dateTo: null,
  }
  const addManualRate = () => {
    if (manualRates.length === newManualRates.length) {
      setNewManualRates([newManualRate, ...manualRates])
    }
  }

  const onUpdateManualRate = async (data: any) => {
    updateManualRate(data)
  }

  const onUpdateCreatedManualRate = async (data: any) => {
    newManualRates[0] = data.data
    setNewManualRates([...newManualRates])
    const requiredValuesTocheck = {
      ...newManualRates[0]
    }
    delete requiredValuesTocheck.rateCorrelation
    
    Object.values(requiredValuesTocheck).every((item: any) => item) && onCreate(newManualRates[0])
  }

  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => !id ? setNewManualRates(newManualRates.slice(1)) : onDelete({ id }) });
  };

  const actionMenuItems = [
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];

  if (isLoading || isUpdateLoading) {
    return <Loader formOverlay />;
  }

  const showErrorForNotUpdatedForm = (formData: any, isRatesAreNotFilled: boolean = false) => {
    if (JSON.stringify(data) === JSON.stringify(formData) && configurationType !== 'exchangeRate') {
      showErrorToastMessage(_t('no_changes_to_save_try_again'))
      return true
    } else if(JSON.stringify(data) === JSON.stringify(formData) && isRatesAreNotFilled) {
      showErrorToastMessage(_t('no_changes_to_save_try_again'))
      return true
    }
    return false
  }

  const onSubmit = async (formData: any) => {
    const normalize = { ...formData }
    delete normalize.configurationType
    delete normalize.activeConvertCurrency

   if (showErrorForNotUpdatedForm(normalize, manualRates && newManualRates && (manualRates.length === newManualRates.length) && configurationType === 'exchangeRate')) {
    return
   }
    if (manualRates && newManualRates && (manualRates.length < newManualRates.length) && configurationType === 'exchangeRate') {
      const fieldsToCheck = {...newManualRates[0]}
      delete fieldsToCheck.rateCorrelation

      const lostFields = Object.keys(fieldsToCheck).filter((key: any) => !fieldsToCheck[key]).map((item: string) => fieldNamesManualRate[item])
      onConfirm({ 
        onOk: () => {
          if (showErrorForNotUpdatedForm(normalize, true)) {
            return
           }
          onUpdate(normalize)
        },
        modalHeading: _t('warning'),
        onOkText: _t('save'),
        onCancelText: _t('back_to_edit'),
        onOkKind: 'primary',
        renderIcon: Save16,
        bodyText: _t("merchant_configuration_exchange_warning_{lostFields}", {
          lostFields: lostFields.join(', ')
        })      
       });
    } else {
      !!normalize.convertCurrency && !data?.convertCurrency
        ? openWarning(() => onUpdate(normalize)) 
        : onUpdate(normalize)
    }
    
  };

  const showAllManualRates = () => {
    showModal(
      {
        componentProps: {
          columns,
          isLoading: manualRatesLoading,
          data: newManualRates,
          cellProps: {
            onUpdateCreatedManualRate,
            actionMenuItems,
            onUpdateManualRate
          }
        },
        modalHeading: _t("manual_rates"),
        component: Table,
        footer: Button,
        footerProps: {
          style: {
            display: 'none'
          }
        }
      }, 'manualRates'
    );
  };

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("{title_name}", {
            title_name: `${data?.merchantName || ''}: ${data?.paymentGatewayName || ''}`,
          })}
            titleClassName='card__head__title-break-words'
          >
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("save_changes"),
                onSubmit: onSubmit,
                initialValues: {...data, 'activeConvertCurrency': !!data?.convertCurrency, configurationType},
                validationSchema: createEditValidation(_t, {
                  isEdit: true,
                }),
              }}
            />
            {configurationType === 'exchangeRate' && (
              <>
                <Divider />
                <div className="form-item__table-header-container">
                  {_t("manual_rates")}
                  <div className="form-item__table-header-add-btn" onClick={addManualRate}>
                    <Add20 /> {_t("set_a_new_manual_rate")}
                  </div>
                </div>
                <Table
                  columns={columns}
                  isLoading={manualRatesLoading}
                  data={newManualRates.slice(0,5)}
                  cellProps={{
                    onUpdateCreatedManualRate,
                    actionMenuItems,
                    onUpdateManualRate
                  }}
                />
                {newManualRates.length > 5 &&
                  <>
                    <Divider />
                    <div className="history-link" onClick={showAllManualRates}>
                      <RecentlyViewed16 style={{ color: 'black' }} />
                      <div className="history-link__text">{_t("view_all_rates")} </div>
                    </div>
                  </>
                }
              </>
            )}

          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

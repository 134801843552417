import React from "react";
import FormBuilder from "../../formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useCreateMerchant, useGetMerchant } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { createEditValidation } from "../validation";
import { useCreateEditConfig } from "../constantHooks";
import { useParams } from "react-router-dom";
import Loader from "components/atoms/Loader";
import { getInitialValuesForCreate } from "../constants";
import { usePrepareDataForMerchantIpn } from "../hooks";

const CreateSubMerchant: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onCreate } = useCreateMerchant();
  const { data: merchantData, isLoading } = useGetMerchant(routeParams?.id);
  const { normalizeDataBeforeSave } = usePrepareDataForMerchantIpn()
  const formConfig = useCreateEditConfig({});
  const onSubmit = async (data: any) => {
    onCreate(normalizeDataBeforeSave(data));
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }
  if (merchantData?.disable) {
    return <>Parent merchant DEACTIVATED/Please, contact support</>;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                onSubmit: onSubmit,
                initialValues: getInitialValuesForCreate({
                  isSubMerchant: true, 
                  additionalData: {
                    parentId: routeParams?.id,
                    secretKeyV2: null,
                    country: merchantData?.country
                  }
                }),
                validationSchema: createEditValidation(_t, {
                  isEdit,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default CreateSubMerchant;

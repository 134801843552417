import { Column as ColumnType } from "react-table";
import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useModuleData } from "./hooks";
import Input from "components/atoms/Input";
import Toggle from "components/atoms/Toggle";
import JsonOrInput from "components/molecules/JsonOrInput";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import {
  useGetCredential,
  useGetCredentials,
} from "modules/credentials/apiHooks";
import Select from "components/atoms/Select";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { ArrayOptionsMapRes } from "system/helpers/types";
import { useParams } from "react-router-dom";
import { TableColumnType } from "modules/table/types";
import { Link } from "carbon-components-react";
import CopyButtonWrapper from "components/atoms/CopyButtonWrapper";

export const useTableColumns = (data: any[], params: any): TableColumnType[] => {
  const { _t } = useTrans()
  const dataColumns: any = useMemo(
    () =>
      data.length
        ? Object.keys(data?.[0]).map((item: any, idx) => {
            if (typeof Object.values(data?.[0])[idx] === "object") {
              return {
                Header: item,
                id: item,
                mobileVisible: true,
                isMobileTitle: item === "code" || item === "iSettleCode" || item === "key"
                  || item === "codeLiteral" || item === "code3" || item === "className",
                Cell: (props: any) => {
                  return JSON.stringify(props?.row?.original?.[item]);
                },
              };
            }
            return {
              Header: item,
              id: item,
              mobileVisible: true,
              isMobileTitle: item === "code" || item === "iSettleCode" || item === "key"
                || item === "codeLiteral" || item === "code3" || item === "className",
              Cell: (props: any): any => {
                if (params.settingId === 'paymentGWs' && item === "code") {
                  return (
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        const propsToOpenUpdate = {
                          id: props?.row?.original.id,
                          allData: props?.row?.original,
                        }
                        
                        if (e.ctrlKey || e.metaKey) {
                          window.open(props.createLink(propsToOpenUpdate), "_blank")
                        } else {
                          props.onUpdate(propsToOpenUpdate);
                        }
                      }}
                    >
                    {`${props?.row?.original?.[item]}`}
                  </Link>
                  )
                } else if (params.settingId === 'paymentGWs' && item === "notificationUrl") {
                  return (
                    <div style={{ display: "flex", gap: "4px" }}>
                    <CopyButtonWrapper 
                        copyValue={props?.row?.original?.[item]}
                        toastMessage={_t('{item}_copied', {
                          item: 'notificationUrl'
                        })}
                        customStyles={{width: 'max-content'}}/>
                        {props?.row?.original?.[item]}
                    </div>
                  )
                }
                
                return `${props?.row?.original?.[item]}`;
              },
            };
          })
        : [],
    [data]
  );
  const additional =
    params.settingId === "cronJobs"
      ? [
          {
            maxWidth: 60,
            Header: "Job",
            mobileVisible: true,
            Cell: (props: any) => {
              return (
                <div key={"Toggle" + props?.row?.original?.id}>
                  <Toggle
                    key={"Toggle" + props?.row?.original?.id}
                    id={"Toggle" + props?.row?.original?.id}
                    value={props?.row?.original?.enabled}
                    onChange={() => props.onCronJob(props?.row?.original)}
                  />
                </div>
              );
            },
          },
        ]
      : [];
  const columns = useMemo(
    (): TableColumnType[] => [
      ...dataColumns,
      ...additional,
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    [data]
  );
  return columns;
};

export const useCreateEditConfig = (
  data: any[],
  params: any
): FormConfigType => {
  const { _t } = useTrans();
  const pgFormConfig = useCreateEditConfigPG({
    optionsData: params.optionsData,
  });
  const propertiesFormConfig = useCreateEditConfigProperties(data);
  const paymentMethodsFormConfig = useCreateEditConfigPM(data);
  const credentialsFormConfig = useCreateEditConfigCredentials(data, params);
  //settingId
  const dataFields = useMemo(
    () =>
      data
        ? Object.keys(data).map((item: any) => ({
            component: Input,
            name: item,
            componentProps: {
              labelText: _t(item),
              placeHolderText: _t(item),
            },
            columnParams: {
              lg: 16,
              md: 16,
            },
          }))
        : [],
    [data]
  );
  let formConfig;
  switch (params.settingId) {
    case "paymentGWs":
      formConfig = pgFormConfig;
      break;
    case "properties":
      formConfig = propertiesFormConfig;
      break;
    case "paymentMethods":
      formConfig = paymentMethodsFormConfig;
      break;
    case "credentials":
      formConfig = credentialsFormConfig;
      break;
    default:
      formConfig = dataFields;
  }
  return formConfig;
};
export const useCreateEditConfigProperties = (data: any[]): FormConfigType => {
  const { _t } = useTrans();
  const dataFields = useMemo(
    () =>
      data
        ? Object.keys(data).map((item: any) => {          
            const Component = item === "value" ? JsonOrInput : Input;
            return {
              component: Component,
              name: item,
              componentProps: {
                labelText: _t(item),
                placeHolderText: _t(item),
              },
              columnParams: {
                lg: 16,
                md: 16,
              },
            };
          })
        : [],
    [data]
  );
  const formConfig = useMemo((): FormConfigType => {
    return [...dataFields];
  }, [data]);
  return formConfig;
};
export const useCreateEditConfigPG = ({ optionsData }: any): FormConfigType => {
  const { _t } = useTrans();
  const { operationOptions } = useModuleData();  
  const paymentMethodsOptions = arrayOptionsMap(optionsData?.paymentMethods, {
    labelKey: "id",
    valueKey: "id",
  });
  const currenciesOptions = arrayOptionsMap(optionsData?.currencies, {
    labelKey: "id",
    valueKey: "id",
  });
  const credentialsOptions = arrayOptionsMap(optionsData?.credentials, {
    labelKey: "key",
    valueKey: "id",
  });
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo(
    () => [
      fields.formFields.code(),
      fields.formFields.name(),
      fields.formFields.operationType({
        componentProps: { 
          options: operationOptions,
          mobileModalFilterName: 'createUpdateItem'
        },
      }),
      fields.formFields.selectedPaymentMethods({
        componentProps: { 
          options: paymentMethodsOptions,
          mobileModalFilterName: 'createUpdateItem'
       },
      }),
      fields.formFields.selectedCurrencies({
        componentProps: { 
          options: currenciesOptions,
          mobileModalFilterName: 'createUpdateItem'
        },
      }),
      fields.formFields.selectedCredentials({
        componentProps: { 
          options: credentialsOptions,
          mobileModalFilterName: 'createUpdateItem'
        },
      }),
      // fields.formFields.priority(), 
      fields.formFields.json(),
      fields.formFields.description(),
    ],
    [optionsData]
  );
  return formConfig;
};
export const useCreateEditConfigCredentials = (
  { optionsData }: any,
  params: any
): FormConfigType => {
  const { _t } = useTrans();
  const { data = [], isLoading } = useGetCredentials();
  const [credential, setCredential] = useState("");
  const { data: credentialData } = useGetCredential(credential);
  const credentialsOptions = arrayOptionsMap(data, {
    labelKey: "type",
    valueKey: "type",
  });
  const formTemplateFields: any = useMemo(() => {
    const res: any = [];
    const formFields = credentialData?.[0];
    const restrictedFields = ["type", "updatedDate", "createdDate", "id"];
    if (formFields) {
      for (let key in formFields) {
        if (!restrictedFields.includes(key)) {
          res.push({
            component: Input,
            name: key,
            componentProps: {
              labelText: key,
              id: key,
            },
            columnParams: {
              lg: 16,
              md: 16,
            },
          });
        }
      }
    }
    return res;
  }, [credentialData]);
  const formConfig = useMemo(
    () => [
      {
        component: Select,
        name: "type",
        componentProps: (form: any) => {
          if (form?.values?.type) {
            setCredential(form?.values?.type);
          }
          return {
            labelText: _t("type"),
            items: credentialsOptions,
            disabled: params?.isEdit,
          };
        },
        columnParams: {
          md: 16,
          lg: 16,
        },
      },
      ...formTemplateFields,
    ],
    [optionsData, isLoading, credentialData, formTemplateFields]
  );
  return formConfig;
};

export const useCreateEditConfigPM = ({ optionsData }: any): FormConfigType => {
  const { countriesOptions } = useModuleData();  
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo(
    () => [
      fields.formFields.code(),
      fields.formFields.name(),
      fields.formFields.kycMaxAmount(),
      fields.formFields.maxAmount(),
      fields.formFields.countriesSupported({
        componentProps: { 
          options: countriesOptions, 
          mobileModalFilterName: 'createUpdateItem' 
      },
      }),
      fields.formFields.description(),
      fields.formFields.allCountries(),
      fields.formFields.defaultToggle(),
      fields.formFields.refundable(),
      fields.formFields.storedSupported(),
      fields.formFields.voucherAuto(),
    ],
    [optionsData, countriesOptions]
  );
  return formConfig;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans(); 
  const routeParams: any = useParams(); 

  const propertiesFilterOptions = [
    { label: _t("key"), value: "key" },
    { label: _t("value"), value: "value" },
    { label: _t("description"), value: "description" },
  ]

  const paymentMethodsFilterOptions = [
    { label: _t("code"), value: "code" },
    { label: _t("description"), value: "description" },
  ]

  const paymentGWsFilterOptions = [
    { label: _t("code"), value: "code" },
    { label: _t("description"), value: "description" },
    { label: _t("name"), value: "name" },
  ]

  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    let result: any = [];
    switch(routeParams?.settingId){
      case "properties":
        result = [
          fields.filters.filterWithSelectFieldForInput({
            componentProps: {
              showSearch: true,
              searchIconDescription: _t("search"),
              selectItems: propertiesFilterOptions,
              selectValue: propertiesFilterOptions[0],
            },
          }),
        ];
        break;
      case "paymentMethods":
        result = [
          fields.filters.filterWithSelectFieldForInput({
            componentProps: {
              showSearch: true,
              searchIconDescription: _t("search"),
              selectItems: paymentMethodsFilterOptions,
              selectValue: paymentMethodsFilterOptions[0],
            },
          }),
        ];
        break;
      case "paymentGWs":
        result = [
          fields.filters.filterWithSelectFieldForInput({
            componentProps: {
              showSearch: true,
              searchIconDescription: _t("search"),
              selectItems: paymentGWsFilterOptions,
              selectValue: paymentGWsFilterOptions[0],
            },
          }),
        ];
        break;
    }
    return result
  }, []);
  return formConfig;
};

import { TransType } from "system/translations/types";

export const filterOptions = (_t: TransType) => [
  { label: _t("uuid"), value: "transactionUUID" },
  { label: _t("merchant_order_no"), value: "referenceNo" },
  { label: _t("psp_order_no"), value: "gatewayTransactionId" },
];
export const expandedItems = (_t: TransType) => [
  { title: _t("payment_method"), accessor: "paymentMethod" },
  { title: _t("client"), accessor: "client.name" },
  { title: _t("merchant_client_id"), accessor: "client.merchantClientId" },
  { title: _t("country"), accessor: "client.country" },
];

import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../router/constants";
import { useToast } from "modules/toast/hooks";

export const useApiErrors = () => {
  const history = useHistory();
  const toastHandler = useToast();

  const apiErrors = (err: any, notify = true) => {
    const errMessage = err?.errorData?.message;

    const toastMessage = (msg: string) =>
      toastHandler.addToast({
        caption: msg,
        kind: "error",
      });

    if (!!err && err?.status === 401) {
      localStorage.removeItem("token");
      history.push(APP_ROUTES.login);
    }

    if (notify) {
      if (!!errMessage) {
        toastMessage(errMessage);
      } else {
        toastMessage("Some error occurred");
      }
    }
  };
  return { apiErrors };
};

import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import {
  useCreateTranslations,
  useGetTranslations,
} from "modules/translations/apiHooks";
import { createEditValidation } from "modules/translations/validation";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/translations/constantHooks";
import { useHistory } from "react-router-dom";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const history = useHistory()
  const { mutateAsync: onCreate } = useCreateTranslations();
  const { data: translationsData, isLoading } = useGetTranslations();
  const languages = translationsData?.[0].languages || [];
  const formConfig = useCreateEditConfig({ languages, isEdit: false });
  const onSubmit = async (data: any) => {
    const { key, ...rest } = data;
    const { status } = await onCreate({ data: rest, id: key });
    status === "APPROVED" && history.goBack();
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_translation")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                onSubmit: onSubmit,
                initialValues: {},
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateStatusReason } from "../apiHooks";
import { useCreateEditConfig } from "../constantHooks";
import { createEditValidation } from "../validation"

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { mutate: onCreate } = useCreateStatusReason();
  const formConfig = useCreateEditConfig();
  const onSubmit = (data: any) => {
      onCreate(data);
  };

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed 
      className="update-settings-content">
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_status_reason")}>
            <FormBuilder
              formItemsConfig={formConfig}
              formProps={{
                submitBtnLabel: _t("create"),
                onSubmit: onSubmit,
                initialValues: {},
                validationSchema: createEditValidation(_t),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

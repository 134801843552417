import { Column as ColumnType } from "react-table";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "modules/formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import DotsMenu from "components/atoms/DotsMenu";
import { CreateEditFormConfigParams } from "modules/translations/types";
import { TableColumnType } from "modules/table/types";
import { arrayOptionsMap } from "system/helpers/helperFunctions";

export const useTableColumns = (languages: any = []): TableColumnType[] => {
  const { _t } = useTrans();
  const cells = languages?.map((item: any) => {
    return {
      Header: item.toUpperCase(),
      id: item.toUpperCase(),
      mobileVisible: true,
      isLanguage: true,
      Cell: (props: any) => {
        return !!props?.row?.original?.[item]
          ? props?.row?.original?.[item]
          : "-";
      },
    };
  });
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("key"),
        accessor: "key",
        id: "key",
        width: 350,
        mobileVisible: true,
        isMobileTitle: true,
      },
      ...cells,
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.key,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    [languages]
  );

  return columns;
};

export const useSearchFormConfig = ({ languages = [] } : any): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const filterValues = ["key", ...languages];
  const filterOptions = arrayOptionsMap(filterValues, {
    labelKey: "id",
    valueKey: "id",
  });
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterField({
        componentProps: {
          items: filterOptions,
        },
        columnParams: {
          md: 2,
          lg: 2,
        },
      }),

      fields.filters.key({
        columnParams: {
          md: 5,
          lg: 8,
        },
      })
      /*      {
        component: Search,
        name: "key",
        componentProps: {
          labelText: _t("search"),
          placeHolderText: _t("search"),
          SearchIconDescription: _t("search"),
          showSearch: true,
        },
        emptySpace: {
          sm: { span: 10 },
          lg: { span: 10 },
          md: { span: 10 },
        },
        columnParams: {
          md: 8,
          lg: 8,
        },
      },*/
    ];
  }, [languages]);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.key({
        componentProps: {
          disabled: params.isEdit,
        },
      }),
      fields.formFields.transKeys({ name: [...params.languages] }),
      fields.general.saveBtn(),
      /*      {
        component: Input,
        name: "key",
        componentProps: {
          labelText: _t("key"),
        },
        columnParams: {
          lg: 16,
          md: 16,
        },
      },
      {
        component: TextArea,
        name: [...params.languages],
        componentProps: {
          labelText: (param: any) =>
            _t("language{fieldName}", { fieldName: param.fieldName }),
        },
        columnParams: {
          lg: 16,
          md: 16,
        },
      },*/
    ];
  }, [params.languages]);
  return formConfig;
};

import React from "react";
import Card from "components/atoms/Card";
import { useTrans } from "system/translations/hooks";
import { generatePath, useHistory } from "react-router-dom";
import Loader from "components/atoms/Loader";
import { useDeleteStatusReason, useGetStatusReasons } from "./apiHooks";
import { useTableColumns } from "./constantHooks";
import { Add16, CloseOutline16, Edit16 } from "@carbon/icons-react";
import Table from "modules/table";
import { isMobile } from "system/config/constant";
import ButtonLink from "components/atoms/ButtonLink";
import { APP_ROUTES } from "system/router/constants";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

const StatusReasons = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { data = [], isLoading } = useGetStatusReasons();
  const { mutate: onDelete } = useDeleteStatusReason();
  const { onConfirm } = useConfirmModal();
  const columns = useTableColumns();

  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.statusReasons.update, { id });
    history.push(link, history.location);
  };

  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };

  const actionMenuItems = [
    { 
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => generatePath(APP_ROUTES.statusReasons.update, { id }),
      icon: <Edit16 /> 
    },
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <CloseOutline16 />,
      type: "danger",
    },
  ];

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Card 
      title={_t("status_reasons")}
      extra={
        <RoleWrapper accessTo={"ROLE_MODIFY_STATUS_REASONS"}>
          <>
            {isMobile
              ? <ButtonLink
                className="button-create__link"
                isExpressive
                renderIcon={Add16}
                linkTo={APP_ROUTES.statusReasons.create}
              />
              : <ButtonLink
                isExpressive
                renderIcon={Add16}
                linkTo={APP_ROUTES.statusReasons.create}
                title={_t("create")}
              />
            }
          </>
        </RoleWrapper>
      }
    >
      <Table
        totalRecords={data.length}
        columns={columns}
        data={data}
        isPagination
        // onPagination={setPagination}
        // pageIndex={pagination.page}
        // pageSize={pagination.perPage}
        isLoading={isLoading}
        cellProps={{ actionMenuItems }}
      />
    </Card>
  );
};

export default StatusReasons;

import React from "react";
import { ArrowDown16, ArrowUp16 } from "@carbon/icons-react";
import { ReorderArrowsProps } from "./types";

const ReorderArrows: React.FC<ReorderArrowsProps> = ({
 onMoveUp,
 onMoveDown
}) => {
  return (
    <div className="reorder-arrows__container">
      <ArrowUp16 onClick={onMoveUp}/>
      <ArrowDown16 onClick={onMoveDown} />
    </div>
  );
};

export default ReorderArrows;

import { Toggle, ToggleSmall } from "carbon-components-react";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTrans } from "system/translations/hooks";

const AnalyticPeriodPicker = ({
  onChange,
  onChangeToggle,
}: any) => {
  const [period, setPeriod] = useState('Day')
 
  const handlerChange = (val: string) => {
    setPeriod(val);
    onChange(val.toLowerCase());
  };
  const { _t } = useTrans();
  const periods = ['Hour', 'Day', 'Week', 'Month', 'Year']
  const activeIdx = periods.findIndex(item => item === period)

  return (
    <div className="analytic-period__container">
      <div className="analytic-period__buttons">
      {periods.map((item, idx)=> {
        return (
          <button
            style={item !== period && ((activeIdx - idx) !== 1) && (idx !== periods.length -1) 
              ? { borderRight: '1px solid #CFD7DD'} 
              : {}
            }
            className={classNames("analytic-period__button", {"analytic-period__button--active": item === period})}
            onClick={() => handlerChange(item)}>
            {_t('{period}', {period: item})}
          </button>
        )
      })}
      </div>
      <div className="analytic-period__toggle">
      {_t('convert_all')}
      <ToggleSmall
        aria-label="toggle"
        id={"Toggle"}
        labelA=""
        labelB=""
        onToggle={(e) => {
          onChangeToggle(e);
        }} />
      </div>
    </div>
  );
};

export default AnalyticPeriodPicker;

import config from "system/config";

const fileUploaderConfig = {
 maxFileSize: 1024 * 1024,
 acceptFilesFormat: [
    config.MIMItypes.jpeg,
    config.MIMItypes.png,
    config.MIMItypes.xls,
    config.MIMItypes.xlsx,
    config.MIMItypes.csv
  ],
  checkIsValidFile: (file: any = '') => {
    const checkFile = {
      isValidFormat: false,
      isValidSize: false,
      isValidFile: true
    }

    if (!file) {
      return checkFile
    }

    checkFile.isValidFormat = fileUploaderConfig.acceptFilesFormat.includes(file.type)
    checkFile.isValidSize = file.size < fileUploaderConfig.maxFileSize
    checkFile.isValidFile = checkFile.isValidFormat && checkFile.isValidSize
    
    return checkFile
  }
}

export default fileUploaderConfig;
import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Loader from "components/atoms/Loader";
import { useGetStatusReasonItem, useUpdateStatusReason } from "../apiHooks";
import { useCreateEditConfig } from "../constantHooks";
import { createEditValidation } from "../validation"

const Update: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useUpdateStatusReason();
  const { data: dataStatusReasonItem, isLoading: isLoading } = useGetStatusReasonItem(routeParams?.id);
  const formConfig = useCreateEditConfig();
 
  const onSubmit = (data: any) => {
    onUpdate(data)
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed
      className="update-settings-content"
    >
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("update")}>
            <FormBuilder
              formItemsConfig={formConfig}
              formProps={{
                submitBtnLabel: _t("update"),
                onSubmit: onSubmit,
                initialValues: dataStatusReasonItem,
                validationSchema: createEditValidation(_t),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

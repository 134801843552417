import React from "react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import {
  useGetMonitoringPerMerchantItem,
  useUpdateMonitoringPerMerchant,
} from "../apiHooks";
import Card from "components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { createEditValidation } from "../validation";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "../constantHooks";
import { useFormHelpers } from "../hooks";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useUpdateMonitoringPerMerchant();
  const { data, isLoading } = useGetMonitoringPerMerchantItem(routeParams?.id);
  const formConfig = useCreateEditConfig({
    isEdit,
    initialValues: data
  });
  const { initialValues, normalizeData } = useFormHelpers({ isEdit, data })

  const onSubmit = async (data: any) => {
    onUpdate({ data: normalizeData(data), id: routeParams?.id });
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 12, offset: 2 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("edit")} >
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                onSubmit: onSubmit,
                initialValues: initialValues,
                validationSchema: createEditValidation(_t, { isEdit }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

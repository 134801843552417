import React from "react";
import { useModal } from "modules/modal/hooks";
import { useTrans } from "system/translations/hooks";
import { ConfirmArgTypes, ConfirmResTypes } from "modules/modal/types";
import ConfirmModalButtons from "components/atoms/ConfirmModalButtons";
import ConfirmModalBody from "components/atoms/ConfirmModalBody";

export const useConfirmModal = (): ConfirmResTypes => {
  const { showModal, hideModal } = useModal();
  const { _t } = useTrans();

  const onConfirm = (params: ConfirmArgTypes) => {
    const onOk = () => {
      params.onOk && params.onOk();
      hideModal();
    };
    const onCancel = () => {
      params.onCancel && params.onCancel();
      hideModal();
    }
    showModal({
      size: "sm",
      modalHeading: params?.modalHeading || _t("are_you_sure"),
      component: ConfirmModalBody,
      componentProps: {
        text: params?.bodyText || _t("are_you_sure"),
      },
      isShowScale: Object.keys(params).includes("isShowScale") ? params?.isShowScale : true,
      footer: ConfirmModalButtons,
      footerProps: {
        onOk: onOk,
        onCancel: onCancel,
        onCancelText: params?.onCancelText ? params?.onCancelText : _t("cancel"),
        onOkText: params?.onOkText ? params?.onOkText : _t("on_ok"),
        onOkKind: params?.onOkKind,
        renderIcon: params?.renderIcon,
      },
    });
  };

  return { onConfirm };
};

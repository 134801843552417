import React, { useMemo } from "react";
import { FormConfigType } from "../formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";

export const useChangePasswordFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.password(),
      fields.formFields.confirm_password(),
      fields.general.saveBtn(),
    ];
  }, []);
  return formConfig;
};

import { useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { API_GET_ANALYTICS } from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";

const getAnalyticsKey = "getAnalyticsKey";

export const useGetAnalytics = (filter = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getAnalyticsKey, filter],
    (): Promise<any> =>
      apiRequest({
        url: generateUrlQuery(API_GET_ANALYTICS, filter),
        method: "get",
      })
  );
};

import { useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { API_GET_MERCHANTS_BALANCE_V2_HISTORY } from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { MerchantsBalanceHistoryItemDataType } from "./types";

const getMerchantBalanceV2HistoryKey = "getMerchantBalanceV2HistoryKey";

export const useGetMerchantBalanceV2History = (filter = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantBalanceV2HistoryKey, filter],
    (): Promise<MerchantsBalanceHistoryItemDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS_BALANCE_V2_HISTORY, filter),
        method: "get",
      })
  );
};

import React, { useRef } from "react";
import Toast from "./index";
import { ToastParams } from "./types";

const Context: any = React.createContext(null);

const ToastContextProvider = ({ children }: any) => {
  const childRef: any = useRef(null);
  const addToast = (toast: ToastParams) => {
    childRef.current.toastHandler(toast);
  };

  return (
    <Context.Provider value={{ addToast }}>
      {children}
      <Toast ref={childRef} />
    </Context.Provider>
  );
};

export default Context;
export { ToastContextProvider };

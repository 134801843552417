import React from "react";
import CopyButtonWrapper from "../CopyButtonWrapper";
import { DescriptionProps } from "./types";

const Description: React.FC<DescriptionProps> = ({
  title,
  value,
  dontConvertToString,
}: any) => {
  const renderJson = (value: any) => {
    if (dontConvertToString) {
      return value;
    }
    try {
      const val =
        typeof value === "object" || typeof value === "boolean"
          ? JSON.stringify(value, null, 2)
          : value;
      return val;
    } catch (e) {
      return value;
    }
  };
  return (
    <div className={"description"}>
      <div className={"description__title"}>{title}</div>
      {title !== "transactionId" ? (
        <div className={"description__value"}>{renderJson(value)}</div>
      ) : (
        <div style={{ display: "flex" }}>
          <div className={"description__value"}>{renderJson(value)}</div>
          <CopyButtonWrapper copyValue={renderJson(value)} />
        </div>
      )}
    </div>
  );
};

export default Description;

import React, { useMemo, useState } from "react";
import { Tile, Link, HeaderMenuItem } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Toggle from "components/atoms/Toggle";
import {
  useEnableUserTFa,
  useResendConfirmationLink,
  useResentTFA,
  useResetUserPassword,
} from "modules/users/apiHooks";
import { UserItemType, UserSettingsProps } from "modules/users/types";
import DescriptionList from "components/molecules/DescriptionList";

const Settings: React.FC<UserSettingsProps> = ({ userData }) => {
  const { _t } = useTrans();
  const { mutate: onResend } = useResendConfirmationLink();
  const { mutate: onTFa } = useEnableUserTFa();
  const { mutate: onResetTFa } = useResentTFA();
  const onResendConfirmationLink = (e: any) => {
    e.preventDefault();
    onResend(userData.id);
  };
  const { mutate: onUserPasswordReset } = useResetUserPassword();
  const onPasswordReset = (e: any) => {
    e.preventDefault();
    onUserPasswordReset({ email: userData.email });
  };
  const onUserResetTFa = (e: any) => {
    e.preventDefault();
    onResetTFa(userData.id);
  };
  const listData: any = useMemo(() => {
    const res = [];
    let key: keyof UserItemType;
    for (key in userData) {
      const val = typeof userData[key] ? `${userData[key]}` : userData[key];
      res.push({ title: key, value: val });
    }
    return res;
  }, [userData]);

  const enableTFa = (val: boolean) => {
    const data = {
      id: userData.id,
      tfaState: val,
    };
    onTFa(data);
  };

  return (
    <div style={{ paddingTop: "8px" }}>
      <label htmlFor="name" className="bx--label">
        Security
      </label>
      <Tile>
        <Toggle
          onChange={enableTFa}
          value={userData.tfa}
          labelText={_t("auth_tfa")}
        />
        <br />
        <label htmlFor="name" className="bx--label">
          {_t("auth_tfa_description")}
        </label>
        <br />
        <Link onClick={onUserResetTFa} href="javascript:void(0)">
          {_t("reset_tfa")}
        </Link>
      </Tile>
      <Tile style={{ marginTop: "8px" }}>
        <b>{_t("email_confirmation")}</b>
        <br />
        <label htmlFor="name" className="bx--label">
          {_t("email_confirmation_description")}
        </label>
        <br />
        <Link onClick={onResendConfirmationLink}>
          {_t("resend_confirmation_link")}
        </Link>
      </Tile>
      <Tile style={{ marginTop: "8px" }}>
        <b>{_t("reset_password")}</b>
        <br />
        <label htmlFor="name" className="bx--label">
          {_t("reset_password_description")}
        </label>
        <br />
        <Link onClick={onPasswordReset}>{_t("send_email_link")}</Link>
      </Tile>
      <label style={{ marginTop: "8px" }} htmlFor="name" className="bx--label">
        {_t("other_details")}
      </label>
      <Tile>
        <DescriptionList list={listData} />
      </Tile>
    </div>
  );
};

export default Settings;

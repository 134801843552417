import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MONITORING_DEPOSIT,
  API_GET_MONITORING_WITHDRAWAL,
  API_CREATE_MONITORING,
  API_DELETE_MONITORING,
  API_UPDATE_MONITORING,
  API_GET_MONITORING_ITEM,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  MonitoringDataType,
  MonitoringItemType,
} from "modules/monitoring/types";

const getMonitoringDepositKey = "getMonitoringDepositKey";
const getMonitoringWidrawalKey = "getMonitoringWitdrawalKey";
const getMonitoringItemKey = "getMonitoringItemKey";

export const useGetMonitoringDeposit = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMonitoringDepositKey, filters],
    (): Promise<MonitoringDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MONITORING_DEPOSIT, filters),
        method: "get",
      })
  );
};
export const useGetMonitoringWithdrawal = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMonitoringWidrawalKey, filters],
    (): Promise<MonitoringDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MONITORING_WITHDRAWAL, filters),
        method: "get",
      })
  );
};
export const useGetMonitoringItem = (id: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMonitoringItemKey, id],
    (): Promise<MonitoringItemType> =>
      apiRequest({
        url: generatePath(API_GET_MONITORING_ITEM, { id }),
        method: "get",
      })
  );
};

export const useCreateMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MONITORING,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringDepositKey);
        queryClient.invalidateQueries(getMonitoringWidrawalKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MONITORING, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringDepositKey);
        queryClient.invalidateQueries(getMonitoringWidrawalKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MONITORING, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringDepositKey);
        queryClient.invalidateQueries(getMonitoringWidrawalKey);
        queryClient.invalidateQueries(getMonitoringItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

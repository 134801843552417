import ResendIpn from "./ResendIpn";
import TransactionLog from "./TransactionLog";


const TransactionInfoFooter = ({ resendIpnId, transactionUUID }: { resendIpnId: string, transactionUUID: string | null }) => {
  return (
    <>
      <TransactionLog transactionUUID={transactionUUID} />
      <ResendIpn id={resendIpnId} />
    </>
  );
};

export default TransactionInfoFooter;

import classNames from "classnames";
import React from "react";

const FieldsArray = ({
  name,
  children,
  onFormChange,
  values = [],
  topTitle = "Recipients",
  addTittle = "Add email address",
  isAddButtonBottom = false,
  maxAmount,
  onAddNewItem,
  isTitleTop = false,
  className,
}: any) => {
  const add = () => {
    const newVal = [...values];
    onAddNewItem ? onAddNewItem(newVal) : newVal.push("");
    onFormChange(name, newVal);
  };
  const canAddNewItem = maxAmount === undefined || values.length < maxAmount;
  return  (
    <div className={classNames("fields-array", className)}>
      {isAddButtonBottom && isTitleTop && (
      <div className="fields-array__top">
          <div className="fields-array__top__title">
            {topTitle} ({values.length})
          </div>
         </div>
         )}
      {!isAddButtonBottom && (
        <div className="fields-array__top">
          <div className="fields-array__top__title">
            {topTitle} ({values.length})
          </div>
          {canAddNewItem && (
            <>
              <div className="fields-array__top__divider" />
              <div className="fields-array__top__add" onClick={add}>
                {addTittle} +
              </div>
            </>
          )}
        </div>
      )}

      {children}

      {isAddButtonBottom && canAddNewItem && (
        <div className="fields-array__top__add" onClick={add}>
          {addTittle} +
        </div>
      )}
    </div>
  );
};

export default FieldsArray;

import React, { useState } from "react";
import { Button } from "carbon-components-react";
import { Send16 } from "@carbon/icons-react";
import { useResendIpn } from "modules/transactions/apiHooks";
import { useTrans } from "system/translations/hooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

const ResendIpn: React.FC<{ id: string }> = ({ id }) => {
  const [ disabled, setDisabled ] = useState(false)
  const { mutateAsync } = useResendIpn();
  const { _t } = useTrans();

  const onResend = async () => {
    setDisabled(true);
    await mutateAsync(id);

    setTimeout(() => {
      setDisabled(false)
    }, 5000)
  };
  
  return (
    <Button
      kind="secondary"
      onClick={onResend}
      renderIcon={Send16}
      disabled={disabled}
    >
      {_t("resend_ipn")}
    </Button>
  );
};

const ResendIpnWrapped = (props: any) => {
  return (
    <RoleWrapper accessTo={"ROLE_RESEND_IPN"}>
      <ResendIpn {...props} />
    </RoleWrapper>
  );
};

export default ResendIpnWrapped;

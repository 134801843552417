import { Column as ColumnType } from "react-table";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import Input from "components/atoms/Input";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (data: any[]): TableColumnType[] => {
  const dataColumns = useMemo(
    () =>
      data.length
        ? Object.keys(data?.[0])?.map((item: any) => ({
            Header: item,
            accessor: item,
            id: item,
            mobileVisible: item !== "type",
            isMobileTitle: item === "key",
          }))
        : [],
    [data]
  );
  const columns = useMemo(
    (): TableColumnType[] => [
      ...dataColumns,
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    [data]
  );

  return columns;
};

export const useCreateEditConfig = (data: any[]): FormConfigType => {
  const { _t } = useTrans();
  const dataFields = useMemo(
    () =>
      data
        ? Object.keys(data).map((item: any) => ({
            component: Input,
            name: item,
            componentProps: {
              labelText: _t(item),
              placeHolderText: _t(item),
            },
            columnParams: {
              lg: 16,
              md: 16,
            },
          }))
        : [],
    [data]
  );
  const formConfig = useMemo((): FormConfigType => {
    return [...dataFields];
  }, [data]);
  return formConfig;
};

import React from "react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import {
  useDeleteMonitoring,
  useGetMonitoringItem,
  useUpdateMonitoring,
} from "../apiHooks";
import Card from "components/atoms/Card";
import { Button, Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { createEditMonitoringValidation } from "../validation";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/monitoring/constantHooks";
import { useConfirmModal } from "modules/modal/predefinedModals";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const { onConfirm } = useConfirmModal();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useUpdateMonitoring();
  const { mutate: onDelete } = useDeleteMonitoring();
  const { data, isLoading } = useGetMonitoringItem(routeParams?.id);
  const formConfig = useCreateEditConfig({
    isEdit,
  });
  const onSubmit = async (data: any) => {
    const normalize = { ...data };
    if (isEdit && !normalize.password) {
      delete normalize.password;
    }
    onUpdate({ data: normalize, id: routeParams?.id });
  };
  const onDeleteWithConfirm = () => {
    onConfirm({ onOk: () => onDelete({ id: routeParams?.id }) });
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 12, offset: 2 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card
            title={_t("edit")}
            /*       extra={
              <Button onClick={onDeleteWithConfirm}>{_t("delete")}</Button>
            }*/
          >
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                onSubmit: onSubmit,
                initialValues: data,
                validationSchema: createEditMonitoringValidation(_t, {
                  isEdit,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

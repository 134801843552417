import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "modules/formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { useModuleData } from "./hooks";
import config from "system/config";

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const { merchantsOptions, currenciesOptions, paymentGatewaysOptions, paymentMethodsOptions } =
    useModuleData();
    const transactionTypes = config.transactionTypes;

  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchantId({
        componentProps: {
          items: merchantsOptions,
        },
      }),
      fields.filters.paymentMethod({
        componentProps: {
          items: paymentMethodsOptions,
          withAsteriskOnSelectAll: true
        },
      }),
      fields.filters.paymentGateway({
        componentProps: {
          items: paymentGatewaysOptions,
          withAsteriskOnSelectAll: true
        },
      }),
      fields.filters.currency({
        valuePreview: true,
        componentProps: {
          items: currenciesOptions,
        },
      }),
      fields.filters.type({
        name: 'transactionType',
        componentProps: {
          items: transactionTypes,
          label: _t('type')
        },
      }),
      fields.filters.date({
        componentProps: {
          openLeft: true
        }}),
      fields.general.submitBtn(),
    ];
  }, [merchantsOptions, paymentGatewaysOptions, currenciesOptions, paymentMethodsOptions]);
  return formConfig;
};

import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_ALERTS,
  API_CREATE_ALERT,
  API_DELETE_ALERT,
  API_UPDATE_ALERT,
  API_GET_ALERT,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { AlertsDataType, AlertsItemType } from "modules/alerts/types";

const getAlertsKey = "getAlertsKey";
const getAlertsItemKey = "getAlertsItemKey";
export const useGetAlerts = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getAlertsKey, filters],
    (): Promise<AlertsDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_ALERTS, filters),
        method: "get",
      })
  );
};
export const useGetAlertsItem = (id: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getAlertsItemKey, id],
    (): Promise<AlertsItemType> =>
      apiRequest({
        url: generatePath(API_GET_ALERT, { id }),
        method: "get",
      })
  );
};

export const useCreateAlerts = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_ALERT,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAlertsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteAlerts = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_ALERT, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAlertsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateAlerts = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_ALERT, {
          id: data.id,
        }),
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAlertsKey);
        queryClient.invalidateQueries(getAlertsItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

import React, { useRef } from "react";
import AnalyticPeriodPicker from "components/molecules/AnalyticPeriodPicker";
import AnalyticsTotalVolume from "components/molecules/AnalyticsTotalVolume";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import { isEmpty } from "lodash";
import { useTrans } from "system/translations/hooks";
import { AnalyticDiagramProps } from "../types";
import { barColors } from "../constants";
import CustomTooltip from "components/molecules/CustomAnalyticTooltip";
import NoData from "components/atoms/NoData";
import AnalyticRenderLegend from "components/molecules/AnalyticLegendRender";
import { useGetAnalyticBarColors } from "system/helpers/hooks";


const AnalyticDiagram: React.FC<AnalyticDiagramProps> = ({
  filters,
  onPeriodChange,
  onConverted,
  analyticsData,
  isLoading,
  data,
  currenciesOptions,
  convertedFilters
}: any) => {
  const { _t } = useTrans()

  const hourTickFormatter = (tick: string) => {
    switch (filters.differentiation) {
      case 'hour':
        return tick.split('(')[0]
      case 'year':
        return tick
      default:
        return tick.split(',')[0]
    }
  };

  const formaterYAxis = (value: any) => {
    if (value >= 1e5 && value < 1e6) return +(value / 1e3).toFixed(1) + "K";
    if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(1) + "M";
    if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(1) + "B";

    return value.toLocaleString()
    
  };

  const ref = useRef<any>(null);
  const depositCurrencies = currenciesOptions[0]?.depositCurrencies || [];
  const withdrawalCurrencies = currenciesOptions[1]?.withdrawalCurrencies || [];

  const { barColorsAll } = useGetAnalyticBarColors(depositCurrencies, withdrawalCurrencies, barColors)

  if (!analyticsData?.data && !isLoading) {
    return <NoData text={_t("no_data_text")} />;
  }; 
  
  return (
    <div style={{ backgroundColor: '#fff', padding: '32px' }}>
      <AnalyticPeriodPicker onChange={onPeriodChange} onChangeToggle={onConverted} />
      <AnalyticsTotalVolume data={analyticsData} isLoading={isLoading} filters={filters} />
      <div style={{ height: "466px", backgroundColor: '#fff' }}>
        <ResponsiveContainer width="100%" height="100%" >
          <ComposedChart
            width={500}
            height={400}
            data={data}
            barGap={8}
            ref={ref}
          >
            <CartesianGrid stroke="#CFD7DD" strokeDasharray="3 3" />
            <YAxis tickFormatter={formaterYAxis} />
            <YAxis 
              orientation="right" 
              yAxisId="right"
              tickCount={5}
              allowDecimals={false}
            />
            <XAxis
              dataKey="name"
              interval='preserveStart'
              tick={{ stroke: '#5B6871' }}
              tickFormatter={hourTickFormatter}
              tickSize={3}
              offset={0}
              scale='band'
              dx={ref?.current?.props?.width / (data.length * 2)}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={<AnalyticRenderLegend />} />
            <Brush dataKey="name" height={30} stroke="#0077D4" travellerWidth={20} />

            {currenciesOptions[0].depositCurrencies.map((currency: any) => {
              return (
                <Bar
                  dataKey={`depositAmountCurrency.${currency}`}
                  name={currency}
                  barSize={isEmpty(convertedFilters) ? 16 : 30}
                  fill={barColorsAll[currency]}
                  radius={[4, 4, 0, 0]}
                />
              )
            })}
            {currenciesOptions[1].withdrawalCurrencies.map((currency: any) => {
              return (
                <Bar
                  dataKey={`withdrawalAmountCurrency.${currency}`}
                  name={currency}
                  barSize={isEmpty(convertedFilters) ? 16 : 30}
                  fill={barColorsAll[currency].includes('#')
                  ? barColorsAll[currency] + 'A1'
                  : barColorsAll[currency].split(')')[0] + ', 0.61)' 
                }
                  radius={[4, 4, 0, 0]}
                />
              )
            })}
            {(!filters.transactionType || filters.transactionType.includes("DEPOSIT")) &&
              <Area
                connectNulls
                yAxisId="right"
                type='monotone'
                dataKey={'depositCount'}
                name="Quantity"
                fill="rgba(253, 59, 59, 0.16)"
                stroke="#FD3B3B"
                strokeWidth={2}
              />
            }
            {(!filters.transactionType || filters.transactionType.includes("WITHDRAWAL")) &&
              <Area
                connectNulls
                yAxisId="right"
                type='monotone'
                dataKey={'withdrawalCount'}
                name="Quantity (withdrawals)"
                fill="rgba(41, 93, 200, 0.16)"
                stroke="#295DC8"
                strokeWidth={2}
              />
            }
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AnalyticDiagram;

import React, { useState } from "react";
import { useTrans } from "system/translations/hooks";
import LoginForm from "./forms/loginForm";
import AuthWrapper from "./wrappers/AuthWrapper";
import { APP_ROUTES } from "system/router/constants";
import TfaProcessForm from "./forms/tfaProcessForm";
import { useLogin } from "modules/auth/apiHooks";

const Login: React.FC = () => {
  const { _t } = useTrans();
  const { mutateAsync, isLoading, data: loginData, variables } = useLogin();
  const [step, setStep] = useState(1);
  const [payloadData, setpPayloadData] = useState({ rememberMe: false });

  const backHandler = () => {
    setStep(1);
  };

  const onSuccess = (data: any) => {
    setpPayloadData(data);
    setStep(2);
  };

  return (
    <>
      {step === 1 && (
        <AuthWrapper
          title={`${_t("log_in_to")} Pingwi`}
          actionLink={APP_ROUTES.forgotPassword}
          actionText={`${_t("forgot_password")}?`}
        >
          <LoginForm
            isLoading={isLoading}
            onLoginAsync={mutateAsync}
            onSuccess={onSuccess}
          />
        </AuthWrapper>
      )}
      {step === 2 && (
        <AuthWrapper
          qrCode={loginData?.qrCode}
          title={`${_t("tfa_authentication")}`}
          subtitle={_t("tfa_authentication_help_text")}
          backHandler={backHandler}
          footer={
            <span
              dangerouslySetInnerHTML={{
                __html: _t(`tfa_contact_support_via_mail_{link}`, {
                  link: (
                    <a target={"_blank"} href={"mailto:info@i-settle.com"}>
                      info@i-settle.com
                    </a>
                  ),
                }),
              }}
            />
          }
        >
          <TfaProcessForm payloadData={variables} />
        </AuthWrapper>
      )}
    </>
  );
};

export default Login;

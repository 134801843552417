import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_PAYMENT_GATEWAYS,
  API_GET_PAYMENT_METHODS,
  API_MERCHANT_GET_PAYMENT_GATEWAY_CODES
} from "system/api/apiUrls";
import {
  PaymentGatewaysDataType,
  PaymentMethodsDataType,
} from "modules/payment/types";

const getPaymentMethodsKey = "getPaymentMethodsKey";
const getPaymentGatewaysKey = "getPaymentGatewaysKey";
const getPaymentGatewaysCodes = "getPaymentGatewaysKey";
export const useGetPaymentMethods = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentMethodsKey],
    (): Promise<PaymentMethodsDataType> =>
      apiRequest({
        url: API_GET_PAYMENT_METHODS,
        method: "get",
      })
  );
};
export const useGetPaymentGateways = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewaysKey],
    (): Promise<PaymentGatewaysDataType> =>
      apiRequest({
        url: API_GET_PAYMENT_GATEWAYS,
        method: "get",
      })
  );
};
export const useGetPaymentGatewaysCodes = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewaysCodes],
    (): Promise<PaymentGatewaysDataType> =>
      apiRequest({
        url: API_MERCHANT_GET_PAYMENT_GATEWAY_CODES,
        method: "get",
      })
  );
};

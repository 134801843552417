import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Add16, TrashCan16, Edit16 } from "@carbon/icons-react";
import Card from "components/atoms/Card";
import ButtonLink from "components/atoms/ButtonLink";
import Table from "modules/table";
import { APP_ROUTES } from "system/router/constants";
import { useTrans } from "system/translations/hooks";
import { useDeleteRole, useGetRoles } from "modules/roles/apiHooks";
import Loader from "components/atoms/Loader";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useTableColumns } from "modules/roles/constantHooks";
import { useDeleteWithConfirm } from "./hooks";
import { getUrl } from "system/helpers/helperFunctions";


const Roles = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const { onConfirm } = useConfirmModal();
  const { data = [], isLoading } = useGetRoles();
  const { mutate: onDelete } = useDeleteRole();
  const columns = useTableColumns();
  const onUpdate = ({ id }: any) => {
    history.push(getUrl(APP_ROUTES.roles.update, id ), history.location);
  };
  const onDeleteWithConfirm = useDeleteWithConfirm()
  
  const actionMenuItems = [
    { 
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.roles.update, id),
      icon: <Edit16 /> 
    },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];
  if (isLoading) {
    return <Loader formOverlay />;
  }

  const isMobile = window.matchMedia('(max-width: 671px)').matches;


  return (
    <Card
      withoutFilter
      title={_t("roles")}
      extra={isMobile
        ? <ButtonLink
            className="button-create__link"
            isExpressive
            renderIcon={Add16}
            linkTo={APP_ROUTES.roles.create}
          />
        : <ButtonLink
          isExpressive
          renderIcon={Add16}
          linkTo={APP_ROUTES.roles.create}
          title={_t("create_new_role")}
        />
      }
    >
      <Table
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        cellProps={{ actionMenuItems }}
        isPagination
        totalRecords={data?.length}
      />
    </Card>
  );
};

export default Roles;

import { arrayOptionsMap } from "system/helpers/helperFunctions";
import {
  useGetMerchantAvailableDepositMethods,
  useGetMerchantAvailableWithdrawalMethods,
} from "./apiHooks";

export const useModuleData = (id: number): any => {
  const { data: depositMethods = [] } =
    useGetMerchantAvailableDepositMethods(id);
  const { data: withdrawalMethods = [] } =
    useGetMerchantAvailableWithdrawalMethods(id);
  const depositMethodsOptions = arrayOptionsMap(depositMethods, {
    labelKey: "name",
    valueKey: "id",
  });
  const withdrawalMethodsOptions = arrayOptionsMap(withdrawalMethods, {
    labelKey: "name",
    valueKey: "id",
  });

  return {
    depositMethodsOptions,
    withdrawalMethodsOptions,
    depositMethods,
    withdrawalMethods,
  };
};

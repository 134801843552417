import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const createEditValidation = (_t: TransType) => {
  return Yup.object().shape({
    code: Yup.number()
      .typeError(_t("must_be_a_number"))
      .required(_t("required")),
    title: Yup.string().required(_t("required")),
    description: Yup.string().required(_t("required")),
  });
};

import React, { useState } from "react";
import { useTrans } from "system/translations/hooks";
import AuthWrapper from "./wrappers/AuthWrapper";
import { APP_ROUTES } from "system/router/constants";
import ResetPasswordForm from "./forms/resetPasswordForm";

const ResetPassword: React.FC = () => {
  const { _t } = useTrans();
  const [step, setStep] = useState(1);

  const onSuccess = () => setStep(2);

  return (
    <>
      {step === 1 && (
        <AuthWrapper
          title={_t("reset_password")}
          subtitle={_t("reset_password_help_text")}
          actionLink={APP_ROUTES.login}
          actionText={`${_t("back_to_login")}?`}
        >
          <ResetPasswordForm onSuccess={onSuccess} />
        </AuthWrapper>
      )}
      {step === 2 && (
        <AuthWrapper
          title={_t("password_changed")}
          subtitle={_t("password_changed_message")}
          actionLink={APP_ROUTES.login}
          actionText={`${_t("back_to_login")}?`}
          classname={"reset-password--success"}
        />
      )}
    </>
  );
};

export default ResetPassword;

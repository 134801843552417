import { Column as ColumnType } from "react-table";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { CreateEditFormConfigParams } from "modules/roles/types";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("name"),
        accessor: "name",
        id: "name",
        mobileVisible: true,
        isMobileTitle: true,
      },
      {
        Header: _t("description"),
        accessor: "description",
        id: "description",
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const authorities = params?.rolesAuthorities?.map((item: any) =>
    fields.formFields.authority({
      name: `authorities.${item.name}`,
      componentProps: { title: item.name, description: item.description },
    })
  );
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.name(),
      fields.formFields.description(),
      ...authorities,
      fields.general.saveBtn(),
    ];
  }, [authorities]);
  return formConfig;
};

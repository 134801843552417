export const useTransactionType = (_t: any) => {
  return [
    { label: _t("deposit"), value: "DEPOSIT" },
    { label: _t("withdrawal"), value: "WITHDRAWAL" },
  ];
};

export const config = {
  statusesConfiguration: [
    { label: "Pull", value: "pull"},
    { label: "Callback", value: "callback"},
    { label: "Response", value: "response"},
  
  ]
}
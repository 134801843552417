import React from "react";
import "./styles/index.scss";
import "./styles/app.scss";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import SystemTemplate from "templates/System";
import routes from "system/router/routes";
import { ModalContextProvider } from "modules/modal/ModalContext";
import { ToastContextProvider } from "modules/toast/ToastContext";
import StoreProvider from "system/store/StoreProvider";

export const history = createBrowserHistory();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 0,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <StoreProvider>
          <ToastContextProvider>
            <ModalContextProvider>
              <SystemTemplate>{routes}</SystemTemplate>
            </ModalContextProvider>
          </ToastContextProvider>
        </StoreProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;

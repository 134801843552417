import React from "react";
import { CopyButtonWrapperProps } from "./types";
import { CopyButton } from "carbon-components-react";
import { useToast } from "modules/toast/hooks";
import "./styles.scss"
import { useTrans } from "system/translations/hooks";

const CopyButtonWrapper: React.FC<CopyButtonWrapperProps> = ({ copyValue, customStyles = {}, toastMessage = '' }: any) => {
  const toastHandler = useToast()
  const {_t} = useTrans()

  return <CopyButton 
    onClick = {(e) => {
      navigator.clipboard.writeText(copyValue);
      toastHandler.addToast({
        caption: toastMessage.length === 0 ?  _t("copiedUUID") : toastMessage,
        kind: "success",
      });
      e.stopPropagation();
    }}
    style = {customStyles}
    className="copy-button-wrapper"
  />
};

export default CopyButtonWrapper;

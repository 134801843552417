import { useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { GET_SWAGGER_DOCUMENTATION_DATA } from "system/api/apiUrls";

const getSwaggerKey = "getSwaggerKey";

export const useGetSwaggerDocumentationData = () => {
  const [apiRequest] = useApiRequest();
  return useQuery<any>(
    [getSwaggerKey],
    () =>
      apiRequest({
        url: GET_SWAGGER_DOCUMENTATION_DATA,
        method: "get",
      }),
  );
};

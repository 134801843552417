import FormBuilder from "modules/formBuilder/FormBuilder";
import React from "react";
import { ModalBodyWithFormProps } from "./types";

const ModalBodyWithForm: React.FC<ModalBodyWithFormProps> = ({ text, formBuilderProps }) => {
  return (
      <div className={"modal-body-with-form"}>
        {text}
        <FormBuilder {...formBuilderProps} />
      </div>
  );
};

export default ModalBodyWithForm;

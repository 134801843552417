import React, { useMemo } from "react";
import { Button, Grid, Row } from "carbon-components-react";
import { useFormik } from "formik";
import classNames from "classnames";
import { useFormikHelper } from "system/helpers/hooks";
import { FormBuilderProps } from "modules/formBuilder/types";
import { getNestedValue } from "system/helpers/helperFunctions";
import { useTrans } from "system/translations/hooks";
import Loader from "components/atoms/Loader";
import FilterTag from "components/molecules/FilterTag";
import FieldsArray from "modules/formBuilder/FieldsArray";
import FieldItem from "modules/formBuilder/FieldItem";
import FieldsArrayWithDragDrop from "./FieldsArrayWithDragDrop";

const FormBuilder: React.FC<FormBuilderProps> = ({
  formProps,
  formItemsConfig,
  showSubmit = true,
  showModal = false,
}) => {
  const { initialValues, ...restFormProps } = formProps;
  const { _t } = useTrans();
  const formInitialValues = useMemo(() => {
    let values = formProps.initialValues || {};
    formItemsConfig.forEach(({ name }: any) => {
      if (
        !!name &&
        !Array.isArray(name) &&
        !values.hasOwnProperty(name) &&
        !name.includes(".")
      ) {
        values[name] = undefined;
      } 
    });
    return values;
  }, [formItemsConfig]);
  const formik: any = useFormik({
    /*    validateOnChange: true,
    validateOnMount: false,*/
    ...restFormProps,
    initialValues: showModal ? formProps.values : formInitialValues,
    values: showModal ? formProps.values || {} : undefined,
  });

  /*  useEffect(() => {
    formProps.onFormValuesChange &&
      formProps.onFormValuesChange(formik.values, formik);
  }, [formik.values]);*/
  const resetField = (fieldName: any) => {
    const initialValue = initialValues?.[fieldName];
    formik.setFieldValue(fieldName, initialValue);
  };
  const { selectedValuePreview, isHasValueToPreview } = useMemo(() => {
    let selectedValuePreview: any = {};
    let isHasValueToPreview = false;
    formItemsConfig.forEach((item: any) => {
      //componentName: item?.component?.name,
      if (item.valuePreview) {
        selectedValuePreview[`${item.name}`] = {
          label: item?.componentProps?.labelText || item?.componentProps?.label,
          value: formik.values?.[item.name],
          name: item.name,
        };
        if (formik.values?.[item.name]) {
          isHasValueToPreview = true;
        }
      }
    });
    return { selectedValuePreview, isHasValueToPreview };
  }, [formItemsConfig, formik.values]);
  const { error, onBlur, onChange } = useFormikHelper(formik);

  const clearAll = (items: any) => {
    items.forEach((item: any) => resetField(item.name))
  }

  return (
    <form noValidate className={"form"} onSubmit={formik.handleSubmit}>
      <Grid condensed style={{ padding: "0 1rem" }}>
        <Row style={formProps.rowStyles}>
          {formItemsConfig?.map((item: any) => {
            let {
              component: Component,
              componentProps: formItemProps,
              name,
              columnParams = {},
              emptySpace,
              type,
              hidden,
              components,
            } = item;

            if (hidden) {
              return null;
            }
            const componentProps =
              typeof formItemProps === "function"
                ? formItemProps(formik)
                : formItemProps;
            const additionalProps: any = {};
            if (componentProps?.type === "submit") {
              additionalProps.formOnSubmit = formik.handleSubmit;
            }
            if (Array.isArray(name)) {
              const labelText = (param: any) =>
                typeof componentProps.labelText === "function"
                  ? componentProps.labelText(param)
                  : componentProps.labelText;
              return name.map((nameKey) => (
                <FieldItem
                  emptySpace={emptySpace}
                  columnParams={columnParams}
                  Component={Component}
                  additionalProps={additionalProps}
                  labelText={labelText({ fieldName: nameKey })}
                  name={nameKey}
                  componentProps={componentProps}
                  error={error(nameKey)}
                  onBlur={onBlur}
                  onChange={onChange}
                  formik={formik}
                  formProps={formProps}
                  getNestedValue={getNestedValue}
                />
              ));
            }
            if (type === "array") {
              const fieldArrayFormItemProps = typeof formItemProps === "function"
                ? formItemProps(formik)
                : formItemProps;
              return (
                <FieldsArray
                  name={name}
                  values={formik?.values?.[name]}
                  onFormChange={(name: any, value: any) =>
                    formik.setFieldValue(name, value)
                  }
                  {...fieldArrayFormItemProps}
                >
                  <Grid condensed style={{ padding: "0 1rem" }}>
                    <Row>
                      {formik?.values?.[name] &&
                        formik?.values?.[name].map(
                          (item: any, arrayIndex: number) => {
                            return (
                              <>
                                {components.map((item: any, index: number) => {
                                  let {
                                    component: Component,
                                    componentProps: formItemProps,
                                    name: componentName,
                                    columnParams = {},
                                    emptySpace,
                                  } = item;
                                  let fullName = !!componentName
                                    ? `${name}.${arrayIndex}.${componentName}`
                                    : `${name}.${arrayIndex}`;
                                  const componentProps =
                                    typeof formItemProps === "function"
                                      ? formItemProps(formik, arrayIndex)
                                      : { ...formItemProps };

                                  const removeItem = () => {
                                    const values = formik?.values?.[name];
                                    values.splice(arrayIndex, 1);
                                    formik.setFieldValue(name, values);
                                  }
                                  
                                  if (name === 'conditions' && componentName === 'operator' || Component.name === 'Divider') {
                                    if (componentProps.isConditionsOperator) {
                                      fullName = 'operator'
                                    }
                                    if (arrayIndex === formik?.values?.[name].length -1 && index >= components.length - 2) {
                                      return
                                    } else if (arrayIndex  && arrayIndex === formik?.values?.[name].length - 2) {
                                      delete componentProps.valueA
                                      delete componentProps.valueB
                                    }
                                  }
                                  const additionalProps: any = item.name === 'removeBtn' ? { onClick: removeItem } : {};
                                  return (
                                    <FieldItem
                                      emptySpace={emptySpace}
                                      columnParams={columnParams}
                                      Component={Component}
                                      additionalProps={additionalProps}
                                      name={fullName}
                                      componentProps={componentProps}
                                      error={error(fullName)}
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      formik={formik}
                                      formProps={formProps}
                                      getNestedValue={getNestedValue}
                                      onRemove={!componentProps?.withoutRemove  && removeItem}
                                    />
                                  );
                                })}
                              </>
                            );
                          }
                        )}
                    </Row>
                  </Grid>
                </FieldsArray>
              );
            }
            if (type === "draggableArray") {
              return (
                <FieldsArrayWithDragDrop 
                  formik={formik}
                  name={name}
                  formItemProps={formItemProps}
                  components={components}
                  error={error}
                  onBlur={onBlur}
                  onChange={onChange}
                  formProps={formProps}
                  getNestedValue={getNestedValue}
                />
              )
            }
            return (
              <FieldItem
                emptySpace={emptySpace}
                columnParams={columnParams}
                Component={Component}
                additionalProps={additionalProps}
                name={name}
                componentProps={componentProps}
                error={error(name)}
                onBlur={onBlur}
                onChange={onChange}
                formik={formik}
                formProps={formProps}
                getNestedValue={getNestedValue}
              />
            );
          })}
        </Row>
      </Grid>
      {showSubmit && (
        <Button
          type="submit"
          kind={formProps.submitBtnKind || "secondary"}
          renderIcon={formProps.renderIcon || null}
          className={classNames(formProps.isLoading && "bx--btn--loading")}
          disabled={formProps.isLoading}
          hasIconOnly={formProps.hasIconOnly || false}
        >
          {formProps.isLoading ? (
            <Loader />
          ) : (
            formProps.submitBtnLabel || _t("submit")
          )}
        </Button>
      )}
      <div>
        {Object.values(selectedValuePreview).map((item: any) => {
          if (!!item.value && Array.isArray(item.value) && !item.value.length) {
            return;
          }
          if (!!item.value) {
            return (
              <FilterTag
                label={item.label}
                value={item.value === "*" ? _t('all') : item.value}
                onClose={() => resetField(item.name)}
              />
            );
          }
        })}
        {isHasValueToPreview ? (
          <FilterTag
            label={_t("clear_all_filters")}
            value={null}
            onClose={() => clearAll(Object.values(selectedValuePreview))}
            type={"danger"}
            isFullClickable
          />
        ) : null}
      </div>
    </form>
  );
};

export default FormBuilder;

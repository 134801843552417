import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { Checkmark16, ChevronDown16, ChevronUp16 } from "@carbon/icons-react";
import { useModuleData } from "./hooks";
import { config, useTransactionType } from "./constants";
import { CreateEditFormConfigParams, StatusCallbackResponsePullCreateUpdateItemType } from "./types";
import ChangeValueTooltip from "components/molecules/ChangeValueTooltip";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { createEditStatusConnectionConfigurationSettingsValidation } from "./validation"

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans()
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("payment_gateway"),
        accessor: "gatewayCode",
        id: "gatewayCode",
        mobileVisible: true,
        isMobileTitle: true,
        Cell: ({ isExpanded, onExpand, row, ...rest }: any) => {
          return (
            <span className={"cell-wrapper"}>
              <span className={"cell-wrapper__accordion"} onClick={onExpand}>
                {isExpanded ? <ChevronUp16 /> : <ChevronDown16 />}
              </span>
              <span
                onClick={onExpand}
              >
                {row?.original?.gatewayCode}
              </span>
            </span>
          );
        },
      },
      {
        Header: _t("type"),
        id: "type",
        accessor: "type",
        mobileVisible: true,
      },
      {
        Header: _t("comment"),
        id: "comment",
        accessor: "comment",
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <RoleWrapper accessTo={"ROLE_MODIFY_STATUS_REASONS"}>
              <DotsMenu
                clickParams={{
                  id: props?.row?.original?.id,
                  allData: props?.row?.original,
                }}
                items={props?.actionMenuItems}
              />
            </RoleWrapper>
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useTableColumnsExpand = (): TableColumnType[] => {
  const { _t } = useTrans();

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("status"),
        accessor: "statusName",
        id: "statusName",
        disableSortBy: true,
        maxWidth: 90,
        minWidth: 90,
        Cell: ({row}: any) => {
          const name = row?.original.statusName
          return name.charAt(0).toUpperCase() + name.slice(1);
        }
      },
      {
        Header: <div className="mb--cell">{_t("code_priority")}</div>,
        accessor: "codePriority",
        id: "codePriority",
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        Header: _t("error_value"),
        accessor: "errorValue",
        id: "errorValue",
      },
      {
        Header: _t("error_detail_key"),
        accessor: "errorDetailKey",
        id: "errorDetailKey",
      },
      {
        Header: <div className="mb--cell">{_t("sequence_number")}</div> ,
        accessor: "sequenceNumber",
        id: "sequenceNumber",
        maxWidth: 90,
        disableSortBy: true,
      },
      {
        Header:  _t("description"),
        accessor: "statusReasonDescription",
        id: "statusReasonDescription",
      },
      {
        Header: <div className="mb--cell">{_t("is_cascading_allowed")}</div>,
        accessor: "statusReasonIsCascadingEnabled",
        id: "statusReasonIsCascadingEnabled",
        maxWidth: 100,
        Cell: ({row}: any) => {
          return row?.original?.statusReasonIsCascadingEnabled + ''
        }
      },
      {
        Header: _t("status_reason"),
        accessor: "statusReason",
        id: "statusReason",
        Cell: ({row}: any) => {
          return `${row?.original.statusReasonCode} ( ${row?.original.statusReasonTitle} )`
        }
       },
    ], []
  );
  return columns;
}

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const {_t} = useTrans()
  const fields = useDefaultFieldsConfig();
  const { paymentGatewaysOptions } = useModuleData();
  const transactionType = useTransactionType(_t);
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.transactionType({
        name: "type",
        componentProps: {
          items: transactionType,
          disabled: params?.isEdit,
        },
      }),
      fields.formFields.paymentGatewayCode({
        name: "gatewayCode",
        componentProps: {
          items: paymentGatewaysOptions,
          light: false,
          disabled: params?.isEdit,
        },
      }),
      fields.formFields.comment(),
      // fields.general.divider(),
      fields.general.saveBtn({
        columnParams: {
          className: 'status_reason__absolut_button'
        }
      }),
    ];
  }, [paymentGatewaysOptions]);
  return formConfig;
};

const useEditStatusReasonItemFormConfig = (type: StatusCallbackResponsePullCreateUpdateItemType): FormConfigType => {
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const { statusReasonsOptions } = useModuleData();
  const formGeneral = [
    fields.general.divider(),
    fields.general.saveBtn({
      componentProps: {
        renderIcon: Checkmark16,
        label: '',
      },
      columnParams: {
        className: 'popover-tooltip__saveBtn'
      }
    }),
  ]
  const formConfig = useMemo((): FormConfigType => {
    switch (type) {
      case "statusName":
        return [
          fields.formFields.selectStatus({
            name: "statusName",
            componentProps: {
              options: config.statusesConfiguration,
              labelText: _t("status"),
              placeholderText: _t('select_status')
            },
            columnParams: {
              lg: 16,
              md: 16,
            }
          }),
          ...formGeneral
        ]
      case "statusReasonOption":
        return [
          fields.formFields.selectStatus({
            name: "statusReasonOption",
            componentProps: {
              options: statusReasonsOptions,
              labelText: _t("status"),
              placeholderText: _t('select_status')
            },
            columnParams: {
              lg: 16,
              md: 16,
            }
          }),
          ...formGeneral
        ];
      case "sequenceNumber":
        return [
          fields.formFields.statusReasonSequenceNumber(),
          ...formGeneral
        ]
      case "errorValue":
        return [
          fields.formFields.description({
            name: "errorValue",
            componentProps: {
              labelText: _t("error_value")
            }
          }),
          ...formGeneral
        ]
      case "codePriority":
        return [
          fields.formFields.statusReasonCodePriority(),
          ...formGeneral
        ]
      case "errorDetailKey":
        return [
          fields.formFields.errorDetailKey(),
          ...formGeneral
        ]
      default:
        return []
    }

  }, [type, statusReasonsOptions]);
  return formConfig;
};


export const useTableStatusReasonsCreateEdit = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("status"),
        accessor: "statusName",
        id: "statusName",
        disableSortBy: true,
        maxWidth: 90,
        minWidth: 90,
        Cell: ({ row, onUpdateCreatedStatusConfigurationItem }: any) => {
          const fieldName: StatusCallbackResponsePullCreateUpdateItemType = 'statusName'
          const formConfig: any = useEditStatusReasonItemFormConfig(fieldName)
          const isUpdateRate = !!row?.original?.[fieldName]
          const name = row?.original?.[fieldName]
          
          return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={isUpdateRate
                  ? name.charAt(0).toUpperCase() + name.slice(1)
                  : <div className="exchange-rates--cell-text">{_t('select_status')}</div>
                }
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={(data: any) => onUpdateCreatedStatusConfigurationItem({...data, id: row.index})}
                validation={createEditStatusConnectionConfigurationSettingsValidation(_t, fieldName)}
              />
          )
        }
      },
      {
        Header: <div className="mb--cell">{_t("code_priority")}</div>,
        accessor: "codePriority",
        id: "codePriority",
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        Cell: ({row, onUpdateCreatedStatusConfigurationItem } : any) => {
          const fieldName: StatusCallbackResponsePullCreateUpdateItemType = 'codePriority'
          const formConfig: any = useEditStatusReasonItemFormConfig(fieldName)
          const isUpdateRate = !!row?.original?.[fieldName]
          return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={isUpdateRate
                  ? row?.original?.[fieldName]
                  : <div className="exchange-rates--cell-text">{_t('set_code_priority')}</div> 
                }
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={(data: any) => onUpdateCreatedStatusConfigurationItem({...data, id: row.index})}
                validation={createEditStatusConnectionConfigurationSettingsValidation(_t, fieldName)}
                />
          )
        }
      },
      {
        Header: _t("error_value"),
        accessor: "errorValue",
        id: "errorValue",
        disableSortBy: true,
        Cell: ({row, onUpdateCreatedStatusConfigurationItem } : any) => {
          const fieldName: StatusCallbackResponsePullCreateUpdateItemType = 'errorValue'
          const formConfig: any = useEditStatusReasonItemFormConfig(fieldName)
          const isUpdateRate = !!row?.original?.[fieldName]
          return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={isUpdateRate
                  ? row?.original?.[fieldName]
                  : <div className="exchange-rates--cell-text">{_t('set_error_value')}</div> 
                }
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={(data: any) => onUpdateCreatedStatusConfigurationItem({...data, id: row.index})}
                validation={createEditStatusConnectionConfigurationSettingsValidation(_t, fieldName)}
              />
          )
        }
      },
      {
        Header: _t("error_detail_key"),
        accessor: "errorDetailKey",
        id: "errorDetailKey",
        disableSortBy: true,
        Cell: ({row, onUpdateCreatedStatusConfigurationItem } : any) => {
          const fieldName: StatusCallbackResponsePullCreateUpdateItemType = 'errorDetailKey'
          const formConfig: any = useEditStatusReasonItemFormConfig(fieldName)
          const isUpdateRate = !!row?.original?.[fieldName]
          return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={isUpdateRate
                  ? row?.original?.[fieldName]
                  : <div className="exchange-rates--cell-text">{_t('set_error_detail_key')}</div> 
                }
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={(data: any) => onUpdateCreatedStatusConfigurationItem({...data, id: row.index})}
                validation={createEditStatusConnectionConfigurationSettingsValidation(_t, fieldName)}
              />
          )
        }
      },
      {
        Header: <div className="mb--cell">{_t("sequence_number")}</div> ,
        accessor: "sequenceNumber",
        id: "sequenceNumber",
        maxWidth: 90,
        disableSortBy: true,
        Cell: ({ row, onUpdateCreatedStatusConfigurationItem }: any) => {
          const fieldName: StatusCallbackResponsePullCreateUpdateItemType = 'sequenceNumber'
          const formConfig: any = useEditStatusReasonItemFormConfig(fieldName)
          const isUpdateRate = !!row?.original?.[fieldName]
          return (
                <ChangeValueTooltip
                  formConfig={formConfig}
                  dataValue={isUpdateRate 
                    ? row?.original?.[fieldName]
                    : <div className="exchange-rates--cell-text">0</div>
                  }
                  initialValues={row.original}
                  bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                  onUpdate={(data: any) => onUpdateCreatedStatusConfigurationItem({...data, id: row.index})}
                  validation={createEditStatusConnectionConfigurationSettingsValidation(_t, fieldName)}
                />
          )
        }
      },
      {
        Header: <div className="mb--cell">{ _t("status_reason")}</div>,
        accessor: "statusReason",
        id: "statusReason",
        disableSortBy: true,
        Cell: ({ row, onUpdateCreatedStatusConfigurationItem } : any) => {
          const fieldName: StatusCallbackResponsePullCreateUpdateItemType = 'statusReasonOption'
          const formConfig: any = useEditStatusReasonItemFormConfig(fieldName)
          const isUpdateRate = !!row?.original?.[fieldName]?.id
          return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={isUpdateRate
                  ? `${row?.original?.[fieldName]?.code} (${row?.original?.[fieldName]?.title})`
                  : <div className="exchange-rates--cell-text">0</div>
                }
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={(data: any) => onUpdateCreatedStatusConfigurationItem({...data, id: row.index})}
                validation={createEditStatusConnectionConfigurationSettingsValidation(_t, fieldName)}
              />
          )
        }
      },
      // {
      //   Header: <div className="mb--cell">{_t("is_cascading_allowed")}</div>,
      //   accessor: "statusReasonIsCascadingEnabled",
      //   id: "statusReasonIsCascadingEnabled",
      //   maxWidth: 100,
      //   Cell: ({row}: any) => {
      //     return row?.original?.statusReasonIsCascadingEnabled.toString()
      //   }
      // },
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.index,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};
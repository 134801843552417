import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import {  useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { createTransactionValidation } from "modules/merchantsBalanceDetails/validation";
import { useCreateEditConfig } from "modules/merchantsBalanceDetails/constantHooks";
import { useCreateManualTransaction } from "../apiHooks";
import Loader from "components/atoms/Loader";
import { parseFormData } from "system/helpers/hooks";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const formConfig = useCreateEditConfig();
  const filter = {
    dateFrom: routeParams?.fromDate,
    dateTo: routeParams?.toDate
  } 
  const { mutate: onCreate, isLoading } = useCreateManualTransaction(
    routeParams.merchantId,
    routeParams.currency,
    filter
    );
  
  const onSubmit = async (data: any) => {
    const normalize = { ...data }
    delete normalize.file
    normalize.transactionType === "onHoldAdjustment" && delete normalize.transferFee
    normalize.transactionType === "balanceAdjustment" && normalize.adjustmentType === "transferToAnotherMerchant" && delete normalize.transferFee
    
    const reqData = parseFormData({
      manualOperationDto: JSON.stringify(normalize),
      file: data.file,
    });

    onCreate(reqData);
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_manual_transaction")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("create"),
                onSubmit: onSubmit,
                initialValues: {transactionType: 'movementAdjustment', date: new Date()},
                validationSchema: createTransactionValidation(_t),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;

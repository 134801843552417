import { FileUploaderDropContainer, FileUploaderItem, FileUploaderProps } from "carbon-components-react"
import { useTrans } from "system/translations/hooks";

const FileUploader: React.FC<
  FileUploaderProps & { 
    value: any;
    onChange: any,
    acceptFilesFormat: string[],
    checkFile: (file: any) => ({ isValidFormat: boolean, isValidSize: boolean, isValidFile: boolean })
  }
> = ({ value, onChange, acceptFilesFormat, checkFile }) => {
  const { _t } = useTrans()
  const { isValidFormat, isValidSize, isValidFile } = checkFile(value)
  const handleChange = (event: React.DragEvent<HTMLElement>, content: { addedFiles: File[] }) => {
    onChange(content.addedFiles[0]);
  };
  const handleRemove = (file: any) => {
    onChange(undefined);
  };
  const errorMessage = !isValidSize 
    ? !isValidFormat 
      ? _t('file_uploader_error_format_and_size_limit') 
      : _t('file_uploader_error_size_limit') 
    : !isValidFormat
      ? _t('file_uploader_error_format_limit') 
      : '' 
  

  return (
    <>
      <FileUploaderDropContainer
        disabled={!!value}
        accept={acceptFilesFormat}
        labelText={_t('file_uploader_formats')}
        multiple={false}
        name="file"
        onAddFiles={handleChange}
        tabIndex={0}
        className={'file-uploader__container'}
      />

      {value && <div className={'file-uploader__item'}>
        <FileUploaderItem
          name={value.name}
          onDelete={() => handleRemove(value)}
          size="md"
          status="edit"
          invalid={!isValidFile}
          errorSubject={errorMessage}
        />
      </div>}

    </>
  )
}

export default FileUploader
import React, { useMemo } from "react";
import { useDeleteMonitoring, useGetMonitoringDeposit, useGetMonitoringWithdrawal } from "./apiHooks";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFEFilter, useFilters, useListPagination } from "system/helpers/hooks";
import Card from "components/atoms/Card";
import { Add16 } from "@carbon/icons-react";
import { useHistory, generatePath } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Edit16, CloseOutline16 } from "@carbon/icons-react";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useSearchFormConfig } from "modules/monitoring/constantHooks";
import { useTableColumns } from "modules/monitoring/constantHooks";
import config from "system/config";
import { getUrl } from "system/helpers/helperFunctions";
import { TabsType } from "components/atoms/Tabs/types";
import Tabs from "components/atoms/Tabs";

const Monitoring = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { filters: filtersDeposit, setFilters: setFiltersDeposit } = useFilters({});
  const { filters: filtersWithdrawal, setFilters: setFiltersWithdrawal } = useFilters({});
  const { pagination: depositPagination, setPagination: setDepositPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { pagination: withdrawalPagination, setPagination: setWithdrawalPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const requestParamsDeposit = useMemo(
    () => ({ ...filtersDeposit, ...depositPagination }),
    [filtersDeposit, depositPagination]
  );
  const requestParamsWithdrawal = useMemo(
    () => ({ ...filtersWithdrawal, ...withdrawalPagination }),
    [filtersWithdrawal, withdrawalPagination]
  );
  const { data: dataDeposit, isLoading } = useGetMonitoringDeposit(requestParamsDeposit);
  const { data: dataWithdrawal} = useGetMonitoringWithdrawal(requestParamsWithdrawal);
  const { onConfirm } = useConfirmModal();
  const { mutate: onDelete } = useDeleteMonitoring();
  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.monitoring.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };

  const onFilterSubmitDeposit = async (data: any) => {
    setFiltersDeposit(data);
    setDepositPagination(config.defaultInitialPagination);
  };

  const onFilterSubmitWithdrawal = async (data: any) => {
    setFiltersWithdrawal(data);
    setWithdrawalPagination(config.defaultInitialPagination);
  };

  const actionMenuItems = [
    { 
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.monitoring.update, id),
      icon: <Edit16 /> 
    },
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <CloseOutline16 />,
      type: "danger",
    },
  ];
  const isMobile = window.matchMedia('(max-width: 671px)').matches;


  const tabs: TabsType = {
    deposit: {
      label: _t("deposit_methods"),
      content: (
        <>
          <FormBuilder
            formItemsConfig={searchFormConfig}
            showSubmit={false}
            formProps={{
              onSubmit: onFilterSubmitDeposit,
              initialValues: {},
            }} />
            <Table
              columns={columns}
              isLoading={isLoading}
              onPagination={setDepositPagination}
              data={dataDeposit?.content || []}
              isPagination
              totalRecords={dataDeposit?.totalElements}
              pageIndex={depositPagination.page}
              pageSize={depositPagination.perPage}
              cellProps={{
                actionMenuItems,
                paymentType: "deposit",
              }} />
            </>
      ),
    },
    withdrawal: {
      label: _t("withdrawal_methods"),
      content: (
        <>
          <FormBuilder
            formItemsConfig={searchFormConfig}
            showSubmit={false}
            formProps={{
              onSubmit: onFilterSubmitWithdrawal,
              initialValues: {},
            }} />
            <Table
              columns={columns}
              onPagination={setWithdrawalPagination}
              isLoading={isLoading}
              data={dataWithdrawal?.content || []}
              isPagination
              totalRecords={dataWithdrawal?.totalElements}
              pageIndex={withdrawalPagination.page}
              pageSize={withdrawalPagination.perPage}
              cellProps={{
                actionMenuItems,
                paymentType: "withdrawal",
              }} />
          </>
      ),
    },
  };

  return (
    <Card
      title={_t("monitoring_rules")}
      extra={
        <>
         {isMobile
          ? <ButtonLink
              className="button-create__link"
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.monitoring.create}
              addHref = {true}
            />
          : <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.monitoring.create}
              addHref = {true}
              title={_t("create")}
            />
        }
        </>
      }
    >
      <Tabs tabs={tabs} name={"monitoring_rules"} />
    </Card>
  );
};

export default Monitoring;

import React from "react";
import { useHistory } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import { APP_ROUTES } from "system/router/constants";
import { CheckmarkOutline32, ArrowRight20 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";

const Verified: React.FC = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const goTo = () => history.push(APP_ROUTES.login);

  return (
    <div className={"verified__container"}>
      <div className={"verified__icon"}>
        <CheckmarkOutline32 />
      </div>
      <div className={"verified__title"}>{_t("your_email_verified")}</div>
      <div className={"verified__subtitle"}>{_t("you_can_login")}</div>
      <Button onClick={goTo} renderIcon={ArrowRight20}>
        {_t("go_to_login")}
      </Button>
    </div>
  );
};

export default Verified;

import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ToastNotification } from "carbon-components-react";
import { ToastTypes, ToastParams } from "./types";

const toastNotificationProps = () => ({
  title: "",
  subtitle: "",
  timeout: 5000,
});

const Toast: React.FC<ToastTypes> = forwardRef(({}, ref) => {
  const [toastList, setToastList] = useState<ToastParams[]>([]);

  useImperativeHandle(ref, () => ({
    toastHandler(toast: ToastParams) {
      setToastList([
        ...toastList,
        {
          ...toast,
          id: +new Date(),
        },
      ]);
    },
  }));

  const onClose = (id: number) => {
    const result = toastList.filter((item) => item.id !== id);
    setToastList(result);
    return true;
  };

  return (
    <div className={"bx--toast-notification-container"}>
      {toastList.map((item: ToastParams) => (
        <ToastNotification
          key={item.id}
          {...toastNotificationProps()}
          notificationType={"toast"}
          caption={item.caption}
          kind={item.kind}
          onClose={() => onClose(item.id)}
        />
      ))}
    </div>
  );
});

export default Toast;

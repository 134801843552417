import React, { useEffect, useState } from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateEditConfig, useTableStatusReasonsCreateEdit } from "../constantHooks";
import Table from "modules/table";
import Divider from "components/atoms/Divider";
import { Add20, TrashCan16 } from "@carbon/icons-react";
import {  useGetStatusReasonConnectionItem, useUpdateStatusReasonConnection } from "../apiHooks";
import { useGetCheckNewStatusConnections, useGetHelperFunctions } from "../hooks";
import Loader from "components/atoms/Loader";
import { NewStatusConnectionType, StatusConnectionTypeType } from "../types";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { createEditValidation } from "../validation"

const Update: React.FC = () => {
  const { _t } = useTrans();
  const { checkNewStatusConnectionForUpdate } = useGetCheckNewStatusConnections()
  const routeParams: any = useParams();
  const type: StatusConnectionTypeType  = routeParams.type
  const lowerCaseType = type.toLowerCase() as Lowercase<StatusConnectionTypeType>

  const { mutate: onUpdate } = useUpdateStatusReasonConnection();
  const { data: dataStatusReasonConnectionItem, isLoading: isLoading } = useGetStatusReasonConnectionItem({
    gatewayCode: routeParams?.gatewayCode,
    type
  });
  const statusReasonItem = dataStatusReasonConnectionItem?.[lowerCaseType]

  const { onConfirm } = useConfirmModal();
  const { getExpandData, sortStatusesIntoUpdateCreateData } = useGetHelperFunctions()
  const columns = useTableStatusReasonsCreateEdit()
  const statusesConfigurationList = getExpandData(statusReasonItem)?.[0]?.statuses
  const [ newStatusesConfigurationList, setNewStatusesConfigurationList ] = useState<NewStatusConnectionType[]>([])
  const formConfig = useCreateEditConfig({
    isEdit: true
  });

  useEffect(() => {
    if (statusesConfigurationList) {
      setNewStatusesConfigurationList(statusesConfigurationList);
    }
  }, [dataStatusReasonConnectionItem]) 

  const onUpdateCreatedStatusConfigurationItem = ({data, id}: { data: NewStatusConnectionType, id: number }) => {
    newStatusesConfigurationList[id] = data
    setNewStatusesConfigurationList([...newStatusesConfigurationList])
  }

  const onSubmit = (data: any) => {
    checkNewStatusConnectionForUpdate({
      newStatusConnectionItem: newStatusesConfigurationList[0],
      onOk: () => onUpdate(sortStatusesIntoUpdateCreateData(data, newStatusesConfigurationList))
    })
  };

  const newStatusConfigurationItem: NewStatusConnectionType = {
    statusName: null,
    sequenceNumber: null,
    codePriority: null,
    errorDetailKey: null,
    errorValue: null,
    statusReasonOption: null
}

  const addStatusConfiguration = () => {
    checkNewStatusConnectionForUpdate({
      newStatusConnectionItem: newStatusesConfigurationList[0] || {},
      onOk: () => setNewStatusesConfigurationList((prev: any) => [newStatusConfigurationItem, ...prev])
    })
  }

  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => setNewStatusesConfigurationList((prevList) => {
      const newList = [...prevList]; 
      newList.splice(id, 1);        
      return newList;               
  })});
  };

  const actionMenuItems = [
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed
      className="update-settings-content"
    >
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("update")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("update"),
                onSubmit: onSubmit,
                initialValues: statusReasonItem?.[0] || {},
                validationSchema: createEditValidation(_t),
              }}
            />
            <div className="form-item__table-header-container">
              {_t("configuration_settings")}
              <div className="form-item__table-header-add-btn" onClick={addStatusConfiguration}>
                <Add20 /> {_t("add_configuration")}
              </div>
            </div>
            <div className="status_reason__table-container">
              <Table
                data={newStatusesConfigurationList}
                columns={columns}
                isLoading={isLoading}
                cellProps={{
                  onUpdateCreatedStatusConfigurationItem,
                  actionMenuItems
                }}
              />
            </div>
          <Divider />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;

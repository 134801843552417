import { Download16, Information16, Save16, SaveModel16 } from "@carbon/icons-react";
import { Button, Link, Tooltip } from "carbon-components-react";
import classNames from "classnames";
import ChangeValueTooltip from "components/molecules/ChangeValueTooltip";
import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "system/helpers/hooks";
import { useTrans } from "system/translations/hooks";
import Divider from "../Divider";

const CommentTooltip: React.FC<{
  comment: any[],
  cellValue: string,
  showMovementAdjustmentDetails?: (comment: any) => void,
  formConfig?: any[],
  onUpdate?: (data: any) => void,
  validation?: any,
  accessTo?: string
  onGetAttachmentHandler?: (data: any) => void
}> = ({
  comment,
  cellValue,
  showMovementAdjustmentDetails = () => {},
  formConfig = [],
  onUpdate,
  validation,
  accessTo,
  onGetAttachmentHandler = () => {}
}) => {
  const { _t } = useTrans()
  const ref = useRef(null)
  const cellRef = useRef<any>(null)
  const [open, setOpen] = useState(false)
  const [ clickOutSide, setClick ] = useOutsideClick(ref);

  useEffect(() => {
    !document.querySelector('.popover-tooltip') && clickOutSide && open && setOpen(false)
    clickOutSide && open && setClick(false)
  }, [clickOutSide])

  const setTooltipOffset = (menuBody: any, menuDirection: any) => {
    if (menuDirection === 'left' ) {
        if (Array.isArray(comment[0])) {
         return  { top:5, left: 12 }
        }
      
      return {top:0, left: cellRef?.current?.getBoundingClientRect()?.width}
    }
    return {top:0, left: 0}
  }
  
  return (

      <div 
        className={classNames(Array.isArray(comment[0]) && "comment-tooltip__cell-fees", "comment-tooltip__cell")} 
        onClick={() => !open && !clickOutSide && setOpen(!open)}
      >
        {cellValue}
        {comment[0] && (
        <div className="comment-tooltip--comment">
          {Array.isArray(comment[0]) 
            ? <Information16 />
            : <div className="comment-tooltip--comment-cell-comment" ref={cellRef}>{comment[0].comment}</div>
          }
          <Tooltip
            menuOffset={setTooltipOffset}
            autoOrientation={true}
            direction="right"
            showIcon={false}
            open={open}
            className="comment-tooltip"
          >
            <div className="comment-tooltip--container" ref={ref}>
            {comment.map((item: any | any[], idx )=> {
              if (Array.isArray(item)) {
                return (
                  <>
                  <div className="comment-tooltip--item">
                    <div>{_t(item[0])}</div>
                    <div className="comment-tooltip--item-value">{item.slice(1).join(' ')}</div>
                  </div>
                  {idx < (comment.length -1)  && <Divider />}
                  </>
                )
              }
              if (idx <= 2) {
                return (
                  <div className="comment-tooltip--container">
                    <div className={classNames("comment-tooltip--item" , item.fileName && "comment-tooltip--item--with-download" )} >
                      {
                        item.fileName && (
                          <Button
                            style={{ color: "#000" }}
                            kind={"ghost"}
                            className="comment-tooltip--item__download-button"
                            hasIconOnly
                            iconDescription={_t('download_{fileName}', {
                              fileName: item.fileName
                            })}
                            renderIcon={Download16}
                            onClick={() => onGetAttachmentHandler(item)}
                            tabIndex={-1}
                          />
                        )
                      }
                            <ChangeValueTooltip
                                formConfig={formConfig}
                                dataValue={
                                  <>
                                    <div className="comment-tooltip--item">{item.amount} {item.currency}</div>
                                    <div className="comment-tooltip--item">{item.comment}</div>
                                  </>
                                }
                                initialValues={item}
                                bigTooltipClass="smallTooltipClass"
                                onUpdate={onUpdate}
                                accessTo={accessTo}
                                validation={validation}
                                isCommentTooltipChanger={true}
                              />
                    </div>
                    {idx < 2 && idx < (comment.length -1)
                      ? <Divider /> 
                      : comment.length > 3 
                        ? <Link onClick={() => showMovementAdjustmentDetails(comment)}>Show more...</Link> 
                        : ''
                    }
                  </div>
                )
              } 
            })}
            </div>
          </Tooltip>
        </div>)}
      </div>
  )
}

export default CommentTooltip
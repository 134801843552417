import * as Yup from "yup";
import { CreateEditPendingTransactionsMonitoringValidationParams } from "./types";
import { TransType } from "system/translations/types";

export const createEditPendingTransactionsMonitoringValidation = (
  _t: TransType,
  params: CreateEditPendingTransactionsMonitoringValidationParams
) => {
  return Yup.object().shape({
    paymentGatewayCode: Yup.string().required(_t("required")),
    depositsPercentage: Yup.number().required(_t("required"))
      .typeError(_t("must_be_a_number")),
    checkDepositsOlderThan: Yup.number().required(_t("required"))
      .typeError(_t("must_be_a_number")),
    checkDepositsTo: Yup.number().required(_t("required"))
      .typeError(_t("must_be_a_number")),
    withdrawalsPercentage: Yup.number().required(_t("required"))
      .typeError(_t("must_be_a_number")),
    checkWithdrawalsOlderThan: Yup.number().required(_t("required"))
      .typeError(_t("must_be_a_number")),
      checkWithdrawalsTo: Yup.number().required(_t("required"))
      .typeError(_t("must_be_a_number")),
  });
};

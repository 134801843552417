import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UseModuleDataRes, UseModuleDataResCreate } from "./types";
import { useGetSetting } from "modules/settings/apiHooks";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { useGetPaymentGatewaysByMerchant } from "./apiHooks";
import { TransType } from "system/translations/types";
import { useTrans } from "system/translations/hooks";
import { useModal } from "modules/modal/hooks";
import NotClosedPeriod from "components/molecules/NotClosedPeriod";
import ConfirmModalButtons from "components/atoms/ConfirmModalButtons";
import { Checkmark16 } from "@carbon/icons-react";
import { useGetPaymentGateways } from "modules/merchantsBalanceV2/apiHooks";

export const useModuleData = (): UseModuleDataRes => {
  const { data: currencies = [] } = useGetSetting('currencies');

  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "codeLiteral",
  });

  return { currenciesOptions };
};

export const useModuleDataCreate = (merchantId: number): UseModuleDataResCreate => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGatewaysByMerchant = [] } = useGetPaymentGatewaysByMerchant(merchantId);
  const { data: paymentGatewaysAll = [] } = useGetPaymentGateways();
  const { data: currencies = [] } = useGetSetting('currencies');

  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "codeLiteral",
  });

  const paymentGatewaysOptionsByMerchant = paymentGatewaysByMerchant.map((item) => ({
    label: item.value,
    value: item,
  }));

  const paymentGatewaysOptionsAll = paymentGatewaysAll.map((item) => ({
    label: item.label,
    value: {
      label: item.value,
      value: item.label
    },
  }));

  const merchantsOptions = merchantsList.map((item) => ({
    label: item.name,
    value: item,
  }));


  return {
    merchantsOptions,
    paymentGatewaysOptionsByMerchant,
    paymentGatewaysOptionsAll,
    currenciesOptions
  };
};

export const useGetFieldNamesManualRate =  (_t: TransType) => {
  return {
    currencyFrom: _t("currency") + ' from',
    currencyTo: _t("currency") + ' to',
    rate: _t("rate_"),
    dateFrom:  _t("start_period"),
    dateTo: _t("end_period"),
  }
}

export const useGetWarningText = () => {
  const { _t } = useTrans()
  const { showModal, hideModal } = useModal()
  const openWarning = (onSubmit: any) => {
    showModal(
      {
        isShowScale: false,
        component: NotClosedPeriod,
        size: 'xs',
        smallModal: true,
        componentProps: {
          data: [],
          titleText:  _t('be_careful'),
          secondaryText:  _t('conversion_can_be_set_once'),
          isClosePeriod: false,
        },
        footer: ConfirmModalButtons,
        footerProps:  {
              onOkText: _t('submit'),
              onCancel: () => hideModal(),
              onOk: () => {
                onSubmit()
                hideModal()
              },
              onOkKind: "primary",
              className: 'small-modal-full-width-buttons',
              renderIcon: Checkmark16
            }
      });
  }

  return { openWarning }
}

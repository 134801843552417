import { Column as ColumnType } from "react-table";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("id"),
        accessor: "id",
        id: "id",
      },
      {
        Header: _t("email"),
        accessor: "email",
        id: "email",
        mobileVisible: true,
        isMobileTitle: true,
      },
      {
        Header: _t("merchant"),
        accessor: "merchant",
        id: "merchant",
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useCreateEditConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.email(),
      fields.formFields.comment(),
      fields.general.saveBtn(),
    ];
  }, []);
  return formConfig;
};

import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MONITORING_PER_MERCHANT_DEPOSIT,
  API_GET_MONITORING_PER_MERCHANT_WITHDRAWAL,
  API_CREATE_MONITORING_PER_MERCHANT,
  API_DELETE_MONITORING_PER_MERCHANT,
  API_UPDATE_MONITORING_PER_MERCHANT,
  API_GET_MONITORING_PER_MERCHANT_ITEM,
  API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  MonitoringPerMerchantDataType,
  MonitoringPerMerchantItemType,
} from "./types";

const getMonitoringPerMerchantDepositKey = "getMonitoringPerMerchantDepositKey";
const getMonitoringPerMerchantWidrawalKey = "getMonitoringPerMerchantWitdrawalKey";
const getMonitoringPerMerchantItemKey = "getMonitoringPerMerchantItemKey";
const getPaymentGatewayCodesKey = "getPaymentGatewayCodesKey";

export const useGetMonitoringPerMerchantDeposit = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMonitoringPerMerchantDepositKey, filters],
    (): Promise<MonitoringPerMerchantDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MONITORING_PER_MERCHANT_DEPOSIT, filters),
        method: "get",
      })
  );
};
export const useGetMonitoringPerMerchantWithdrawal = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMonitoringPerMerchantWidrawalKey, filters],
    (): Promise<MonitoringPerMerchantDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MONITORING_PER_MERCHANT_WITHDRAWAL, filters),
        method: "get",
      })
  );
};
export const useGetMonitoringPerMerchantItem = (id: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMonitoringPerMerchantItemKey, id],
    (): Promise<MonitoringPerMerchantItemType> =>
      apiRequest({
        url: generatePath(API_GET_MONITORING_PER_MERCHANT_ITEM, { id }),
        method: "get",
      })
  );
};

export const useCreateMonitoringPerMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MONITORING_PER_MERCHANT,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringPerMerchantDepositKey);
        queryClient.invalidateQueries(getMonitoringPerMerchantWidrawalKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteMonitoringPerMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MONITORING_PER_MERCHANT, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringPerMerchantDepositKey);
        queryClient.invalidateQueries(getMonitoringPerMerchantWidrawalKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateMonitoringPerMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MONITORING_PER_MERCHANT, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMonitoringPerMerchantDepositKey);
        queryClient.invalidateQueries(getMonitoringPerMerchantWidrawalKey);
        queryClient.invalidateQueries(getMonitoringPerMerchantItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetPaymentGateways = (isEdit: boolean) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewayCodesKey],
    (): Promise<any> =>
      apiRequest({
        url: API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES,
        method: "get",
      }),
      {
        enabled: !isEdit,
      }
  );
};

import React, { useMemo } from "react";
import Card from "components/atoms/Card";
import { useTrans } from "system/translations/hooks";
import { generatePath, useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import { useGetCredentials } from "modules/credentials/apiHooks";
import { ClickableTile, Column, Row } from "carbon-components-react";
import Loader from "components/atoms/Loader";
import { CredentialDataType } from "modules/credentials/types";

const Credentials = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { data, isLoading } = useGetCredentials();
  const linkHandler = (id: string) => {
    const link = generatePath(APP_ROUTES.credentials.info, {
      credentialId: id,
    });
    history.push(link, history.location);
  };
  const uniqueCredentials: CredentialDataType = useMemo(() => {
    let res: any = {};
    data?.forEach((item) => {
      if (!res.hasOwnProperty(item.type)) {
        res[item.type] = item;
      }
    });
    return Object.values(res);
  }, [data]);
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Card title={_t("credentials")}>
      <Row>
        {uniqueCredentials?.map((item: any) => {
          return (
            <Column lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 16 }}>
              <ClickableTile
                className={"bx--link-panel"}
                handleClick={() => linkHandler(item.type)}
              >
                {item.type}
              </ClickableTile>
            </Column>
          );
        })}
      </Row>
    </Card>
  );
};

export default Credentials;

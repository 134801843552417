import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { useEditMonitoringModuleData, useMonitoringModuleData } from "./hooks";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("id"),
        accessor: "id",
        id: "id1",
        disableSortBy: true,
        width: 60,
        mobileVisible: true,
      },
      {
        Header: _t("merchant"),
        accessor: "merchantName",
        id: "merchantName",
        disableSortBy: true,
        mobileVisible: true,
      },
      {
        Header: _t("payment_gateway"),
        accessor: "paymentGatewayCode",
        id: "paymentGatewayCode",
        disableSortBy: true,
        width: 230,
        mobileVisible: true,
        isMobileTitle: true
      },
      {
        Header: _t("slack_channel"),
        accessor: "channelName",
        id: "channelName",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("deposits_percentage"),
        accessor: "depositsPercentage",
        id: "depositsPercentage",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("check_deposits_older_than"),
        accessor: "checkDepositsOlderThan",
        id: "checkDepositsOlderThan",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("check_deposits_to"),
        accessor: "checkDepositsTo",
        id: "checkDepositsTo",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("deposits_checking_enabled"),
        accessor: "depositsCheckingEnabled",
        id: "depositsCheckingEnabled",
        disableSortBy: true,
        minWidth: 220,
        mobileVisible: true,
        Cell: (props: any) => {
          return <>{`${props.row.original.depositsCheckingEnabled}`}</>;
        },
      },
      {
        Header: _t("check_withdrawals_older_than"),
        accessor: "checkWithdrawalsOlderThan",
        id: "checkWithdrawalsOlderThan",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("check_withdrawals_to"),
        accessor: "checkWithdrawalsTo",
        id: "checkWithdrawalsTo",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("withdrawals_percentage"),
        accessor: "withdrawalsPercentage",
        id: "withdrawalsPercentage",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("withdrawals_checking_enabled"),
        accessor: "withdrawalsCheckingEnabled",
        id: "withdrawalsCheckingEnabled",
        disableSortBy: true,
        minWidth: 220,
        mobileVisible: true,
        Cell: (props: any) => {
          return <>{`${props.row.original.withdrawalsCheckingEnabled}`}</>;
        },
      },
      {
        Header: _t("created_date"),
        accessor: "createdDate",
        id: "createdDate",
        disableSortBy: true,
        minWidth: 230,
        mobileVisible: true,
      },
      {
        Header: _t("updated_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        disableSortBy: true,
        minWidth: 230,
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          type: "submit",
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useEditConfig = (initialValues: any): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { merchantSlackChannelOptions } = useEditMonitoringModuleData(initialValues?.merchantId);
  
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.paymentGatewayCode({
        componentProps: {
          items: [{
            label: initialValues?.paymentGatewayCode,
            value: initialValues?.paymentGatewayCode
          }],
          light: false,
          disabled: true,
        },
      }),
      fields.formFields.merchantSlackChannelSelect({
        name: "channel",
        componentProps: {
          items: merchantSlackChannelOptions,
          light: false,
        },
      }),
      fields.formFields.depositsPercentage(),
      fields.formFields.checkDepositsOlderThan(),
      fields.formFields.checkDepositsTo(),
      fields.formFields.withdrawalsPercentage(),
      fields.formFields.checkWithdrawalsOlderThan(),
      fields.formFields.checkWithdrawalsTo(),
      fields.formFields.depositsCheckingEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
        emptySpace: {
          sm: { span: 16 },
          lg: { span: 16 },
          md: { span: 16 },
        },
      }),
      fields.formFields.withdrawalsCheckingEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, [merchantSlackChannelOptions]);
  return formConfig;
};
export const useCreateConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const [ merchantId, setMerchantId ] = useState<number | undefined>(undefined)
  const [ isAllGatewaysDisplay, setIsAllGatewaysDisplay ] = useState(false);  
  const {
    paymentGatewaysOptionsAll,
    paymentGatewaysOptionsByMerchant,
    merchantsOptions,
    merchantSlackChannelOptions
  } = useMonitoringModuleData(merchantId);

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.merchantIdSingle({
        name: "merchant",
        componentProps: {
          items: merchantsOptions,
          light: false,
          onFieldChange: (value: any) => {
            setMerchantId(value.id);
          },
        },
      }),
      fields.formFields.getAllGateways({
        componentProps:{
          value: isAllGatewaysDisplay,
          onFieldChange: (value: any, values: any, form: any) => {
            form.setFieldValue('paymentGatewayCode', "")
            form.setFieldTouched('paymentGatewayCode', false);
            setIsAllGatewaysDisplay(value)
          },
        },
      }),
      fields.formFields.paymentGatewayCode({
        componentProps: {
          items: isAllGatewaysDisplay ? paymentGatewaysOptionsAll : paymentGatewaysOptionsByMerchant,
          light: false,
          whiteBackground: true,
          wrapClassPrefix: 'dropdown-full-width',
          withTitleText: true,
          previewSelectedItems: true,
        },
      }),
      fields.formFields.merchantSlackChannelSelect({
        name: "channel",
        componentProps: {
          items: merchantSlackChannelOptions,
          light: false,
        },
      }),
      fields.formFields.depositsPercentage(),
      fields.formFields.checkDepositsOlderThan(),
      fields.formFields.checkDepositsTo(),
      fields.formFields.withdrawalsPercentage(),
      fields.formFields.checkWithdrawalsOlderThan(),
      fields.formFields.checkWithdrawalsTo(),
      fields.formFields.depositsCheckingEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
        emptySpace: {
          sm: { span: 16 },
          lg: { span: 16 },
          md: { span: 16 },
        },
      }),
      fields.formFields.withdrawalsCheckingEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, [paymentGatewaysOptionsAll, paymentGatewaysOptionsByMerchant, merchantsOptions]);
  return formConfig;
};

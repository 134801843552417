import React from "react";
import { TextInput as CarbonTextInput } from "carbon-components-react";
import { InputProps } from "components/atoms/Input/types";
import FilterTag from "components/molecules/FilterTag";

const InputWithFilterTag: React.FC<InputProps> = ({
  id = "input",
  labelText,
  value,
  onChange,
  hideLabel = false,
  style = {},
  placeholder,
  size = "xl",
  onRemove,
  autoFocus = false,
  ...rest
}) => {
  const prepareMultiAddIp = value
    ? value.includes(',')
      ? value.split(',').map((item: string) => item.trim()).filter((item: string) => item.length)
      : [value]
    : []
  
  const resetField = (index: number) => {
    onChange(value.split(',').filter((item: string, idx: number) => index !== idx).join(','))
  }
  return (
    <>
      <CarbonTextInput
        autoFocus={autoFocus}
        placeholder={placeholder}
        style={style}
        {...rest}
        id={id}
        onChange={(e) => onChange(e?.target?.value)}
        value={value}
        labelText={labelText}
        size={size}
        hideLabel={hideLabel} />
      {prepareMultiAddIp.length > 1 && prepareMultiAddIp.map((item: string, idx: number) => {
        return (
          <FilterTag
            label={'ip' + (idx + 1)}
            value={item}
            onClose={() => resetField(idx)}
          />
        )
      })}
    </>
  );
};

export default InputWithFilterTag;

import { useGetPaymentGatewaysByMerchant } from "modules/merchantConfiguration/apiHooks";
import { useGetPaymentGateways } from "./apiHooks";
import { useGetMerchantSlackChannels, useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UseFormHelpersProps, UseMonitoringPerMerchantModuleDataRes } from "./types";
import { useMemo } from "react";

export const useModuleData = (isEdit: boolean, merchantId?: number): UseMonitoringPerMerchantModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGateways = [] } = useGetPaymentGateways(isEdit);
  const { data: paymentGatewaysByMerchant = [] } = useGetPaymentGatewaysByMerchant(merchantId || 0);
  const { data: merchantSlackChannels = [] } = useGetMerchantSlackChannels(merchantId || 0);

  const merchantsOptions = merchantsList.map((item) => ({
    label: item.name,
    value: item,
  }));

  const merchantSlackChannelOptions = merchantSlackChannels.map((item) => ({
    label: item.channelName,
    value: {
      id: item.id,
      name: item.channelName
    },
  }));

  const paymentGatewaysOptionsAll = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  const paymentGatewaysOptionsByMerchant = arrayOptionsMap(paymentGatewaysByMerchant, {
    labelKey: "value",
    valueKey: "label",
  });  

  return {
    merchantsOptions,
    paymentGatewaysOptionsAll,
    paymentGatewaysOptionsByMerchant,
    merchantSlackChannelOptions
  };
};

export const useFormHelpers = ({ isEdit, data }: UseFormHelpersProps) => {
  const initialValues = useMemo(() => {
    if (isEdit && data) {
      return {
        ...data,
        channel: { id: data.channelId, name: data.channelName },
        merchant: { id: data.merchantId, name: data.merchantName  },
      };
    }

    return {};
  }, [isEdit, data]);

  const normalizeData = (formData: any) => {
    const { channel, merchant, getAllGateways, ...rest } = formData;

    return {
      ...rest,
      channelId: channel?.id,
      channelName: channel?.name,
      merchantId: merchant?.id,
      merchantName: merchant?.name,
    };
  };

    return { initialValues, normalizeData }
}

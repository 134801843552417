import React, { useEffect } from "react";
import * as ace from "brace";
import "brace/mode/json";
import "brace/theme/monokai";
import { Maximize24 } from "@carbon/icons-react";
import { useModal } from "modules/modal/hooks";
import classNames from "classnames";

const JsonEditor = ({ value, onChange, labelText, isModal = false }: any) => {
  const { showModal } = useModal()
  const jsonId = isModal ? "javascript-editor-modal" : "javascript-editor"
  let editor: any = null;
  const onChangeHandler = (value: any) => {
    onChange && onChange(value);
    /*    try {
      JSON.parse(value);
      /!*  setFieldValid(true);*!/
    } catch (e) {
      /!*  setFieldValid(false);*!/
    }*/
  };

  const onResize = () => {
      showModal({
        modalHeading: 'JsonEditor',
        componentProps: {
          value,
          onChange,
          labelText,
          isModal: true
        },
        component: JsonEditor,
      },"json");
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    editor = ace.edit(jsonId);
    editor.$blockScrolling = Infinity;
    editor.getSession().setMode("ace/mode/json");
    if (value) {
      try {
        const val = JSON.parse(value);
        editor.setValue(JSON.stringify(val, null, 2));
      } catch (e) {
        editor.setValue(value, null, 2);
      }
    }
    editor.addEventListener("change", () => onChangeHandler(editor.getValue()));

    return () => {
      const valueModal = editor.getValue()
      
      editor && editor.destroy();
      editor.removeEventListener("change", () =>
        onChangeHandler(editor.getValue())
      );

      if (isModal) {
        editor = ace.edit("javascript-editor");

        if (valueModal) {
          try {
            const val = JSON.parse(valueModal);
            editor.setValue(JSON.stringify(val, null, 2));
          } catch (e) {
            editor.setValue(valueModal, null, 2);
          }
        }
      }
    };
  }, []);

  return (
    <div className={"json-editor"}>
      {labelText}
     {!isModal && <Maximize24 onClick={onResize} className={"json-editor__resize-icon"}/>}
      <div id={jsonId} className={classNames("json-editor-container", isModal && "json-editor-container-modal")} />
    </div>
  );
};

export default JsonEditor;

import React from "react";
import { Warning32 } from "@carbon/icons-react";
import { useTrans } from "system/translations/hooks";

const NotConfiguretGatewaysModal: React.FC<any> = ({ titleText , secondaryText , data, isClosePeriod = false }) => {
  const { _t } = useTrans()
  return (
    <div className="not-configuret__container">
      <Warning32 className="not-configuret__icon"/>
      <div className="not-configuret__text-container">
        <span className="not-configuret__prompt">{titleText} {
              <div className="not-configuret__items-container">
                {!!data.fee && (
                  <div className="not-configuret__items">
                    <span className="not-configuret__prompt-provider">{_t('fee_settings')}</span>
                    {data.fee.map((item: string) => {
                      return  <span className="not-configuret__prompt-provider">{item}</span>
                    })}
                  </div >
                )}
                {!!data.config && (
                  <div className="not-configuret__items">
                    <span className="not-configuret__prompt-provider">{_t('configuration_settings')}</span>
                    {data.config.map((item: string) => {
                      return  <span className="not-configuret__prompt-provider">{item}</span>
                    })}
                  </div>
                )}
              </div>
          }
        </span>
        <span className="not-configuret__prompt-secondary">{secondaryText}</span>
      </div>
    </div>
  );
};

export default NotConfiguretGatewaysModal;

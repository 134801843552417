import React from "react";
import { Tag } from "carbon-components-react";
import { InfoTagProps } from "components/atoms/InfoTag/types";
import classNames from "classnames";

const InfoTag: React.FC<InfoTagProps> = ({
  label,
  status = "default",
  reverse,
  fitContent,
  size = "md",
  type,
}) => {
  return type == "secondary" ? (
    <div className={classNames(`info-tag-${type}-wrap`)}>
      <div
        className={classNames(
          `info-${type}-tag`,
          `info-${type}-tag--${status?.toLowerCase()}`
        )}
      />
      <span className={`info-${type}-tag-label`}>{label}</span>
    </div>
  ) : (
    <Tag
      className={classNames(
        "info-tag",
        `info-tag--${status?.toLowerCase()}`,
        reverse && "info-tag--reverse",
        fitContent && "info-tag--fit-content"
      )}
      size={size}
    >
      {label}
    </Tag>
  );
};

export default InfoTag;

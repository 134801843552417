import React from "react";
import Update from "modules/merchantPayments/components/Update";

const MerchantPaymentsUpdatePage = () => {
  return (
    <div>
      <Update />
    </div>
  );
};

export default MerchantPaymentsUpdatePage;

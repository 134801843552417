import React from "react";
import MainTemplate from "templates/Main";
import { getToken } from "system/helpers/helperFunctions";
import { InjectTransContext } from "system/translations/InjectTransContext";
import NotLoggedTemplate from "../NotLogged";
import { useGetCurrentAdmin } from "modules/auth/apiHooks";
import Loader from "components/atoms/Loader";

const SystemTemplate: React.FC = ({ children }) => {
  const token = getToken();
  const { isLoading: isLoadingUser } = useGetCurrentAdmin();
  if (!token) {
    return <NotLoggedTemplate>{children}</NotLoggedTemplate>;
  }
  if (isLoadingUser) {
    return <Loader formOverlay />;
  }
  return (
    <InjectTransContext locale={"en"} messages={{}}>
      <MainTemplate>{children}</MainTemplate>
    </InjectTransContext>
  );
};

export default SystemTemplate;

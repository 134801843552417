import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "carbon-components-react";
import { ButtonLinkProps } from "./types";

const ButtonLink: React.FC<ButtonLinkProps> = ({
  title,
  isExpressive,
  renderIcon,
  linkTo,
  className,
  addHref = false
}) => {
  const history = useHistory();
  const onClickHandler = () => {
    history.push(linkTo, history.location);
  };

  return (
    <Button
      className={className || ''}
      onClick={addHref ? () => {} : onClickHandler}
      isExpressive={isExpressive}
      renderIcon={renderIcon}
      href = {addHref && linkTo}
    >
      {title}
    </Button>
  );
};

export default ButtonLink;

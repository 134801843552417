import Card from "components/atoms/Card";
import Loader from "components/atoms/Loader";
import _ from "lodash";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useGetPaymentOrchestration } from "../routingRules/apiHooks"
import { useSearchFormConfig } from "./constantHooks";

const ProviderConstraints = () => {
  const { _t } = useTrans()
  const { data = [], isLoading } = useGetPaymentOrchestration()
  const searchFormConfig = useSearchFormConfig()

  const onFilterSubmit = (data: any) => {
    console.log(data)
  }

  if (isLoading) {
    return <Loader />
  }

  const preparedData: any = _.cloneDeep(data)
  preparedData[0].rules = JSON.parse(preparedData[0].rules)

  return (
    <Card title={_t("provider_constraints")} >
      <FormBuilder
        formItemsConfig={searchFormConfig}
        showSubmit={false}
        formProps={{
          onSubmit: onFilterSubmit,
          initialValues: {},
        }}
      />
      {/* <Table
        totalRecords={data?.totalElements}
        columns={columns}
        data={data?.content || []}
        isPagination
        onPagination={setPagination}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        isLoading={isLoading}
        cellProps={{ actionMenuItems }}
      /> */}
    </Card>

  )
}

export default ProviderConstraints